/* import __COLOCATED_TEMPLATE__ from './provider.hbs'; */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly, or } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import { all, dropTask, task } from 'ember-concurrency';
import referralSharingOptionValidations from 'smile-admin/validations/referral-sharing-option';
import { types } from 'smile-admin/models/referral-sharing-option';

/**
 * @argument DS.Model rewardProgram
 * @argument DS.Model account
 */
@tagName('')
export default class ReferralSharingOptionsProvider extends Component {
  @service alert;
  @service errorHandler;
  @service store;

  referralSharingOptionValidations = referralSharingOptionValidations;
  referralSharingOptionDefinitionListings = [];
  referralSharingOptions = [];

  @or(
    'fetchReferralSharingOptions.isRunning',
    'fetchReferralSharingOptionDefinitionListings.isRunning'
  )
  isLoading;

  @computed(
    'referralSharingOptionDefinitionListings.@each.is_upgrade_required',
    'referralSharingOptions.[]'
  )
  get availableSharingOptions() {
    let availableReferralSharingOptionDefinitionListings =
      this.referralSharingOptionDefinitionListings.filter(
        (item) => !item.is_upgrade_required
      );
    let { referralSharingOptions } = this;
    let availableSharingOptions = [];

    availableReferralSharingOptionDefinitionListings.forEach(
      (referralSharingOptionDefinitionListings) => {
        let referralSharingOption = referralSharingOptions.find(
          (option) =>
            option.type ===
            referralSharingOptionDefinitionListings
              ?.referral_sharing_option_definition?.referral_sharing_option_type
        );
        if (isPresent(referralSharingOption)) {
          availableSharingOptions.push(referralSharingOption);
        }
      }
    );

    // meta-tags sharing option is listed separately
    // in a different card below these options
    return availableSharingOptions.filter(
      (option) => option.type !== types.metaTags
    );
  }

  @computed('availableSharingOptions.@each.is_enabled')
  get enabledSharingTypesList() {
    return this.availableSharingOptions
      .filter((option) => option.is_enabled)
      .sort((a, b) => a.sort_order - b.sort_order)
      .map((option) => option.type);
  }

  @readOnly(
    'referralSharingOptionDefinitionListings.0.referral_sharing_option_definition.template_variables'
  )
  referralSharingOptionTemplateVariables;

  @computed('referralSharingOptionDefinitionListings.[]')
  get metaTagsSharingOptionDefinitionListing() {
    return this.referralSharingOptionDefinitionListings.find(
      (option) =>
        option?.referral_sharing_option_definition
          ?.referral_sharing_option_type === types.metaTags
    );
  }

  init() {
    super.init(...arguments);

    this.fetchReferralSharingOptions.perform();
    this.fetchReferralSharingOptionDefinitionListings.perform();
  }

  @dropTask
  *fetchReferralSharingOptionDefinitionListings() {
    const referralSharingOptionDefinitionListings = yield this.store.query(
      'referral-sharing-option-definition-listing',
      { reward_program_id: this.rewardProgram.id }
    );
    return this.set(
      'referralSharingOptionDefinitionListings',
      referralSharingOptionDefinitionListings
    );
  }

  @dropTask
  *fetchReferralSharingOptions() {
    const referralSharingOptions = yield this.store.query(
      'referral-sharing-option',
      {
        account_id: this.account.id,
        include: 'notifications',
      }
    );
    return this.set('referralSharingOptions', referralSharingOptions);
  }

  @dropTask
  *saveReferralSharingOptions(changesets) {
    try {
      yield changesets.validate();
      if (changesets.isInvalid) {
        return;
      }

      changesets.execute();

      let changesetItems = changesets.items.filter((item) => item.isDirty);
      let imageUpdated;
      let childTasks = [];

      changesetItems.forEach((changeset) => {
        imageUpdated = changeset.get('change').meta_tag_image_url;

        if (imageUpdated === null) {
          let referralSharingOption = this.referralSharingOptions.find(
            (option) =>
              option.type ===
              changeset.get(
                'referral_sharing_option_definition.referral_sharing_option_type'
              )
          );
          childTasks.push(
            referralSharingOption.removeImage({ image: 'meta_tag_image' })
          );
        }
        childTasks.push(this.saveReferralSharingOption.perform(changeset));
      });

      yield all(childTasks);

      this.alert.info('Sharing settings saved');
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  @task
  *saveReferralSharingOption(referralSharingOption) {
    yield referralSharingOption.save();
  }
}
