import {
  validateFormat,
  validatePresence,
} from 'ember-changeset-validations/validators';

export default {
  name: validatePresence(true),

  url: validateFormat({
    description: 'Store URL',
    type: 'url',
    allowBlank: true,
  }),

  sender_name: validatePresence(true),
  sender_email: validateFormat({
    type: 'email',
    description: 'Reply-to email',
  }),

  billing_email: validateFormat({ type: 'email' }),
};
