import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';

export default class AccountOrderLimit extends Model {
  @attr('boolean') isOverOrderLimit;
  @attr('boolean') isPastOrderLimitGracePeriod;
  @attr('utc') orderLimitEvaluationStartsAt;
  @attr('utc') orderLimitLastEvaluatedAt;
  @attr('utc') orderLimitNextEvaluatedAt;
  @attr('utc') orderLimitGracePeriodEndsAt;
  @attr('number') monthlyOrderLimit;
  @attr('number') totalMonthlyOrdersWhenLastEvaluated;
  @attr('number') totalMonthlyOrdersInCurrentEvaluationPeriod;
  @attr('number') billingSubscriptionId;

  @attr('number') totalOrdersWithNoEmailCurrentMonth;
  @attr('number') totalOrdersWithNoEmailPreviousMonth;
  @attr('number') totalBillableOrdersCurrentMonth;
  @attr('number') totalBillableOrdersPreviousMonth;
  @attr('number') totalBillableOrders2MonthsAgo;
  @attr('number') totalOrdersExemptedByPricingProtectionCurrentMonth;
  @attr('number') totalOrdersExemptedByPricingProtectionPreviousMonth;
  @attr('number') totalProjectedBillableOrdersCurrentMonth;

  @belongsTo('account', { async: false, inverse: 'account' })
  accountOrderLimit;

  @computed('orderLimitEvaluationStartsAt')
  get isInOrderLimitTrialPeriod() {
    return moment().isBefore(this.orderLimitEvaluationStartsAt);
  }

  @computed('isOverOrderLimit', 'orderLimitEvaluationStartsAt')
  get isInOrderLimitEvaluationPeriod() {
    return (
      moment().isAfter(this.orderLimitEvaluationStartsAt) &&
      !this.isOverOrderLimit
    );
  }

  @computed(
    'isOverOrderLimit',
    'orderLimitEvaluationStartsAt',
    'isPastOrderLimitGracePeriod'
  )
  get isInOrderLimitGracePeriod() {
    return (
      moment().isAfter(this.orderLimitEvaluationStartsAt) &&
      this.isOverOrderLimit &&
      !this.isPastOrderLimitGracePeriod
    );
  }

  @computed(
    'isOverOrderLimit',
    'orderLimitEvaluationStartsAt',
    'isPastOrderLimitGracePeriod'
  )
  get isInOrderLimitUpgradePeriod() {
    return (
      moment().isAfter(this.orderLimitEvaluationStartsAt) &&
      this.isOverOrderLimit &&
      this.isPastOrderLimitGracePeriod
    );
  }
}
