/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default Mixin.create({
  sesh: service(),
  store: service(),

  modelForExport: null,
  accountExportType: null,
  queryParams: {},

  model() {
    let superModelPromise = this._super(...arguments);
    let modelForExportPromise = this.store.query(this.get('modelForExport'), {
      account_id: this.get('sesh.account.id'),
      page_size: 1,
      page: 1,
      ...this.queryParams,
    });

    let latestAccountExportsPromise = this.store.query('account-export', {
      type: this.get('accountExportType'),
      include: 'trackable_task',
      page_size: 1,
      page: 1,
    });

    return hash({
      modelForExport: modelForExportPromise,
      latestAccountExports: latestAccountExportsPromise,
      superModel: superModelPromise,
    });
  },

  setupController(controller, model) {
    this._super(controller, model.superModel);

    let latestAccountExport = model.latestAccountExports[0];
    let latestExportTrackableTask =
      latestAccountExport && latestAccountExport.get('trackableTask');

    controller.setProperties({
      accountExportType: this.accountExportType,
      showEmptyState: isEmpty(model.modelForExport),
      latestAccountExport: latestAccountExport,
      showExportProgress:
        latestExportTrackableTask &&
        !latestExportTrackableTask.get('isFinished'),
    });
  },
});
