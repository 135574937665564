import { validatePresence } from 'ember-changeset-validations/validators';

export default {
  // NOTE: these are the API user email & password
  api_key: validatePresence({ presence: true, description: 'API user email' }),
  api_secret: validatePresence({
    presence: true,
    description: 'API user password',
  }),
};
