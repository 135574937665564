/**
 * Overwrites default ember-changeset-validations
 * https://github.com/DockYard/ember-changeset-validations#overriding-validation-messages
 */
export default {
  // Overwritten
  notAnInteger: '{description} must be a whole number',

  // Custom
  handlebarsInvalid: '{description} is invalid',
  handlebarsInvalidFor: '{description} is invalid: {errorMessage}',
  emailDisposable: '{description} must be a real email address',

  // Defaults
  inclusion: '{description} is not included in the list',
  exclusion: '{description} is reserved',
  invalid: '{description} is invalid',
  confirmation: "{description} doesn't match {on}",
  accepted: '{description} must be accepted',
  empty: "{description} can't be empty",
  blank: '{description} must be blank',
  present: "{description} can't be blank",
  collection: '{description} must be a collection',
  singular: "{description} can't be a collection",
  tooLong: '{description} is too long (maximum is {max} characters)',
  tooShort: '{description} is too short (minimum is {min} characters)',
  between: '{description} must be between {min} and {max} characters',
  before: '{description} must be before {before}',
  onOrBefore: '{description} must be on or before {onOrBefore}',
  after: '{description} must be after {after}',
  onOrAfter: '{description} must be on or after {onOrAfter}',
  wrongDateFormat: '{description} must be in the format of {format}',
  wrongLength: '{description} is the wrong length (should be {is} characters)',
  notANumber: '{description} must be a number',
  greaterThan: '{description} must be greater than {gt}',
  greaterThanOrEqualTo: '{description} must be greater than or equal to {gte}',
  equalTo: '{description} must be equal to {is}',
  lessThan: '{description} must be less than {lt}',
  lessThanOrEqualTo: '{description} must be less than or equal to {lte}',
  otherThan: '{description} must be other than {value}',
  odd: '{description} must be odd',
  even: '{description} must be even',
  positive: '{description} must be positive',
  multipleOf: '{description} must be a multiple of {multipleOf}',
  date: '{description} must be a valid date',
  email: '{description} must be a valid email address',
  phone: '{description} must be a valid phone number',
  url: '{description} must be a valid url',
};
