import { modifier } from 'ember-modifier';
import { focusFirstFocusableNode } from '../utilities/focus.ts';

interface FocusModifierSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      disabled?: boolean;
      root: HTMLElement | null;
    };
  };
}

// `focus` will automatically focus the first focusable element
// that it finds in it's children when it is mounted, unless the
// user specified an `autoFocus` element.
export const focus = modifier<FocusModifierSignature>(
  (element, _positional, { disabled, root }) => {
    if (disabled || !root) {
      return;
    }

    const node = root || element;

    if (!node || node.querySelector('[autofocus]')) {
      return;
    }

    focusFirstFocusableNode(node, false);
  },
);

export default focus;
