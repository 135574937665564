/* import __COLOCATED_TEMPLATE__ from './progress.hbs'; */
import Component from '@glimmer/component';

export default class WizardProgress extends Component {
  get progressPercentage() {
    const { stepNum, numSteps } = this.args;
    if (!numSteps) {
      return 0;
    }

    return (100 * stepNum) / numSteps;
  }
}
