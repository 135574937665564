import { validateLength } from 'ember-changeset-validations/validators';

export default {
  customizableTitle: validateLength({ max: 200, description: 'Title' }),
  customizableSubtitle: validateLength({
    max: 500,
    description: 'Description',
  }),
  customizableCta: validateLength({ max: 50, description: 'Button' }),
};
