import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  creditReason: attr('string'),
  programMembers: attr('number'),
  monthlyOrders: attr('number'),
  smileApps: attr('number'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  account: belongsTo('account', { async: false, inverse: 'usageCredits' }),
});
