import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ShopifyAuthRoute extends Route {
  @service('router') router;
  @service session;
  @service shopify;

  beforeModel() {
    super.beforeModel(...arguments);
    return this.signIn();
  }

  async signIn() {
    if (!this.shopify.isEmbedded) {
      // If we don't have a Shopify App Bridge instance at this point, nothing more to do
      this.router.transitionTo('home');
      return;
    }

    try {
      await this.session.authenticate('authenticator:shopify-session-token');
    } catch (err) {
      // TODO update ember-smile-core/adapters/errors to support `fetch` error formats, which are
      // objects with a `status` === 401 in case of UnauthorizedError's
      if (err?.status === 401) {
        // This handles an edge case where the app is still installed on the store but the store is
        // no longer in our system (ex: deleted through `internal`)
        await this.shopify.triggerOauth();
      }

      throw err;
    }
  }
}
