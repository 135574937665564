/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';

@classNames('breakdown-details')
export default class BreakdownDetails extends Component {
  /**
   * The billing subscription's main billing product name.
   *
   * @type {String}
   * @default null
   * @public
   */
  basePlanName = null;

  /**
   * Whether to show additional members rate.
   *
   * @type {Boolean}
   * @default false
   * @public
   */
  showAdditionalMembersRate = false;
}
