import { getOwner, setOwner } from '@ember/application';
import { LegacyNetworkHandler } from '@ember-data/legacy-compat';
import RequestManager from '@ember-data/request';
import Fetch from '@ember-data/request/fetch';
import AuthHandler from './auth-handler';
import ActiveRecordHandler from '../utils/ember-data/active-record-handler';

export default class Requests extends RequestManager {
  constructor(args) {
    super(args);

    const authHandler = new AuthHandler();
    setOwner(authHandler, getOwner(this));

    this.use([authHandler, LegacyNetworkHandler, ActiveRecordHandler, Fetch]);
  }
}
