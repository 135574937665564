import Model, { attr } from '@ember-data/model';

export default Model.extend({
  programMembersCount: attr('number'),
  smileAppsCount: attr('number'),
  monthlyOrders: attr('number'),
  isProgramMembersInfinite: attr('boolean'),
  isSmileAppsInfinite: attr('boolean'),
  isMonthlyOrdersInfinite: attr('boolean'),

  createdAt: attr('date'),
  updatedAt: attr('date'),
});
