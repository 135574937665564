import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { memberAction, collectionAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export default Model.extend({
  type: attr('string'),
  name: attr('string'),
  canBeInstalledMultipleTimes: attr('boolean'),
  oauthConnectUrl: attr('string'),
  maintainerContactEmail: attr('string'),
  source: attr('string'),
  isPlatformApp: attr('boolean'),

  appListing: belongsTo('app-listing', { async: false, inverse: 'app' }),
  oauth2Application: belongsTo('oauth2-application', {
    async: false,
    inverse: 'app',
  }),
  rewardDefinitions: hasMany('reward-definition', {
    async: false,
    inverse: null,
  }),
  activityDefinitions: hasMany('activity-definition', {
    async: false,
    inverse: 'app',
  }),
  newIntegrations: hasMany('new-integration', { async: false, inverse: 'app' }),

  isThirdParty: equal('source', 'third_party').readOnly(),
  isSecondParty: equal('source', 'second_party').readOnly(),

  install: memberAction({
    path: 'install',
    type: 'post',
    after: serializeAndPush,
  }),
  createAndInstall: collectionAction({
    path: 'create_and_install',
    type: 'post',
    after: serializeAndPush,
  }),
});
