import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    reward_program_definition: { serialize: false, deserialize: 'records' },
    points_expiry_reminders: { serialize: false, deserialize: 'records' },
    initialRefreshTiersTask: { serialize: false, deserialize: 'records' },
    shopifyVipMetafieldSync: { serialize: false, deserialize: 'records' },
  },
});
