/* import __COLOCATED_TEMPLATE__ from './html-emails-deprecation-info.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

@tagName('')
export default class HtmlEmailsDeprecationInfoComponent extends Component {
  @service billing;
  @service sesh;

  @readOnly('sesh.account.showHtmlEmailUpdatesCard')
  shouldRender;

  @action
  contactSupport() {
    this.billing.contactSales(
      'I have a question about custom HTML emails no longer supported'
    );
  }

  @action
  dismissCard() {
    let { account } = this.sesh;
    account.set('showHtmlEmailUpdatesCard', false);
    account.save();
  }
}
