/* import __COLOCATED_TEMPLATE__ from './promote-customized-banner-image-feature.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

@tagName('')
export default class PromoteCustomizedBannerImageFeature extends Component {
  @service router;

  @action
  transitionToDisplayTheme() {
    this.router.transitionTo('settings.branding.theme', {
      queryParams: { displaySection: 'banner-images' },
    });
  }
}
