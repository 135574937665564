import {
  validateFormat,
  validatePresence,
} from 'ember-changeset-validations/validators';
import validateActiveUrl from '../validators/valid-active-url';

export default {
  customized_landing_page_url: validateActiveUrl(),
  customized_referral_domain: validateFormat({
    allowBlank: true,
    // Starts with no protocol, contains something, then a .tld at least
    regex: /(.*\.[^\s]{2,})/,
    message: 'Must be a valid URL (example.com)',
  }),
  customized_points_label_plural: validatePresence({
    presence: true,
    ignoreBlank: true,
    on: 'customized_points_label_singular',
    message: 'You must specify a plural label for your points currency',
  }),
  customized_points_label_singular: validatePresence({
    presence: true,
    ignoreBlank: true,
    on: 'customized_points_label_plural',
    message: 'You must specify a singular label for your points currency',
  }),
};
