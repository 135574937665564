import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { customizableProperty } from 'smile-admin/utils/customizable-property';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export default Model.extend({
  name: attr('string'),
  range_minimum: attr('number'),
  image_url: attr('string'),
  imageSvg: attr('string'),
  benefit_descriptions: attr('array'),
  string_benefits: attr('array'),
  range_description: attr('string'),
  formatted_range_minimum: attr('string'),

  customized_image_url: attr('string'),
  customized_image_temp_s3_key: attr('string'),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  // TODO: The `range_minimum` field is being deprecated in favour
  // of `milestone`. Currently the API has backwards-compatible support
  // for the old field (i.e. if you send the old field it'll save it to
  // the new db column). Since API gets real confused if you send both
  // the old AND new field at the same time, I'm commenting this out
  // for the time being. When we go to migrate to the new field, we
  // should do an all-at-once switchover so that we are only ever
  // sending one of the two fields (never both at the same time).
  //
  // milestone: attr('number'),

  // Milestone VIP-specific fields.
  milestoneDescription: attr('string'),

  reward_program: belongsTo('reward-program', { async: false, inverse: null }),
  rewards: hasMany('reward', { async: false, inverse: 'vip_tier' }),

  imageUrl: customizableProperty('image_url'),

  isFirstTier: equal('range_minimum', 0),

  removeImage: memberAction({
    path: 'remove_image',
    after: serializeAndPush,
  }),
});
