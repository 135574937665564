import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  title: attr('string'),
  price: attr('number'),

  product: belongsTo('shopify-product', { async: false, inverse: 'variants' }),

  // URL to the product variant on the store frontend.
  urlForStore: computed(function () {
    return `${this.get('product.urlForStore')}?variant=${this.get('id')}`;
  }).readOnly(),

  displayTitle: computed('title', function () {
    // Replace " / " with a space to make a readable variant title.
    return this.get('title').replace(/ \/ /g, ' ');
  }).readOnly(),
});
