import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import moment from 'moment';

const states = {
  running: 'running',
  scheduled: 'scheduled',
  finished: 'finished',
};

export const operators = {
  multiplication: 'multiplication',
  addition: 'addition',
};

export default Model.extend({
  type: attr('string'),
  startsAt: attr('moment'),
  endsAt: attr('moment'),
  bonusOperator: attr('string'),
  bonusOperand: attr('number'),
  name: attr('string'),
  earningDescription: attr('string'),
  valueDescription: attr('string'),
  bonusDescription: attr('string'),
  iconUrl: attr('string'),
  customizedIconUrl: attr('string'),

  account: belongsTo('account', { async: false, inverse: null }),
  activityRuleBonusDefinition: belongsTo('activity-rule-bonus-definition', {
    async: false,
    inverse: null,
  }),
  activityRule: belongsTo('activity-rule', {
    async: false,
    inverse: 'activityRuleBonuses',
  }),
  vipTier: belongsTo('vip-tier', { async: false, inverse: null }),

  hasStarted: computed('startsAt', function () {
    return moment().isAfter(this.startsAt);
  }).readOnly(),

  hasEnded: computed('endsAt', function () {
    return moment().isAfter(this.endsAt);
  }).readOnly(),

  isActive: computed('hasStarted', 'hasEnded', function () {
    let { hasStarted, hasEnded } = this;
    return hasStarted && !hasEnded;
  }).readOnly(),

  isScheduled: computed('hasStarted', 'hasEnded', function () {
    let { hasStarted, hasEnded } = this;
    return !hasStarted && !hasEnded;
  }).readOnly(),

  state: computed('isActive', 'isScheduled', 'hasEnded', function () {
    if (this.isActive) {
      return states.running;
    }

    if (this.isScheduled) {
      return states.scheduled;
    }

    if (this.hasEnded) {
      return states.finished;
    }

    return null;
  }).readOnly(),

  spansNewYear: computed('startsAt', 'endsAt', function () {
    let { startsAt, endsAt } = this;

    return startsAt.year() !== endsAt.year();
  }).readOnly(),

  cancel: memberAction({
    path: 'cancel',
    type: 'put',
    after: serializeAndPush,
  }),
});
