import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  isCreatable: attr('boolean'),
  isUpgradeRequired: attr('boolean'),
  isMissingRequiredIntegration: attr('boolean'),

  activityRuleDefinition: belongsTo('activity-rule-definition', {
    async: false,
    inverse: null,
  }),
});
