/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  queryParams: ['state', 'page'],

  state: null,
  page: 1,

  actions: {
    changeFilters(filters) {
      this.setProperties(Object.assign({ page: 1 }, filters));
    },
  },
});
