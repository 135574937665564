import { isValidMoment } from 'smile-admin/helpers/is-valid-moment';
import moment from 'moment';

export function combineDateAndTime({ date, time }) {
  if (!isValidMoment(date) || !isValidMoment(time)) {
    return null;
  }

  return moment(date).set({
    hours: time.hours(),
    minutes: time.minutes(),
    seconds: time.seconds(),
    milliseconds: time.milliseconds(),
  });
}
