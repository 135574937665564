import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  activity_type: attr('string'),
  activityData: attr('object'),
  rule_matched: attr('boolean'),
  rule_not_matched_reason: attr('string'),

  customer: belongsTo('customer', { async: false, inverse: null }),
  points_transactions: hasMany('points-transaction', {
    async: false,
    inverse: 'activity',
  }),
});
