import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    reward_program: { serialize: 'id', deserialize: 'id' },

    advocateReward: { serialize: 'id', deserialize: 'records' },
    friendTrackingReward: { serialize: 'id', deserialize: 'records' },
  },
});
