import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import { setBuildURLConfig } from '@ember-data/request-utils';
import { isDevelopingApp, macroCondition, importSync } from '@embroider/macros';

import config from 'smile-admin/config/environment';
import './deprecation-workflow';

setBuildURLConfig({
  host: config.apiHost,
  namespace: config.apiNamespace,
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

if (macroCondition(isDevelopingApp())) {
  if (config.useMirageInDevelopment) {
    const createMirageServer = importSync('./mirage/server').default;
    const server = createMirageServer({
      environment: config.environment,
      env: config,
      scenario: 'shopify',
    });
    server.logging = true;

    console.log('Using Mirage server in development enabled');
  }
}
