import { tracked } from '@glimmer/tracking';

/*
 * Object to handle transforming data between the format used by the `activity-rule-bonus`
 * model and that required by the schedule card's form.
 */
export default class ActivityRuleBonusScheduleFormData {
  // The campaign start and end date/times are stored as single fields. However, the UI splits
  // them between different controls. To enable the requested designs, we split the date/times
  // into separate date and time components, then recombine them before sending them to the
  // back end. This avoids the need to "guess" what to set the time to when the merchant picks
  // a date.
  @tracked startDate = null;
  @tracked startTime = null;
  @tracked endDate = null;
  @tracked endTime = null;

  // Internally, "start immediately" is represented as a null value for the `startsAt` property.
  // However, if we null out that value, the date/time pickers will lose their values and the
  // merchant will have to set them again if they check the "start immediately" checkbox then
  // change their mind and uncheck it. To work around that situation, we keep track of the
  // checkbox internally and mutate `startsAt` when committing changes to the back end.
  // We can assume that this should always be initialised to `false` because we only allow editing
  // activity rule bonuses that are either new (i.e. no schedule yet) or not yet started.
  @tracked startsImmediately = false;

  // Backs the radio button used to choose between preset campaign durations or a custom end date/time.
  @tracked endTimePreset = null;
}
