/**
 * Wraps HTTP requests to allow us to hook into requests made by e.g. 3rd-party code.
 * Usage:
 *
 *  let interceptor = new XhrInterceptor({
 *    Xhr:             XMLHttpRequest class you want to intercept calls from; this will usually
 *                     be window.XMLHttpRequest unless you need to hook calls from e.g. a frame,
 *    shouldIntercept: function called on XHR `open` (with the same params as passed to `open`)
 *                     to determine whether its `send` call should be hooked,
 *    onSend:          function invoked when the XHR's `send` method is called if `shouldIntercept`
 *                     returned a truthy value. `onSend` will be passed these parameters:
 *                      - origSendInvocation: a parameterless function to invoke the original `send` call
 *                      - openInvocationArgs: an array of the parameters passed to the XHR `open` call
 *  })
 *
 * When you are finished listening to requests, you should call `interceptor.destroy()`
 * to restore the original XHR functionality.
 */
export class XhrInterceptor {
  _origOpen = null;
  _Xhr = null;
  _shouldIntercept = null;
  _onSend = null;

  constructor({ Xhr, shouldIntercept, onSend }) {
    this._Xhr = Xhr;
    this._shouldIntercept = shouldIntercept;
    this._onSend = onSend;

    this._wrapXhr();
  }

  destroy() {
    this._unwrapXhr();
  }

  _wrapXhr() {
    if (!this._Xhr) {
      return;
    }

    // Replace XHR's `open` method on the prototype, so that
    // ALL instances will share this wrapper method.
    this._origOpen = this._Xhr.prototype.open;
    let interceptor = this;
    this._Xhr.prototype.open = function (...openInvocationArgs) {
      // Be careful with contexts here - `this` refers to a wrapped
      // XHR instance, NOT the XhrInterceptor instance!
      if (interceptor._shouldIntercept(...openInvocationArgs)) {
        // Replace this one XHR instance's `send` method
        // to allow interception when it's invoked.
        let origSend = this.send;
        this.send = function (...sendInvocationArgs) {
          let origSendInvocation = origSend.bind(this, ...sendInvocationArgs);
          return interceptor._onSend(origSendInvocation, openInvocationArgs);
        };
      }

      interceptor._origOpen.apply(this, openInvocationArgs);
    };
  }

  _unwrapXhr() {
    if (!this._Xhr) {
      return;
    }

    this._Xhr.prototype.open = this._origOpen;
  }
}
