/* import __COLOCATED_TEMPLATE__ from './adjust-points-balance.hbs'; */
import BasePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/base';
import { isNone, isBlank } from '@ember/utils';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import pointsTransactionValidations from 'smile-admin/validations/points-transaction';

export default BasePolarisModal.extend({
  classNames: ['polaris-modals-customer-adjust-points-balance-component'],

  alert: service(),
  errorHandler: service(),
  intl: service(),
  sesh: service(),
  store: service(),

  pointsAdjustment: null,
  notifyCustomer: false,

  pointsTransactionValidations,

  customer: readOnly('modalParams.customer'),

  pointsTransactionChangeset: computed('pointsTransaction', function () {
    let pointsTransaction = this.get('pointsTransaction');

    if (isNone(pointsTransaction)) {
      return null;
    }

    let pointsTransactionValidations = this.get('pointsTransactionValidations');

    return new Changeset(
      pointsTransaction,
      lookupValidator(pointsTransactionValidations),
      pointsTransactionValidations,
      { skipValidate: true }
    );
  }),

  newBalance: computed(
    'customer.points_balance',
    'pointsTransactionChangeset.points_change',
    function () {
      let balance = parseInt(this.get('customer.points_balance'));
      let pointsChange = parseInt(
        this.get('pointsTransactionChangeset.points_change')
      );

      if (!isNaN(pointsChange)) {
        balance += pointsChange;
      }

      return balance;
    }
  ).readOnly(),

  applyPointsAdjustment: task(function* (changeset) {
    yield changeset.validate();

    if (changeset.get('isInvalid')) {
      return;
    }

    try {
      if (isBlank(changeset.get('comment'))) {
        changeset.set('comment', this.intl.t('points_transaction.comment'));
      }

      yield changeset.save();

      // TODO: need to reload customer points activity table here if it's visible
      yield this.get('customer').reload();

      this.alert.info('Points adjusted');
    } catch (err) {
      this.errorHandler.handle(err);
    } finally {
      this.send('closeModal');
    }
  }),

  init() {
    this._super(...arguments);

    let pointsTransactionProps = {
      customer: this.get('customer'),
      account_id: this.sesh.get('account.id'),
    };

    this.set(
      'pointsTransaction',
      this.get('store').createRecord(
        'points-transaction',
        pointsTransactionProps
      )
    );
  },

  willDestroyElement() {
    let pointsTransaction = this.get('pointsTransaction');
    if (pointsTransaction.get('isNew')) {
      pointsTransaction.rollbackAttributes();
    }

    this._super(...arguments);
  },

  actions: {
    notifyCustomerChanged([notifyCustomer]) {
      this.set('notifyCustomer', notifyCustomer);
    },
  },
});
