import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';

export default {
  name: validatePresence({ presence: true, description: 'Tier name' }),
  range_minimum: [
    validateNumber({ integer: true, gte: 0, description: 'Milestone amount' }),
  ],
};
