import Transform from '@ember-data/serializer/transform';
import EmberObject from '@ember/object';
import { camelize } from '@ember/string';
import { isBlank, isEmpty, typeOf } from '@ember/utils';

/**
 * This is used for transforming nested object data, so that
 * mocked associations are not poluting ember data store.
 *
 * This will transform a model property into an Ember Object.
 * By default, keys will be camelCased and will also do the same for nested objects.
 * It also supports transforming into an EmberObject objects which keys are of the
 * form "customer.name" (like we use in template variables returned from the API)
 *
 * Options:
 *    * camelCase - whether or not keys should be camelCased. Default: true.
 *    * recursive - wheather or not should transform nested objects too.
 *                  Default: true
 */
export default Transform.extend({
  deserialize(serialized, options) {
    this.camelCase = isBlank(options.camelCase) ? true : options.camelCase;
    this.recursive = isBlank(options.recursive) ? true : options.recursive;
    serialized = this.toEmberObject(serialized);

    serialized = isEmpty(serialized) ? null : EmberObject.create(serialized);

    return serialized;
  },

  serialize(/* deserialized */) {
    return null;
  },

  toEmberObject(obj) {
    if (isBlank(obj)) {
      return null;
    }

    let emObject = {};
    Object.keys(obj).forEach((key) => {
      let val = obj[key];
      if (typeOf(val) === 'object' && this.recursive) {
        val = this.toEmberObject(val);
      }

      if (key.indexOf('.') !== -1) {
        let keys = key.split('.');
        let nestedObjKey = this.camelCase ? camelize(keys[1]) : keys[1];
        let nestedObj = EmberObject.create();
        nestedObj[nestedObjKey] = val;

        key = keys[0];
        val = nestedObj;
      }

      key = this.camelCase ? camelize(key) : key;
      emObject[key] = val;
    });

    return EmberObject.create(emObject);
  },
});
