/* import __COLOCATED_TEMPLATE__ from './star-rating.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class StarRating extends Component {
  get hasPreviewedRating() {
    return typeof this.args.previewedRating === 'number';
  }

  @action
  previewRating(i) {
    this.args.onRatingPreviewed?.(i);
  }
}
