/* import __COLOCATED_TEMPLATE__ from './home.hbs'; */
import Component from '@ember/component';
import BillingSubscriptionData from 'smile-admin/objects/billing-subscription-data';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { and } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { camelize } from '@ember/string';
import { tagName } from '@ember-decorators/component';
import { storageFor } from 'ember-local-storage';
import {
  all,
  dropTask,
  waitForProperty,
  task,
  restartableTask,
  lastValue,
} from 'ember-concurrency';
import { referralIncludes } from 'smile-admin/routes/reward-programs/referrals';
import { dismissalFlagsByCardType } from 'smile-admin/models/home-card';
import moment from 'moment';

@tagName('')
export default class HomeRoutePage extends Component {
  @service ajax;
  @service billing;
  @service intercom;
  @service config;
  @service errorHandler;
  @service featureRollouts;
  @service router;
  @service sesh;
  @service shopify;
  @service store;
  @service utils;

  @storageFor('sweet-tooth-session')
  localStorage;

  @tracked
  homeCardListings = [];

  @tracked
  homeCardsByCategory = {};

  @lastValue('fetchAppEmbedCheck')
  appEmbedCheck;

  @and(
    'sesh.account.eligibleForLauncherlessPrograms',
    'localStorage.showChooseDisplayInteractionMediumPostOnboarding'
  )
  showChooseInteractionMediumModal;

  get isAddSmileToYourStoreStepComplete() {
    const { account } = this.sesh;

    if (account.usesShopifyAppEmbed) {
      return !this.appEmbedCheck?.prompt_to_enable;
    }

    return (
      account.lastSeenSmileUiInitRequest &&
      moment(account.lastSeenSmileUiInitRequest).isAfter(
        moment().subtract(5, 'days')
      )
    );
  }

  get addSmileToYourStoreStepButtonText() {
    if (this.sesh.account.usesShopifyAppEmbed) {
      return this.isAddSmileToYourStoreStepComplete
        ? 'Go to app embed settings'
        : 'Enable Smile in app embed settings';
    }

    return this.isAddSmileToYourStoreStepComplete
      ? 'Go to visibility settings'
      : 'Make Smile visible on your store';
  }

  get shouldShowNewStartGuide() {
    const areAllStartGuideStepsComplete = this.startGuideSteps.every(
      (step) => step.isComplete
    );

    return this.sesh.hasShopifyPlatform && !areAllStartGuideStepsComplete;
  }

  get startGuideSteps() {
    const isOnPaidPlan = !this.sesh.account.chargeableSubscription.isFreeType;

    const launchProgramStep = {
      title: 'Launch your loyalty program',
      description:
        'Turn your loyalty program on so customers earn points on every order and are more likely to shop again!',
      imagePath: '/assets/illustrations/start-guide/activate.svg',
      isComplete: this.isPointsProgramEnabled,
      isActionButtonPrimary: !this.isPointsProgramEnabled,
      actionButtonText: this.isPointsProgramEnabled
        ? 'Go to program settings'
        : 'Launch your program',
      onActionButtonClick: () => {
        this.router.transitionTo('reward-programs.points');
      },
    };

    const addSmileToStoreStep = {
      title: 'Add Smile to your store theme',
      description:
        "Customers can't redeem their points until you add Smile to your store. Toggle Smile on and hit save in your Shopify theme settings so your customers can start redeeming.",
      imagePath: '/assets/illustrations/start-guide/launcher.svg',
      isComplete: this.isAddSmileToYourStoreStepComplete,
      actionButtonText: this.addSmileToYourStoreStepButtonText,
      isActionButtonPrimary: !this.isAddSmileToYourStoreStepComplete,
      onActionButtonClick: () => {
        if (this.sesh.account.usesShopifyAppEmbed) {
          this.utils.openExternalLink(
            this.sesh.platformIntegration.themeSettingsAppEmbedDeepLink
          );
        } else {
          this.router.transitionTo('settings.platform', {
            queryParams: { showDetailsOnLoad: true },
          });
        }
      },
    };

    const choosePlanStep = {
      title: 'Choose a plan',
      description:
        'Get the most out of Smile by choosing a plan. Get access to more features designed to boost your customer loyalty. ',
      imagePath: '/assets/illustrations/start-guide/trophy.svg',
      isComplete: isOnPaidPlan,
      actionButtonText: isOnPaidPlan
        ? 'Go to billing settings'
        : 'Choose a plan',
      isActionButtonPrimary: !isOnPaidPlan,
      onActionButtonClick: () => {
        this.router.transitionTo('settings.billing');
      },
    };

    return [launchProgramStep, addSmileToStoreStep, choosePlanStep];
  }

  get showMilestones() {
    return (
      !this.sesh.hasCustomPlatform &&
      this.isPointsProgramEnabled &&
      this.totalOrdersWithSmileDiscountCode < 2
    );
  }

  get milestonesFinished() {
    return (
      this.isPointsProgramEnabled && this.totalOrdersWithSmileDiscountCode >= 2
    );
  }

  get isPointsProgramEnabled() {
    return this.sesh.pointsRewardProgram?.is_enabled;
  }

  get totalOrdersWithSmileDiscountCode() {
    let { dataSet } = this.sesh.account;

    return dataSet?.totalOrdersWithSmileDiscountCode || 0;
  }

  get totalEarners() {
    let { dataSet } = this.sesh.account;

    return dataSet?.totalEarners || 0;
  }

  get totalRedeemers() {
    let { dataSet } = this.sesh.account;

    return dataSet?.totalRedeemers || 0;
  }

  get daysSinceSignupLessThan28days() {
    return this.sesh.account.daysSinceSignup < 20;
  }

  get title() {
    if (this.showMilestones && this.totalEarners === 0) {
      return 'Your loyalty program is live!';
    }
    return 'Welcome to Smile';
  }

  @computed('fetchDailyAccountSummaries.lastSuccessful.value')
  get dashboardData() {
    const { account } = this.sesh;

    const dailyAccountSummaries =
      this.fetchDailyAccountSummaries.lastSuccessful?.value || [];

    const parseData = (property) => {
      return dailyAccountSummaries.map((summary) => {
        return {
          x: summary.date,
          y: summary[property],
        };
      });
    };

    const salesInfluencedValues = parseData(
      'totalOrderValueWithSmileDiscountCode'
    );

    const salesInfluencedTotal = salesInfluencedValues.reduce((acc, value) => {
      return acc + value.y;
    }, 0);

    const data = [
      {
        title: 'Earners',
        description: 'Number of customers that earned points.',
        chartValues: parseData('totalEarners'),
        total: account.dataSet?.totalEarners28d || 0,
      },
      {
        title: 'Redeemers',
        description: 'Number of customers that redeemed points.',
        chartValues: parseData('totalRedeemers'),
        total: account.dataSet?.totalRedeemers28d || 0,
      },
    ];

    const billingSubscriptionData = new BillingSubscriptionData(
      account.chargeableSubscription
    );

    if (
      salesInfluencedTotal * 100 >
      billingSubscriptionData.priceTotalPerMonth
    ) {
      data.push({
        title: 'Sales influenced',
        description: 'Total sales that were placed with a Smile discount code.',
        chartValues: salesInfluencedValues,
        total: salesInfluencedTotal,
        format: 'currency',
      });
    } else {
      const newMembersValues = parseData('totalFirstTimeEarners');
      data.unshift({
        title: 'New Members',
        description: 'Total customers who earned points for the first time.',
        chartValues: newMembersValues,
        total: newMembersValues.reduce((acc, value) => {
          return acc + value.y;
        }, 0),
      });
    }

    return data;
  }

  @restartableTask
  *fetchDailyAccountSummaries() {
    return yield this.store.query('daily-account-summary', {});
  }

  @restartableTask
  *setupHomeCards() {
    let includes = [
      'home_card.unhealthy_integrations',
      'home_card.feature_release',
      'home_card.nudge',
      'home_card.activity_rule',
    ];

    try {
      this.homeCardListings = yield this.store.query('home-card-listing', {
        account_id: this.get('sesh.account.id'),
        include: includes.join(','),
      });
      this.categorizeAndFilterCards();
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  categorizeAndFilterCards() {
    const homeCardListings = [...this.homeCardListings].sort((a, b) => {
      return a.sort_order - b.sort_order;
    });
    const storage = this.localStorage;
    const homeCards = {};

    // Categorize listings and filter dismissed cards
    homeCardListings.forEach((listing) => {
      const {
        home_card: homeCard,
        home_card: { type, category },
      } = listing;

      if (!category || !homeCard.component || !homeCard.shouldRender) {
        return;
      }

      const isDismissedFlag = dismissalFlagsByCardType[type];
      const camelizedCategory = camelize(category);
      homeCards[camelizedCategory] = homeCards[camelizedCategory] || [];

      // Card isn't dismissible or hasn't been dismissed yet
      if (
        !dismissalFlagsByCardType.hasOwnProperty(type) ||
        !storage.get(isDismissedFlag)
      ) {
        if (!homeCards[camelizedCategory].includes(homeCard)) {
          homeCards[camelizedCategory].push(homeCard);
        }
      } else {
        homeCards[camelizedCategory] = homeCards[camelizedCategory].filter(
          (item) => item !== homeCard
        );
      }
    });

    this.homeCardsByCategory = homeCards;
  }

  @task
  *launchProductTour() {
    let { config, sesh, intercom } = this;
    let { account } = sesh;
    let homePageTourId = config.get('intercom.homePageV1TourId');

    if (
      !sesh.hasShopifyPlatform ||
      !config.get('intercom.enabled') ||
      !homePageTourId ||
      account.hasSeenHomePageProductTour ||
      !account.chargeableSubscription.isFree ||
      // Only show tour to accounts created on or after tour rollout date.
      account.created_at < new Date('Sep 21 2020')
    ) {
      return;
    }

    // If the app was loaded directly on this page, we need to wait for
    // Intercom to have finished booting before starting the tour.
    yield waitForProperty(this, 'intercom.isBooted');

    intercom.startTour(homePageTourId);

    account.set('hasSeenHomePageProductTour', true);
    yield account.save();
  }

  @dropTask
  *fetchSidebarData() {
    try {
      yield all([
        this.fetchRewardFulfillments.perform(),
        this.fetchPointsPurchase.perform(),
        this.fetchReferrals.perform(),
      ]);

      this.handleShowSidebarChange();
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  @dropTask
  *fetchRewardFulfillments() {
    return yield this.store.query('reward-fulfillment', {
      with_customer: true,
      state: 'completed',
      include: 'customer',
      excluded_source_types: 'points_purchase',
      page_size: 5,
    });
  }

  @dropTask
  *fetchPointsPurchase() {
    return yield this.store.query('points-purchase', {
      include: 'reward_fulfillment.customer',
      page_size: 5,
    });
  }

  @dropTask
  *fetchReferrals() {
    return yield this.store.query('referral', {
      include: referralIncludes,
      state: 'completed',
      page_size: 5,
    });
  }

  @dropTask
  *fetchAppEmbedCheck() {
    try {
      return yield this.ajax.request(
        `/v1/shopify_themes/app_embed_check/${this.sesh.platformIntegration.id}`
      );
    } catch (err) {
      // This endpoint can return error codes like 404 with a payload
      // that we want to return to the caller.
      //
      // Ex:
      // STATUS 404
      // {
      //   prompt_to_enable: true,
      //   message: "Merchant is missing the Smile App Embed Block entry on config/settings_data.json - initial enabling required",
      //   status: :not_found
      // }
      if (err.payload) {
        return err.payload;
      }
      this.errorHandler.handle(err);
    }
  }

  init() {
    super.init(...arguments);
    this.setupData.perform();
  }

  @restartableTask
  *setupData() {
    yield waitForProperty(this, 'sesh.user');

    this.setupHomeCards.perform();

    // Refresh account to show any existing `offered` billing-subscription
    // NOTEs:
    // - we're explicitly not waiting for this
    // - if it's the initial page load, no need to refetch this since we're already including this data in the initial API call
    if (!this.sesh.isInitialPageLoad) {
      this.billing.fetchBillingSubscriptionOffers.perform();
    }

    this.launchProductTour.perform();
    // this.fetchSidebarData.perform();

    if (this.sesh.account?.usesShopifyAppEmbed) {
      this.fetchAppEmbedCheck.perform();
    }

    this.fetchDailyAccountSummaries.perform();
  }

  @action
  handleShowSidebarChange() {
    const shouldShowSidebar =
      this.fetchRewardFulfillments.lastSuccessful?.value?.length > 0 ||
      this.fetchPointsPurchase.lastSuccessful?.value?.length > 0 ||
      this.fetchReferrals.lastSuccessful?.value?.length > 0;

    this.onChangeShowSidebar(shouldShowSidebar);
  }

  @action
  dismissHomeCard(hasDimissedCardFlag) {
    this.localStorage.set(hasDimissedCardFlag, true);

    // Have to wait until PolarisPopover calls `blur` on the popover
    // trigger before removing it from the set of cards that are being
    // rendered.
    next(this, () => {
      this.categorizeAndFilterCards();
    });
  }
}
