/* import __COLOCATED_TEMPLATE__ from './app-nav-content.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',

  media: service(),
  sesh: service(),
});
