import EmberRouter from '@embroider/router';
import { isPresent } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';
import moment from 'moment';
import config from 'smile-admin/config/environment';
import { datadogRum } from '@datadog/browser-rum';
import { isTesting, macroCondition } from '@embroider/macros';

const allowedRoutesForLockedAccounts = [
  'start',
  'restricted-access',
  'settings.index',
  'settings.billing.index',
  'logout',
];

export const allowedRoutesForRestrictedAccessOverLimit = [
  'restricted-access-over-limit',
  'settings.billing.index',
  'settings.billing.pricing-plans',
  'customers.filter-and-export',
  'logout',
];

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  @service billing;
  @service metrics;
  @service sesh;

  @storageFor('sweet-tooth-session')
  localStorage;

  init() {
    super.init(...arguments);

    this.on('routeWillChange', (transition) => {
      if (transition.isAborted) {
        return;
      }

      // Wherever we are, we're not allowing transitioning while a billing
      // operation is in progress
      if (this.billing.operations.isRunning) {
        transition.abort();
      }

      if (
        this.sesh.account?.lockedPendingPayment &&
        !allowedRoutesForLockedAccounts.includes(transition.to.name)
      ) {
        transition.abort();
      }

      if (
        this.sesh.account?.shouldSeeLockoutScreen &&
        (!allowedRoutesForRestrictedAccessOverLimit.includes(
          transition.to.name
        ) ||
          (transition.to.name === 'settings.billing.index' &&
            this.sesh.improveBillingUsability))
      ) {
        // when the merchant does a downgrade to the Free plan, he contines to be on the billing page
        // and when he tries to click on another page on the menu, he should be redirected to the
        // restricted-access-over-limit instead of beign stuck on the billing page
        if (
          transition.from?.name !== 'restricted-access-over-limit' &&
          !this.sesh.improveBillingUsability
        ) {
          this.replaceWith('restricted-access-over-limit');
        } else {
          transition.abort();
        }
      }
    });

    this.on('routeDidChange', (transition) => {
      this.sesh.isInitialPageLoad = transition.from === null;

      this._trackPage();

      // Don't bounce to the top of the page on query param changes.
      if (transition && !transition.queryParamsOnly) {
        this._resetScrollPosition();
      }

      this._trackSession();
    });
  }

  /**
   * Track page transitions.
   */
  _trackPage() {
    // Track page view with datadog
    // For more details, see: https://smileio.atlassian.net/browse/AS-675
    datadogRum.startView({ name: this._currentUrlWithoutIds() });

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      const currentRouteName = this.get('currentRouteName');
      const title = currentRouteName || 'unknown';
      const page = this.get('url');

      this.metrics.trackPage('GoogleTagManager', { page, title });
    });
  }

  /**
   * Reset scroll position when changing route.
   */
  _resetScrollPosition() {
    if (!window) {
      return;
    }

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', this, () => {
      // Support for old product
      window.scrollTo(0, 0);

      // Support for new Polaris product
      let contentElem = document.querySelector('.bm-content');
      if (contentElem && typeof contentElem.scrollTo === 'function') {
        contentElem.scrollTo(0, 0);
      }
    });
  }

  /**
   * Tracks the user's session.
   * We use Google's definition of a session which is any activity after 30min of inactivity.
   * Source: https://support.google.com/analytics/answer/2731565?hl=en
   */
  _trackSession() {
    if (isPresent(this.get('sesh.account.id'))) {
      this.set('localStorage.lastSessionActivityAt', moment());
    }
  }

  _currentUrlWithoutIds() {
    const currentUrl = this.currentURL || '/';

    return currentUrl
      .split('/')
      .map((segment) => (/^\d+$/.test(segment) ? '?' : segment)) //Replace all numeric segments with '?'
      .join('/');
  }
}

Router.map(function () {
  this.route('auth', function () {
    this.route('login');
    this.route('recover');
    this.route('reset');
    this.route('auto-login');
    this.route('shopify');
  });

  this.route('unsubscribe');

  /**
   * Authenticated routes
   */
  this.route('authenticated', { path: '/', resetNamespace: true }, function () {
    this.route('logout', { resetNamespace: true });

    // New onboarding quiz
    this.route('start', { resetNamespace: true });

    /**
     * The main app structure with sidebar,
     * top bar, content area, etc
     */
    this.route('main-app', { path: '/', resetNamespace: true }, function () {
      this.route('home', { path: '/', resetNamespace: true });
      this.route('restricted-access', { resetNamespace: true });
      this.route('restricted-access-over-limit', {
        path: '/upgrade',
        resetNamespace: true,
      });

      this.route('plan-canceled', { resetNamespace: true });

      // Points program routes
      this.route('customers', { resetNamespace: true }, function () {
        this.route('index', { path: '/' });
        this.route('show', { path: '/:customer_id' });
        this.route('filter-and-export');
      });

      this.route('analytics', { resetNamespace: true }, function () {
        this.route('reports', function () {
          this.route('index', { path: '/' });
          this.route('show', { path: '/:report_id' });
        });
        this.route('index', { path: '/' });
        this.route('points');
        this.route('referrals');
        this.route('members');
      });

      this.route('simplified-analytics', { resetNamespace: true }, function () {
        this.route('dashboard', { path: '/' });

        this.route('reports', function () {
          this.route('index', { path: '/' });
          this.route('show', { path: '/:report_id' });
        });
      });

      this.route('settings', { resetNamespace: true }, function () {
        this.route('index', { path: '/' });
        this.route('general');
        this.route('account');
        this.route('language');
        this.route('developer-tools');
        this.route('platform');
        this.route('billing', function () {
          this.route('index', { path: '/' });
          this.route('pricing-plans');
        });
        this.route('customer-accounts');
        this.route('branding', function () {
          this.route('theme', { path: '/' });
          this.route('email');
        });
        this.route('customer-notifications', function () {
          this.route('index', { path: '/' });
          this.route('edit', { path: '/edit/:customer_notification_id' });
        });
      });

      this.route('reward-programs', { resetNamespace: true }, function () {
        this.route('index', { path: '/' });
        this.route('activity');

        /**
         * Points program
         */
        this.route('points', function () {
          this.route('dashboard', {
            path: '/',
          });

          this.route('actions', function () {
            this.route('index', { path: '/' });
            this.route('new', {
              path: '/new/:activity_rule_definition_id',
            });
            this.route('show', {
              path: '/:activity_rule_id',
            });
          });

          // TODO: remove this route when Loox have updated
          // their URLs to use the new activity rule URL.
          this.route('redirect-from-old-select-activity-url', {
            path: '/earning/select-activity',
          });

          this.route('rewards', function () {
            this.route('index', { path: '/' });
            this.route('new', {
              path: '/new/:reward_definition_id',
            });
            this.route('show', {
              path: '/:points_product_id',
            });
          });

          this.route('activity');
        });

        /**
         * Referral program
         */
        this.route('referrals', function () {
          this.route('dashboard', {
            path: '/',
          });
          this.route('activity');
          this.route('referral', {
            path: '/activity/:referral_id',
          });

          this.route('reward', { path: '/:reward_for' }, function () {
            this.route('new', {
              path: '/new/:reward_definition_id',
            });
            this.route('show', {
              path: '/',
            });
          });
        });

        /**
         * VIP program
         */
        this.route('vip', function () {
          this.route('dashboard', { path: '/' });

          this.route('tiers', function () {
            this.route('new');
            this.route('show', {
              path: '/:vip_tier_id',
            });

            this.route('rewards.new', {
              path: '/:vip_tier_id/rewards/new/:reward_definition_id',
            });
            this.route('reward', {
              path: '/:vip_tier_id/rewards/:reward_id',
            });
          });

          this.route('activity');
        });

        /**
         * Points Bonuses
         */
        this.route('points-bonuses', function () {
          this.route('index', { path: '/' });
          this.route('new');
          this.route('show', { path: '/:activity_rule_bonus_id' });
        });
      });

      this.route('integrations', { resetNamespace: true }, function () {
        // TODO: remove this route when Stamped.io have updated
        // their redirect URL to use the new authorization URL.
        this.route('redirect-from-old-auth-url', {
          path: '/manage/apps/authorize/:account_app_listing_slug',
        });

        // Lists all integrations, both installed and available for installation.
        // Also contains the app info modal shown to merchants before they install.
        this.route('index', { path: '/' });

        // Merchants get redirected here to authorize an integration.
        this.route('authorize', {
          path: '/authorize/:account_app_listing_slug',
        });

        // View one app. The dynamic segment can either be a numeric new integration ID or an app slug.
        this.route('show', { path: '/:new_integration_id_or_app_slug' });
      });

      this.route('on-site', { resetNamespace: true }, function () {
        this.route('overview', { path: '/' });
        this.route('nudges', function () {
          this.route('index', { path: '/' });
          this.route('show', { path: '/:nudge_id' });
        });
        this.route('redeem-at-checkout');
        this.route('points-at-checkout');
        this.route('order-status');
        this.route('accounts');
        this.route('product');
        this.route('landing-page');

        this.route('panel');
        this.route('launcher');
      });

      if (macroCondition(isTesting())) {
        this.route('testing', {
          resetNamespace: true,
          path: '/testing/:customer_id',
        });
      }
    });
  });

  // Catch all route
  this.route('error-404', { path: '/*wildcard' });
});
