import { validatePresence } from 'ember-changeset-validations/validators';

export default {
  orderAwardStatusIds: validatePresence({
    presence: true,
    description: 'Reward status',
  }),
  orderCancelStatusIds: validatePresence({
    presence: true,
    description: 'Reward cancellation status',
  }),
};
