export const types = {
  bigcommerceOrder: 'bigcommerce_order',
  custom: 'custom',
  customerBirthday: 'customer_birthday',
  facebookLike: 'facebook_like',
  facebookShare: 'facebook_share',
  instagramFollow: 'instagram_follow',
  order: 'order',
  shopifyOnlineOrder: 'shopify_online_order',
  shopifyPosOrder: 'shopify_pos_order',
  shopifyProductReview: 'shopify_product_review',
  signup: 'signup',
  tiktokFollow: 'tiktok_follow',
  twitterFollow: 'twitter_follow',
  twitterShare: 'twitter_share',
  wixOrder: 'wix_order',
  yotpoProductReview: 'yotpo_product_review',
};

export const categoryNames = {
  store: 'Online Store',
  shopifyPos: 'Shopify POS',
  social: 'Social',
  smileApps: 'Smile Apps',
  custom: 'Custom Actions',
};

/**
 * Given a list of activity rules returns first order type activity rule
 *
 * @param {[ActivityRule]} activityRules  List of activity-rule records.
 * @returns {ActivityRule}                First matching order-type activity-rule.
 */
export function firstOrderActivityRule(activityRules) {
  return activityRules.find(isOrderActivityRule);
}

/**
 * Checks if an activity rule is an order type
 *
 * @param {ActivityRule} activityRule  Activity-rule record.
 * @returns {bool}                     Whether the activity rule is an order type.
 */
export function isOrderActivityRule(activityRule) {
  return [
    types.bigcommerceOrder,
    types.shopifyOnlineOrder,
    types.wixOrder,
    types.order,
  ].includes(activityRule.type);
}

export function orderTypeForPlatform(platform) {
  switch (platform) {
    case 'shopify':
      return types.shopifyOnlineOrder;
    case 'bigcommerce':
      return types.bigcommerceOrder;
    case 'wix':
      return types.wixOrder;
    default:
      return types.order;
  }
}
