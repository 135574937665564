import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    customer_notification_definition: {
      serialize: false,
      deserialize: 'records',
    },
    pointsExpiryReminder: {
      serialize: false,
      deserialize: 'records',
    },
  },
});
