/**
 * Sourced from:
 * http://urbbit.com/?q=item/retrieve&content=%7B%22id%22:%224c928c35-9ec8-11e5-84a4-74867ad6e338%22%7D
 * Variable names cleaned up to be more specific, and converted to an ember
 * utility function.
 */
export default function getQueryParams() {
  let queryParameters = {};
  let queryString = location.search.substring(1);
  let regex = /([^&=]+)=([^&]*)/g;

  // Creates a map with the query string parameters
  let match = regex.exec(queryString);
  while (match) {
    queryParameters[decodeURIComponent(match[1])] = decodeURIComponent(
      match[2]
    );
    match = regex.exec(queryString);
  }

  return queryParameters;
}
