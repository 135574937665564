/* import __COLOCATED_TEMPLATE__ from './promote-shopify-guest-referral-prompt.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';

@tagName('')
export default class PromoteShopifyGuestReferralPromptHomeCardComponent extends Component {
  @service sesh;

  @readOnly('sesh.account.showGuestReferralsEnablementCard')
  shouldRender;

  @action
  dismissCard() {
    let { account } = this.sesh;
    account.set('showGuestReferralsEnablementCard', false);
    account.save();
  }
}
