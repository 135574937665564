/**
 * There's a naming conflict with the helper provided by `ember-intl` addon.
 * TODO look into migrating to the ember-intl's helper if is supports all our
 * use cases.
 */

import { helper } from '@ember/component/helper';
import { isArray } from '@ember/array';
import numeral from 'numeral';

export function formatNumber(params, hash) {
  let { format } = hash || {};
  let number = params;

  if (isArray(params)) {
    number = params[0];
  }

  if (typeof number === 'undefined') {
    number = null;
  }

  if (isNaN(number)) {
    number = null;
  }

  return numeral(number).format(format);
}

export default helper(formatNumber);
