import { validatePresence } from 'ember-changeset-validations/validators';

export default {
  webdavUsername: validatePresence({
    presence: true,
    description: 'WebDAV username',
  }),
  webdavPassword: validatePresence({
    presence: true,
    description: 'WebDAV password',
  }),
};
