import Model, { attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';

/**
 * Example
 * {
 *   "id":"cad",
 *   "decimal_mark":".",
 *   "disambiguate_symbol":"C$",
 *   "html_entity":"$",
 *   "iso_code":"CAD",
 *   "iso_numeric":"124",
 *   "name":"Canadian Dollar",
 *   "priority":5,
 *   "smallest_denomination":5,
 *   "subunit":"Cent",
 *   "subunit_to_unit":100,
 *   "symbol":"$",
 *   "symbol_first":true,
 *   "thousands_separator":","
 * }
 */
export default Model.extend({
  decimal_mark: attr('string'),
  disambiguate_symbol: attr('string'),
  html_entity: attr('string'),
  iso_code: attr('string'),
  iso_numeric: attr('string'),
  name: attr('string'),
  priority: attr('string'),
  smallest_denomination: attr('number'),
  subunit: attr('string'),
  subunit_to_unit: attr('number'),
  symbol: attr('string'),
  symbol_first: attr('boolean'),
  thousands_separator: attr('string'),

  // This is an alias until we go camelCase (some helpers in ember-sweettooth-common rely on this)
  isoCode: alias('iso_code'),
  symbolFirst: alias('symbol_first'),
});
