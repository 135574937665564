import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class MainAppController extends Controller {
  @service router;
  @service sesh;

  queryParams = [
    {
      chargeId: {
        scope: 'controller',
        as: 'charge_id',
      },
      bypassUpgradeScreen: {
        as: 'bypass_upgrade_screen',
      },
    },
  ];

  /**
   * We get redirected to this page when a merchant approves/declines a `shopify` type
   * billing-subscription and get this charge ID we use to finish the flow.
   */
  chargeId;
}
