/* import __COLOCATED_TEMPLATE__ from './promote-guest-earning.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';

@tagName('')
export default class PromoteGuestEarningComponent extends Component {
  @service router;

  @action
  transitionToCustomerAccountsSettings() {
    this.router.transitionTo('settings.customer-accounts', {
      queryParams: { ge_source: 'home_card' },
    });
  }
}
