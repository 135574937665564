import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';
import buildDummyDataForTemplateValidations from 'smile-admin/utils/computed/build-dummy-data-for-template-validations';

export default Model.extend(
  addDefaultAndCustomizedFieldsToModel({
    // Visitor account creation
    joinProgramTitle: attr('string'),
    joinProgramProgramDescription: attr('string'),
    joinProgramCta: attr('string'),
    joinProgramAlreadyHaveAnAccount: attr('string'),

    signInFormTitle: attr('string'),
    signInFormDescription: attr('string'),
    signInFormCta: attr('string'),

    vipExplainerTitle: attr('string'),
    vipExplainerDescription: attr('string'),

    pointsExplainerTitle: attr('string'),
    pointsExplainerDescription: attr('string'),
    pointsExplainerEarnMorePointsCta: attr('string'),
    pointsExplainerSpendYourPointsCta: attr('string'),

    // Birthday entry
    birthdayFormTitle: attr('string'),
    birthdayFormDescription: attr('string'),
    birthdayFormSaveBirthdayCta: attr('string'),

    // Visitor referral program details
    referralsExplainerTitle: attr('string'),
    referralsExplainerDescription: attr('string'),

    // Member referrals summary details
    referralsSummaryTitle: attr('string'),
    referralsSummaryDescriptionTemplate: attr('string'),

    // for referral details view in panel
    referralDetailTitle: attr('string'),
    referralDetailDescription: attr('string'),

    // Referral landing
    referralLandingClaimDescriptionTemplate: attr('string'),
    referralLandingClaimGiftCta: attr('string'),
    referralLandingEnterEmailPlaceholder: attr('string'),

    // VIP program details
    vipProgramDetailsTitle: attr('string'),
    vipProgramTiersTitle: attr('string'),
    vipProgramTiersDescription: attr('string'),

    // Earn / Spend icons
    earnMorePointsIconUrl: attr('string'),
    spendYourPointsIconUrl: attr('string'),

    myRewardsSummaryLatestReward: attr('string'),

    // Array of section names
    rewardProgramCardOrder: attr(),

    // Shapes
    borderRadiusStyle: attr('string'),
    cardBorderRadiusStyle: attr('string'),
    buttonBorderRadiusStyle: attr('string'),
    inputBorderRadiusStyle: attr('string'),

    // Smile Branding
    // This is deliberately not flagged as a boolean because otherwise
    // ember-data coerces null values for customizedShowSmileBranding
    // to false, which interferes with our customizedOrDefault logic.
    showSmileBranding: attr(),

    // Wallpaper
    wallpaperOption: attr('string'),
  }),
  {
    // These `customized` attributes aren't using the
    // addDefaultAndCustomizedFieldsToModel mixin because
    // they do not have regular or `default` versions
    // of themselves.
    customizedEarnMorePointsIconTempS3Key: attr('string'),
    customizedSpendYourPointsIconTempS3Key: attr('string'),

    // Wallpaper. Although this field isn't writable, we update it
    // in our app for live previews on the panel.
    wallpaperUrl: attr('string'),

    updatedAt: attr('date'),

    // The server uses additional logic to compute this field for
    // the panel. Although this field isn't writable, we update it
    // in our app for live previews on the panel.
    hideSmileBranding: attr('boolean'),

    // Variables available to handlebars (templated) fields
    templateVariables: attr('object'),

    panelHeader: belongsTo('panel-header', { async: false, inverse: null }),

    wallpaperOptions: hasMany('wallpaper-option', {
      async: false,
      inverse: null,
    }),

    dummyDataForTemplateValidations:
      buildDummyDataForTemplateValidations('templateVariables').readOnly(),

    removeImage: memberAction({ path: 'remove_image', type: 'put' }),
  }
);
