import Service from '@ember/service';
import { bool } from '@ember/object/computed';
import { storageFor } from 'ember-local-storage';

export default class HeadDataService extends Service {
  @storageFor('sweet-tooth-session')
  localStorage;

  @bool('localStorage.isAdmin')
  isAdmin;
}
