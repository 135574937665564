import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { openExternalLink } from '@smile-io/ember-smile-core/utils/external-links';
import { Redirect } from '@shopify/app-bridge/actions';

export default class UtilsService extends Service {
  @service shopify;

  /**
   * Utility to open an external link.
   * Uses Shopify App Bridge utilities when embedded inside Shopify, falling back on
   * `openExternalLink` provided by `ember-smile-core` otherwise.
   *
   * @param {String} link                 The link to open.
   * @param {Object} options              Options for opening the link.
   * @property {Boolean} options.newTab   When true (default), opens the link in a new tab.
   * @property {Boolean} options.replace  When true (default), replaces the current URL (skipping history).
   *                                      Set to false, if you want to preserve history. Only applies
   *                                      when `newTab` is `false`
   */
  @action
  openExternalLink(link, { newTab = true } = {}) {
    // If it's Shopify embedded, not a new tab, use AppBridge
    // NOTE we could be more specific and use a redirect to a relative path in Shopify admin, if
    // it's the case, but using a generic absolute URL redirect instead because query params are not
    // supported which breaks things (ex: billing upgrade/downgrade approvals)
    if (this.shopify.isEmbedded && !newTab) {
      this.shopify.redirect.dispatch(Redirect.Action.REMOTE, link);
      return;
    }

    openExternalLink(...arguments);
  }
}
