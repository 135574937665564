/* import __COLOCATED_TEMPLATE__ from './rating-description.hbs'; */
import Component from '@glimmer/component';

export default class RatingDescription extends Component {
  get ratingDescriptions() {
    return [this.args.defaultDescription, ...this.args.ratingDescriptions];
  }

  get visibleRatingIndex() {
    return this.args.visibleRatingDescription === null
      ? 0
      : this.args.visibleRatingDescription + 1;
  }
}
