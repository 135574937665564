/* import __COLOCATED_TEMPLATE__ from './add-referrals-program-type.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { not } from '@ember/object/computed';
import { tagName } from '@ember-decorators/component';
import { storageFor } from 'ember-local-storage';
import { inject as service } from '@ember/service';

@tagName('')
export default class AddProgramTypeReferralsComponent extends Component {
  @service sesh;

  shouldRenderFeedbackForm = false;

  @storageFor('sweet-tooth-session')
  localStorage;

  @not('localStorage.hasDismissedAddProgramTypeReferralsCard')
  shouldRender;

  @action
  dismissCard() {
    let dismiss = this.onDismiss;

    if (dismiss) {
      dismiss('hasDismissedAddProgramTypeReferralsCard');
    }
  }
}
