import { isEmpty, isPresent } from '@ember/utils';

export default function getSmileEditorPathInfo(
  navigationConfig,
  displaySection
) {
  let hasMultipleFlows = navigationConfig.length > 1;
  let subPathChunks = isPresent(displaySection)
    ? displaySection.split('/')
    : [];

  let flow, tab, section;
  let selectedFlowConfig, selectedSectionConfig, selectedTabConfig;

  if (hasMultipleFlows) {
    selectedFlowConfig = navigationConfig.find(
      (config) => config.value === subPathChunks[0]
    );

    // Invalid path so serve the first flow
    if (isEmpty(selectedFlowConfig)) {
      selectedFlowConfig = navigationConfig[0];
    }

    flow = selectedFlowConfig.value;
    subPathChunks.shift();
  } else {
    selectedFlowConfig = navigationConfig[0];
  }

  // :tabName/:sectionName
  if (isPresent(selectedFlowConfig.tabs)) {
    selectedTabConfig = selectedFlowConfig.tabs.find(
      (tab) => tab.value === subPathChunks[0]
    );

    // Invalid path so serve the first tab
    if (isEmpty(selectedTabConfig)) {
      selectedTabConfig = selectedFlowConfig.tabs[0];
    }
    tab = selectedTabConfig.value;

    selectedSectionConfig = selectedTabConfig.sections.find(
      (section) => section.value === subPathChunks[1]
    );

    // Inside a section of a tab
    if (isPresent(selectedSectionConfig)) {
      section = selectedSectionConfig.value;
    }
  } else if (isPresent(selectedFlowConfig.sections)) {
    // :sectionName/:tabName

    selectedSectionConfig = selectedFlowConfig.sections.find(
      (section) => section.value === subPathChunks[0]
    );
    if (isPresent(selectedSectionConfig)) {
      section = selectedSectionConfig.value;

      if (isPresent(selectedSectionConfig.tabs)) {
        selectedTabConfig = selectedSectionConfig.tabs.find(
          (tab) => tab.value === subPathChunks[1]
        );

        // Invalid path so serve the first tab
        if (isEmpty(selectedTabConfig)) {
          selectedTabConfig = selectedSectionConfig.tabs[0];
        }

        tab = selectedTabConfig.value;
      }
    }
  }

  return {
    flow,
    tab,
    section,
    selectedFlowConfig,
    selectedSectionConfig,
    selectedTabConfig,
  };
}
