import { validateLength } from 'ember-changeset-validations/validators';

export const maxDescriptionLength = 250;

/**
 * Reusable validation for description fields for smile-ui customisations.
 * This currently just limits the maximum length to `maxDescriptionLength`.
 */
export default function validateSmileUiDescriptionField(options = {}) {
  return validateLength({
    allowBlank: true,
    max: maxDescriptionLength,
    description: 'Description',
    message: `{description} cannot be more than ${maxDescriptionLength} characters`,
    ...options,
  });
}
