import { default as OriginalGoogleTagManager } from 'ember-metrics/metrics-adapters/google-tag-manager';
import { compact } from 'ember-metrics/-private/utils/object-transforms';
import config from 'smile-admin/config/environment';

export default class GoogleTagManager extends OriginalGoogleTagManager {
  /**
   * Overwrites original method to remove the auto-prefixing of all data with `event`.
   */
  trackEvent(options = {}) {
    const compactedOptions = compact(options);

    window[this.dataLayer].push(compactedOptions);

    // In dev environment, log this to console
    if (config.smileEnv !== 'production') {
      console.log(`[${this.toString()}] trackEvent`, compactedOptions);
    }

    return compactedOptions;
  }

  trackPage(options = {}) {
    const pageEvent = super.trackPage(options);

    // In dev environment, log this to console
    if (config.smileEnv !== 'production') {
      console.log(`[${this.toString()}] trackPage`, pageEvent);
    }

    return pageEvent;
  }
}
