import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { empty, notEmpty } from '@ember/object/computed';

export default Model.extend({
  email: attr('string'),
  first_name: attr('string'),
  last_name: attr('string'),
  loginMethod: attr('string'),
  password: attr('string'),
  passwordConfirmation: attr('string'),
  temp_password: attr('boolean'),
  intercomHash: attr('string'),

  created_at: attr('date'),
  updated_at: attr('date'),

  account: belongsTo('account', { async: false, inverse: 'users' }),

  noEmail: empty('email').readOnly(),
  hasEmail: notEmpty('email').readOnly(),

  name: computed('first_name', 'last_name', function () {
    let firstName = this.get('first_name') ?? '';
    let lastName = this.get('last_name') ?? '';

    return `${firstName} ${lastName}`.trim();
  }).readOnly(),
});
