import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    condition_activity_attribute_definitions: {
      serialize: false,
      deserialize: 'records',
    },
    defaultRateLimitConditions: { serialize: false, deserialize: 'records' },
  },
});
