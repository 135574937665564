import { getOwner } from '@ember/application';
import { isEmpty } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';

export function validateEmailDisposability() {
  return (key, newValue, oldValue, changes, content) => {
    if (isEmpty(newValue)) {
      return true;
    }

    let owner = getOwner(content);
    let ajax = owner.lookup('service:ajax');

    return ajax
      .request('/v1/email_addresses/validate', { data: { email: newValue } })
      .then((response) => {
        if (response.is_valid) {
          return true;
        }

        return buildMessage(key, {
          type: 'emailDisposable',
          value: newValue,
        });
      });
  };
}
