import { sourceTypes as pointsTransactionSourceTypes } from 'smile-admin/models/points-transaction';

export const tabs = [
  {
    text: 'All',
    value: null,
    filterParams: null,
  },
  {
    text: 'Earned',
    value: 'earned',
    filterParams: {
      source_type: pointsTransactionSourceTypes.rewardFulfillment,
    },
  },
  {
    text: 'Redeemed',
    value: 'redeemed',
    filterParams: {
      source_type: pointsTransactionSourceTypes.pointsPurchase,
      is_canceling: false,
    },
  },
  {
    text: 'Refunded',
    value: 'refunded',
    filterParams: {
      source_type: pointsTransactionSourceTypes.pointsPurchase,
      is_canceling: true,
    },
  },
  {
    text: 'Expired',
    value: 'expired',
    filterParams: {
      source_type: pointsTransactionSourceTypes.expiry,
    },
  },
];

export function getFilterParamsForTab(tab) {
  let selectedTab = tabs.find((tabItem) => tabItem.value === tab) || tabs[0];

  return selectedTab.filterParams;
}
