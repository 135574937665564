import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    dataSet: {
      deserialize: 'records',
      serialize: false,
      key: 'account_data_set',
    },
    billingSubscriptions: { serialize: false, deserialize: 'records' },
    currency: { serialize: 'id', deserialize: 'records' },
    newIntegrations: { serialize: false, deserialize: 'records' },
    reward_programs: { serialize: false, deserialize: 'records' },
    onboardingQuizAnswerSet: { serialize: false, deserialize: 'records' },
    displaySetting: { serialize: false, deserialize: 'records' },
    usageCredits: { serialize: false, deserialize: 'records' },
    accountOrderLimit: { serialize: false, deserialize: 'records' },
    isInOrderLimitGracePeriodNew: { key: 'is_in_order_limit_grace_period' },
  },
});
