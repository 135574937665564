import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    activity_rule_definition: { serialize: 'id', deserialize: 'records' },
    reward_program: { serialize: 'id', deserialize: 'id' },
    reward: { serialize: 'records', deserialize: 'records' },
    customized_award_conditions: { serialize: false, deserialize: 'records' },
    customized_cancel_conditions: { serialize: false, deserialize: 'records' },
    activityRuleBonuses: { serialize: false, deserialize: 'records' },
  },
});
