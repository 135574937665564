import { underscore } from '@ember/string';
import { isNone, isPresent } from '@ember/utils';

/**
 * @param {Object} model
 * @returns an object with camel cased fields replaced with snake cased fields
 */
let transformFieldsToSnakeCase = (model) =>
  Object.keys(model).reduce(
    (transformedModel, key) => ({
      ...transformedModel,
      [underscore(key)]: model[key],
    }),
    {}
  );

/**
 *
 * This method transforms the model used by our app to match the data format expectations of SmileUI
 *
 * @export setPreviewAttributes
 * @param {Object} modelToTransform
 * @param {String} primaryColor
 * @param {String} secondaryColor
 * @returns a model that the smile-ui panel can use to preview the changes made in our app.
 */
export default function setPreviewAttributes(
  modelToTransform,
  primaryColor,
  secondaryColor
) {
  if (isNone(modelToTransform)) {
    return modelToTransform;
  }

  let model = transformFieldsToSnakeCase(modelToTransform);

  return Object.keys(model).reduce((newModel, key) => {
    // Skip property if it's already been set. This avoids issues with
    // e.g. `color` being set when we process `color_preset`, then
    // reset to an outdated value when we process `color` itself.
    if (newModel.hasOwnProperty(key)) {
      return newModel;
    }

    let result = { ...newModel };

    // Ignore custom fields for s3 keys since those fields
    // are temporary and not actually saved on the snakeCasedModel.
    if (key.indexOf('temp_s3_key') !== -1) {
      return result;
    }

    // Presets are named color combinations(strings). This transformation is required since
    // Smile UI doesn't understand presets & expects hex values instead
    if (
      key.endsWith('_preset') &&
      (isPresent(secondaryColor) || isPresent(primaryColor))
    ) {
      let actualKey = key.replace('_preset', '');
      result[actualKey] =
        model[key] === 'secondary' ? secondaryColor : primaryColor;
    }

    let actualKey = key.replace(/customizable_|customized_|default_/, '');
    let defaultKey = `default_${actualKey}`;

    // In case there are props that don't have a default/customized prefix like visitor_cards
    if (!model.hasOwnProperty(defaultKey)) {
      result[key] = model[key];
      return result;
    }

    // Compute default+customized/customizable fields to their derived field. i.e., do what the API
    // does on saving changes so that smileUI can refer to the same fields even in preview mode
    if (key.startsWith('default_')) {
      let customValue =
        model[`customizable_${actualKey}`] || model[`customized_${actualKey}`];
      let defaultValue = model[defaultKey];

      result[actualKey] = customValue || defaultValue;
    }

    return result;
  }, {});
}
