import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  transitionType: attr('string'),

  description: attr('string'),
  internalNote: attr('string'),

  manualPlacement: attr('boolean'),
  state: attr('string'),

  updatedAt: attr('utc'),
  currentVipTierExpiresAt: attr('utc'),
  createdAt: attr('utc'),

  account: belongsTo('account', { async: false, inverse: null }),
  customer: belongsTo('customer', { async: false, inverse: null }),
  currentVipTier: belongsTo('vip-tier', { async: false, inverse: null }),
});
