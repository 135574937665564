import Helper from '@ember/component/helper';
import { isNone } from '@ember/utils';
import { pointsLabel } from './points-label';
import numeral from 'numeral';
import { inject as service } from '@ember/service';

export default Helper.extend({
  sesh: service(),

  compute([pointsAmount], { format }) {
    return formatPoints(pointsAmount, this.get('sesh'), format);
  },
});

/**
 * Helper to format points with the account's points label and, optionally, a custom numeral.js format.
 *
 * @param  {Number} pointsAmount    Required. The amount of points.
 * @param  {Service} sesh           Required. This is our 'sesh' service, which needs to be passed in as we
 *                                  can't inject it into utility functions.
 * @param  {String} format          Optional. A valid numeral.js format option.
 *                                  Default: '0,0'.
 * @return {String}
 */
export function formatPoints(pointsAmount, sesh, format) {
  format = format || '0,0';
  if (isNone(pointsAmount)) {
    return;
  }

  let formattedPointsAmount = numeral(pointsAmount.toString()).format(format);

  return `${formattedPointsAmount} ${pointsLabel(pointsAmount, sesh)}`;
}
