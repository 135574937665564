/* import __COLOCATED_TEMPLATE__ from './iframe-view.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { loadScript } from 'smile-admin/utils/load-script';

export default class IframeViewComponent extends Component {
  @action
  handleIframeLoad(event) {
    let iframe = event.srcElement;
    this.args.onIframeLoaded?.(event, iframe);

    // This is for required for the iframe-resizer modifier to work correctly
    loadScript(
      '/iframe-resizer/js/iframeResizer.contentWindow.js',
      iframe.contentDocument
    );
  }

  resizeIframe(iframe) {
    iframe.iFrameResizer?.resize();
  }
}
