import Model, { belongsTo, attr } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export default class ProductExclusion extends Model {
  @attr('string') type;
  @attr('string') operator;
  @attr('string') externalValue;

  @belongsTo('external-product', {
    async: false,
    inverse: null,
  })
  externalProduct;

  createMany = collectionAction({
    path: 'batch',
    type: 'post',
    after: serializeAndPush,
  });

  deleteMany = collectionAction({
    path: 'batch',
    type: 'delete',
  });
}
