import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SelectionType } from './index-provider/types.ts';

function defaultResourceIDResolver(resource: { [key: string]: unknown }) {
  if ('id' in resource) {
    return resource.id as string;
  }

  throw new Error(
    'Your resource does not directly contain an `id`. Pass a `resourceIDResolver` to `useIndexResourceState`',
  );
}

type ResourceIDResolver<T extends { [key: string]: unknown }> = (
  resource: T,
) => string;

interface OptionsType<T extends { [key: string]: unknown }> {
  selectedResources?: string[];
  allResourcesSelected?: boolean;
  resourceIDResolver?: ResourceIDResolver<T>;
  resourceFilter?: (value: T, index: number) => boolean;
}

export function useIndexResourceState<T extends { [key: string]: unknown }>(
  resources: T[],
  options: OptionsType<T> = {},
) {
  return new IndexResourceStateService(resources, options);
}

class IndexResourceStateService<T extends { [key: string]: unknown }> {
  @tracked selectedResources: (string | Range)[] = [];
  @tracked allResourcesSelected = false;
  resources: T[] = [];
  resourceIDResolver: ResourceIDResolver<T> = defaultResourceIDResolver;
  resourceFilter?: (value: T, index: number) => boolean = undefined;

  constructor(resources: T[], options: OptionsType<T> = {}) {
    this.resources = resources;
    this.selectedResources = options.selectedResources || [];
    this.allResourcesSelected = options.allResourcesSelected || false;
    this.resourceIDResolver =
      options.resourceIDResolver || defaultResourceIDResolver;
    this.resourceFilter = options.resourceFilter;
  }

  @action
  handleSelectionChange(
    selectionType: SelectionType,
    isSelecting: boolean,
    selection: string | Range,
    _position: number, // eslint-disable-line @typescript-eslint/no-unused-vars
  ) {
    if (selectionType === SelectionType.All) {
      this.allResourcesSelected = isSelecting;
    } else if (this.allResourcesSelected) {
      this.allResourcesSelected = false;
    }

    const filteredResources = this.resourceFilter
      ? this.resources.filter(this.resourceFilter)
      : this.resources;

    switch (selectionType) {
      case SelectionType.Single:
        this.selectedResources = isSelecting
          ? [...this.selectedResources, selection]
          : this.selectedResources.filter((id) => id !== selection);
        break;
      case SelectionType.All:
      case SelectionType.Page:
        this.selectedResources =
          isSelecting &&
          this.selectedResources.length < filteredResources.length
            ? filteredResources.map(this.resourceIDResolver)
            : [];
        break;
      case SelectionType.Multi:
        if (!selection) break;
        this.selectedResources = this.handleMultiSelection(
          isSelecting,
          selection as unknown,
        );
        break;
      case SelectionType.Range:
        if (!selection) break;
        this.selectedResources = this.handleRangeSelection(
          isSelecting,
          selection,
        );
        break;
    }
  }

  handleMultiSelection(isSelecting: boolean, selection: unknown) {
    const ids: string[] = [];
    const filteredResources = this.resourceFilter
      ? this.resources.filter(this.resourceFilter)
      : this.resources;
    const index0 = (selection as number[])[0];
    const index1 = (selection as number[])[1];
    for (let i = index0!; i <= index1!; i++) {
      if (filteredResources.includes(this.resources[i]!)) {
        const id = this.resourceIDResolver(this.resources[i]!);
        if (
          (isSelecting && !this.selectedResources.includes(id)) ||
          (!isSelecting && this.selectedResources.includes(id))
        ) {
          ids.push(id);
        }
      }
    }

    return isSelecting
      ? [...this.selectedResources, ...ids]
      : this.selectedResources.filter((id) => !ids.includes(id as string));
  }

  handleRangeSelection(isSelecting: boolean, selection: unknown) {
    const filteredResources = this.resourceFilter
      ? this.resources.filter(this.resourceFilter)
      : this.resources;

    const resourceIds = filteredResources.map(this.resourceIDResolver);

    const selectedIds = resourceIds.slice(
      Number((selection as number[])[0]),
      Number((selection as number[])[1]) + 1,
    );

    const isIndeterminate = selectedIds.some((id) => {
      return this.selectedResources.includes(id);
    });

    const isChecked = selectedIds.every((id) => {
      return this.selectedResources.includes(id);
    });

    const isSelectingAllInRange =
      !isChecked && (isSelecting || isIndeterminate);

    return isSelectingAllInRange
      ? [...new Set([...this.selectedResources, ...selectedIds]).values()]
      : this.selectedResources.filter(
          (id) => !selectedIds.includes(id as string),
        );
  }

  @action
  clearSelection() {
    this.selectedResources = [];
    this.allResourcesSelected = false;
  }

  @action
  removeSelectedResources(removeResources: string[]) {
    const newSelectedResources = this.selectedResources.filter(
      (resource) => !removeResources.includes(resource as string),
    );

    this.selectedResources = newSelectedResources;

    if (newSelectedResources.length === 0) {
      this.allResourcesSelected = false;
    }
  }
}

export default useIndexResourceState;
