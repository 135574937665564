/* import __COLOCATED_TEMPLATE__ from './start-guide.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ensureSafeComponent } from '@embroider/util';
import { restartableTask } from 'ember-concurrency';
import WelcomeStep from './start-guide/welcome';
import ProgramTypesStep from './start-guide/welcome';
import WaysToEarnStep from './start-guide/ways-to-earn';
import WaysToRedeemStep from './start-guide/ways-to-redeem';
import CustomerJourneyStep from './start-guide/customer-journey';
import ProgramHealthStep from './start-guide/program-health';

export default class StartGuide extends Component {
  @service store;

  @tracked currentStep;
  @tracked furthestSeenStep;

  constructor() {
    super(...arguments);

    this.fetchData();

    this.currentStep = this.args.account?.startGuideStep || this.stepKeys[0];
    this.furthestSeenStep = this.currentStep;
  }

  get stepIndex() {
    return Math.max(this.stepKeys.indexOf(this.currentStep), 0);
  }

  get stepKeys() {
    return this.steps.map((step) => step.key);
  }

  get steps() {
    return [
      {
        key: 'welcome',
        component: ensureSafeComponent(WelcomeStep, this),
        title: 'Start guide',
      },
      {
        key: 'program_types',
        component: ensureSafeComponent(ProgramTypesStep, this),
        title: 'Step 1: Launch your program',
      },
      {
        key: 'ways_to_earn',
        component: ensureSafeComponent(WaysToEarnStep, this),
        title: 'Step 2: Add at least 3 ways to earn points',
      },
      {
        key: 'ways_to_redeem',
        component: ensureSafeComponent(WaysToRedeemStep, this),
        title: 'Step 3: Add at least 3 ways to redeem points',
      },
      {
        key: 'customer_journey',
        component: ensureSafeComponent(CustomerJourneyStep, this),
        title: 'Step 4: The customer loyalty cycle',
      },
      {
        key: 'program_health',
        component: ensureSafeComponent(ProgramHealthStep, this),
        title: 'Program health',
      },
    ];
  }

  get remainingDaysCount() {
    return 28 - this.args.account?.daysSinceSignup;
  }

  @restartableTask
  *fetchEnabledActivityRules() {
    return yield this.store.query('activity-rule', {
      account_id: this.args.account.id,
      is_enabled: true,
    });
  }

  @restartableTask
  *fetchEnabledPointsProducts() {
    return yield this.store.query('points-product', {
      account_id: this.args.account.id,
      is_enabled: true,
    });
  }

  @restartableTask
  *updateProgress() {
    // Update the account's start guide step if they've moved to a step they haven't seen before.
    const { stepKeys, currentStep, furthestSeenStep } = this;
    if (stepKeys.indexOf(currentStep) <= stepKeys.indexOf(furthestSeenStep)) {
      return;
    }

    this.furthestSeenStep = currentStep;

    const { account } = this.args;
    account.startGuideStep = currentStep;
    yield account.save();
  }

  fetchData() {
    this.fetchEnabledActivityRules.perform();
    this.fetchEnabledPointsProducts.perform();
  }

  @action
  moveStep(count) {
    const { stepKeys, stepIndex } = this;
    const nextStep = stepKeys[stepIndex + count];
    this.currentStep = nextStep;
  }
}
