import Devise from 'ember-simple-auth/authenticators/devise';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { run } from '@ember/runloop';

const JSON_CONTENT_TYPE = 'application/json';

/**
 * Here we extend the ember-simple-auth devise authenticator to
 * add token login functionality
 *
 * Original file:
 * https://github.com/simplabs/ember-simple-auth/blob/master/addon/authenticators/devise.js
 */
export default Devise.extend({
  config: service(),

  /**
   * The identification attribute name. This will be used in the request and
   * also be expected in the server's response.
   * NOTE: not using `email` here, because we don't have emails for every user.
   *
   * @property identificationAttributeName
   * @type {String}
   */
  identificationAttributeName: 'id',

  /**
   * The token attribute name. This will be used in the request and also be
   * expected in the server's response.
   *
   * @property tokenAttributeName
   * @type {String}
   */
  tokenAttributeName: 'authentication_token',

  /**
   * The devise resource name. This will be used in the request and also be
   * expected in the server's response.
   *
   * @property resourceName
   * @type {String}
   */

  resourceName: 'user',

  serverTokenEndpoint: computed('config.apiHost', {
    get() {
      return `${this.get('config.apiHost')}/${this.get(
        'config.apiNamespace'
      )}/users/sign_in`;
    },
  }),

  authenticate(identification, password) {
    return new Promise((resolve, reject) => {
      const { resourceName, identificationAttributeName, tokenAttributeName } =
        this.getProperties(
          'resourceName',
          'identificationAttributeName',
          'tokenAttributeName'
        );
      const data = {};

      // Allow user to login with either email/password or authentication_token
      if (identification === null) {
        data[resourceName] = { authentication_token: password };
      } else {
        data[resourceName] = { password };
        data[resourceName]['email'] = identification;
      }

      // Add our custom header to denote it's coming from our apps
      let options = {
        headers: {
          'Smile-Client': this.config.get('modulePrefix'),
          // We also include what parent does, because it's not doing a deep merge :(
          accept: JSON_CONTENT_TYPE,
          'content-type': JSON_CONTENT_TYPE,
        },
      };

      this.makeRequest(data, options)
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              if (this._validate(json)) {
                const resourceName = this.get('resourceName');
                const _json = json[resourceName] ? json[resourceName] : json;
                run(null, resolve, _json);
              } else {
                run(
                  null,
                  reject,
                  `Check that server response includes ${tokenAttributeName} and ${identificationAttributeName}`
                );
              }
            });
          } else {
            run(null, reject, response);
          }
        })
        .catch((error) => run(null, reject, error));
    });
  },
});
