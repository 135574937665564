import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const conversionTypesForDeliveryType = {
  increase_reward_usage: 'reward used',
  increase_points_spending: 'reward redeemed',
  increase_account_creation: 'member',
  increase_referral_url_sharing: 'share',
  increase_guest_referral_url_sharing: 'share',
};

export default Model.extend({
  name: attr('string'),
  description: attr('string'),
  imageUrl: attr('string'),
  imageSvg: attr('string'),
  deliveryType: attr('string'),
  defaultIconStyles: attr(),
  defaultTitle: attr('string'),
  defaultSubtitle: attr('string'),
  defaultCta: attr('string'),

  conversionType: computed('deliveryType', function () {
    let { deliveryType } = this;
    return conversionTypesForDeliveryType[deliveryType];
  }).readOnly(),

  install: memberAction({
    path: 'install',
    type: 'put',
    after: serializeAndPush,
  }),
});
