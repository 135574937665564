/* import __COLOCATED_TEMPLATE__ from './upgrade-content.hbs'; */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class BillingSubscriptionTemplateUpgradeContentComponent extends Component {
  @service sesh;

  @computed('chargeableSubscription', 'showYearlyPricing')
  get isCurrentBillingProduct() {
    let subscriptionTemplate = this.showYearlyPricing
      ? this.yearlySubscriptionTemplate
      : this.subscriptionTemplate;

    return (
      this.chargeableSubscription?.basePlan?.billingProduct ===
      subscriptionTemplate.basePlan.billingProduct
    );
  }

  @action
  yearlyPlanDiscountAmountPerMonth(
    monthlySubscriptionPrice,
    yearlySubscriptionPricePerMonth
  ) {
    return monthlySubscriptionPrice - yearlySubscriptionPricePerMonth;
  }
}
