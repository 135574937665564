import Transform from '@ember-data/serializer/transform';
/**
 * Transforms between a value stored API-side as
 * a comma-separated string and a UI-side array.
 */
export default Transform.extend({
  deserialize(serialized = '', { separator = ',' }) {
    return serialized.split(separator);
  },

  serialize(deserialized = [], { separator = ',' }) {
    return deserialized.join(separator);
  },
});
