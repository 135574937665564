import Component from '@glimmer/component';
import lifecycle from 'page-lifecycle/dist/lifecycle.es5.js';
import { camelize } from '@ember/string';

const EVENT_NAME = 'statechange';

/**
 * Component wrapper for the page-lifecycle library
 * (https://developers.google.com/web/updates/2018/07/page-lifecycle-api)
 *
 * Possible args:
 *    - onActive
 *    - onPassive
 *    - onHidden
 *    - onFrozen
 *    - onTerminated
 *    - onDiscarded
 */
export default class PageLifecycleComponent extends Component {
  constructor(owner, args) {
    super(owner, args);

    lifecycle.addEventListener(
      EVENT_NAME,
      this.pageLifecycleHandler.bind(this)
    );
  }

  pageLifecycleHandler({ newState }) {
    const targetEventHandler = this.args[camelize(`on_${newState}`)];

    if (typeof targetEventHandler === 'function') {
      targetEventHandler();
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    lifecycle.removeEventListener(EVENT_NAME, this.pageVisibilityHandler);
  }
}
