import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  /**
   * Individual account app listing records are found by slug,
   * not by ID. Example:
   *
   * store.queryRecord('account-app-listing', { slug: 'app-slug-here'})
   *
   */
  urlForQueryRecord({ slug } /*, modelName*/) {
    let baseUrl = this.buildURL();
    return `${baseUrl}/account_app_listings/${encodeURIComponent(slug)}`;
  },

  /**
   * A 'slug' param indicates that we're finding the record by slug using
   * queryRecord('account-app-listing', { slug: 'app-slug-here'}), and therefore
   * do not also need to include the slug as an actual query param.
   *
   * Otherwise you will get URLs that look like:
   * /v1/account_app_listings/app-slug-here?slug=app-slug-here
   *
   */
  sortQueryParams(params) {
    let keys = Object.keys(params);

    if (keys.indexOf('slug') > -1) {
      delete params.slug;
    }

    return params;
  },
});
