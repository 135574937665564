import { validatePresence } from 'ember-changeset-validations/validators';

export default {
  name: validatePresence({
    presence: true,
    description: 'Name before completed',
  }),
  namePastTense: validatePresence({
    presence: true,
    description: 'Name after completed',
  }),
};
