import Model, { belongsTo, attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';

export default Model.extend({
  createdAt: attr('date'),
  updatedAt: attr('date'),

  newBillingPlan: belongsTo('new-billing-plan', {
    async: false,
    inverse: null,
  }),

  isBaseDefault: readOnly('newBillingPlan.billingProduct.isPlanType'),
  isAppsAddOnDefault: readOnly('newBillingPlan.billingProduct.isAppsAddOnType'),
  isMembersAddOnDefault: readOnly(
    'newBillingPlan.billingProduct.isMembersAddOnType'
  ),
  isOrdersAddOnDefault: readOnly(
    'newBillingPlan.billingProduct.isOrdersAddOnType'
  ),
});
