import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    referral_sharing_option_definition: {
      serialize: false,
      deserialize: 'records',
    },
  },
});
