import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { hash, array } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import Bleed from '@smile-io/ember-shopify-polaris/components/bleed';
import BlockStack from '@smile-io/ember-shopify-polaris/components/block-stack';
import Box from '@smile-io/ember-shopify-polaris/components/box';
import Button from '@smile-io/ember-shopify-polaris/components/button';
import Card from '@smile-io/ember-shopify-polaris/components/card';
import Collapsible from '@smile-io/ember-shopify-polaris/components/collapsible';
import EmptyState from '@smile-io/ember-shopify-polaris/components/empty-state';
import FooterHelp from '@smile-io/ember-shopify-polaris/components/footer-help';
import Icon from '@smile-io/ember-shopify-polaris/components/icon';
import InlineGrid from '@smile-io/ember-shopify-polaris/components/inline-grid';
import InlineStack from '@smile-io/ember-shopify-polaris/components/inline-stack';
import Layout from '@smile-io/ember-shopify-polaris/components/layout';
import List from '@smile-io/ember-shopify-polaris/components/list';
import Modal from '@smile-io/ember-shopify-polaris/components/modal';
import SkeletonBodyText from '@smile-io/ember-shopify-polaris/components/skeleton-body-text';
import SkeletonDisplayText from '@smile-io/ember-shopify-polaris/components/skeleton-display-text';
import SkeletonThumbnail from '@smile-io/ember-shopify-polaris/components/skeleton-thumbnail';
import Text from '@smile-io/ember-shopify-polaris/components/text';
import Tooltip from '@smile-io/ember-shopify-polaris/components/tooltip';
import { BulletIcon } from '@smile-io/polaris-icons/components/bullet-icon';
import { ChevronDownIcon } from '@smile-io/polaris-icons/components/chevron-down-icon';
import { ConfettiIcon } from '@smile-io/polaris-icons/components/confetti-icon';
import { InfoIcon } from '@smile-io/polaris-icons/components/info-icon';
import { PersonIcon } from '@smile-io/polaris-icons/components/person-icon';
import { openHelpWidget } from 'smile-admin/helpers/open-help-widget';
import { formatNumber } from 'smile-admin/helpers/format-number';
export default class NewRestrictedAccessOverLimit extends Component {
    @tracked
    showTurnOffProgramModal = false;
    @tracked
    showProgramPerformance = false;
    @service
    alert;
    @service
    router;
    @service
    sesh;
    get isLoading() {
        return !this.sesh.account;
    }
    get accountDataSet() {
        return this.sesh.account.dataSet;
    }
    @action
    navigateToPricingPlans() {
        this.router.transitionTo('settings.billing.pricing-plans');
    }
    @action
    openTurnOffProgramModal() {
        this.showTurnOffProgramModal = true;
    }
    @action
    closeTurnOffProgramModal() {
        this.showTurnOffProgramModal = false;
    }
    @action
    handleToogle() {
        this.showProgramPerformance = !this.showProgramPerformance;
    }
    disableRewardPrograms = dropTask(async ()=>{
        this.args.disableRewardPrograms();
        this.closeTurnOffProgramModal();
        this.alert.info('Program turned off');
    });
    static{
        template(`
    <Layout class="restricted-access-over-limit-layout" as |layout|>
      <layout.Section @variant="oneHalf">
        <Card>
          {{#if this.isLoading}}
            <BlockStack @gap="500" @inlineAlign="center">
              <Box @background="bg-surface" @padding="400" />

              <SkeletonThumbnail @size="large" />

              <Box @width="60%">
                <BlockStack @gap="200">
                  <BlockStack @inlineAlign="center">
                    <Box @width="20%">
                      <SkeletonBodyText @lines={{1}} />
                    </Box>
                  </BlockStack>

                  <BlockStack @gap="400">
                    <SkeletonBodyText @lines={{1}} />
                    <SkeletonBodyText @lines={{1}} />
                  </BlockStack>
                </BlockStack>
              </Box>

              <Box @width="30%">
                <BlockStack @gap="500">
                  <InlineGrid
                    @columns={{hash xs="0.25fr 2fr"}}
                    @alignItems="center"
                    @gap="300"
                  >
                    <SkeletonDisplayText @size="small" @maxWidth="100%" />

                    <SkeletonBodyText @lines={{1}} />
                  </InlineGrid>

                  <InlineGrid @columns={{hash xs="1fr 1fr"}} @gap="200">
                    <SkeletonDisplayText @size="small" @maxWidth="100%" />

                    <SkeletonDisplayText @size="small" @maxWidth="100%" />
                  </InlineGrid>
                </BlockStack>
              </Box>

              <Box @width="30%" @padding="400">
                <BlockStack />
              </Box>
            </BlockStack>
          {{else}}
            <EmptyState
              @heading="You’ve outgrown the {{@basePlanName}} plan"
              @image="/assets/illustrations/chat.svg"
              @action={{hash
                content="Upgrade"
                onAction=this.navigateToPricingPlans
              }}
              @secondaryAction={{if
                @isAnyRewardingProgramEnabled
                (hash
                  content="Turn off program"
                  onAction=this.openTurnOffProgramModal
                )
              }}
              class="empty-state"
            >
              <BlockStack @gap="400" @inlineAlign="center">
                <Text>
                  Your store has exceeded the
                  {{@monthlyOrdersTotal}}
                  monthly order limit. To continue managing your loyalty program
                  and access new features, please choose a paid plan.
                </Text>

                <InlineStack @gap="100" @blockAlign="center">
                  <Icon
                    class="icon-size"
                    @source={{BulletIcon}}
                    @tone={{if
                      @isAnyRewardingProgramEnabled
                      "success"
                      "warning"
                    }}
                  />

                  <Text
                    @as="span"
                    @tone="subdued"
                    @text={{if
                      @isAnyRewardingProgramEnabled
                      "Your program is still running"
                      "Your program is turned off"
                    }}
                  />

                  <Tooltip
                    @preferredPosition="mostSpace"
                    @width="wide"
                    @content={{if
                      @isAnyRewardingProgramEnabled
                      "Your customers are still able to earn and redeem points, but you must subscribe to manage your program."
                      "Your loyalty program is turned off, which means your customers are not able to earn or redeem points."
                    }}
                  >
                    <Icon @source={{InfoIcon}} />
                  </Tooltip>
                </InlineStack>
              </BlockStack>
            </EmptyState>
          {{/if}}

          {{#if @isAnyRewardingProgramEnabled}}
            <Bleed @marginInline="400" @marginBlock="400">
              <Box @background="bg-surface-secondary" @padding="400">
                <BlockStack
                  class="collapsible-container"
                  @inlineAlign="center"
                  @gap="400"
                >
                  <Button @variant="tertiary" @onClick={{this.handleToogle}}>
                    <InlineStack @blockAlign="center">
                      <Text @variant="bodySm" @fontWeight="bold">
                        View program performance
                      </Text>
                      <Icon @source={{ChevronDownIcon}} />
                    </InlineStack>
                  </Button>

                  <Collapsible @open={{this.showProgramPerformance}}>
                    <InlineGrid @gap="200" @columns={{2}}>
                      <Card>
                        <BlockStack @inlineAlign="center" @gap="200">
                          <Box
                            @background="bg-fill-info-secondary"
                            @borderRadius="100"
                          >
                            <Icon @source={{PersonIcon}} @tone="info" />
                          </Box>

                          <BlockStack @inlineAlign="center" @gap="100">
                            <Text
                              @variant="headingSm"
                              @text={{formatNumber
                                this.accountDataSet.totalCustomersWithPointsBalance
                              }}
                            />

                            <Text
                              @variant="bodySm"
                              @text="Total customers with points"
                            />
                          </BlockStack>
                        </BlockStack>
                      </Card>

                      <Card>
                        <BlockStack @inlineAlign="center" @gap="200">
                          <Box
                            @background="bg-fill-success-secondary"
                            @borderRadius="100"
                          >
                            <Icon @source={{ConfettiIcon}} @tone="success" />
                          </Box>

                          <BlockStack @inlineAlign="center" @gap="100">
                            <Text
                              @variant="headingSm"
                              @text={{formatNumber
                                this.accountDataSet.totalEarners28d
                              }}
                            />

                            <Text
                              @variant="bodySm"
                              @text="Earners in the last month"
                            />
                          </BlockStack>
                        </BlockStack>
                      </Card>
                    </InlineGrid>
                  </Collapsible>
                </BlockStack>
              </Box>
            </Bleed>
          {{/if}}
        </Card>
      </layout.Section>

      <layout.Section>
        <FooterHelp>
          Have questions?
          <Button
            @text="Contact support"
            @variant="plain"
            @onClick={{openHelpWidget}}
          />
        </FooterHelp>
      </layout.Section>
    </Layout>

    <Modal
      @open={{this.showTurnOffProgramModal}}
      @onClose={{this.closeTurnOffProgramModal}}
      @title="Turn your program off"
      @primaryAction={{hash
        content="Turn off my program"
        destructive=true
        loading=@isDisablingRewardPrograms
        onAction=@disableRewardPrograms
      }}
      @secondaryActions={{array
        (hash content="Cancel" onAction=this.closeTurnOffProgramModal)
      }}
    >
      <:content as |modal|>
        <modal.Section>
          <BlockStack @gap="200">
            <Text @as="span">
              Turning off your program means:
            </Text>

            <List @type="bullet" as |list|>
              <list.Item>
                <Text @as="span" @fontWeight="bold">
                  {{formatNumber
                    this.accountDataSet.totalCustomersWithPointsBalance
                  }}
                </Text>
                of your customers will lose access to their points
              </list.Item>

              <list.Item>Your customers will not be able to earn or redeem
                points moving forward</list.Item>
            </List>

            <Text @as="span" @fontWeight="bold">
              You will not be able to turn your program back on until you choose
              a paid plan.
            </Text>
          </BlockStack>
        </modal.Section>
      </:content>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
