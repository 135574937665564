import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  interval: attr('string'),
  interval_count: attr('number'),
  is_enabled: attr('boolean'),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  account: belongsTo('account', { async: false, inverse: null }),
  reward_program: belongsTo('reward-program', {
    async: false,
    inverse: 'points_expiry_reminders',
  }),
  customer_notification: belongsTo('customer-notification', {
    async: false,
    inverse: 'pointsExpiryReminder',
  }),
});
