import Model, { attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export default Model.extend({
  title: attr('string'),
  text: attr('string'),
  primaryCtaText: attr('string'),
  primaryCtaUrl: attr('string'),
  secondaryCtaText: attr('string'),
  secondaryCtaUrl: attr('string'),
  imageUrl: attr('string'),

  dismiss: memberAction({
    path: 'dismiss',
    type: 'put',
    after: serializeAndPush,
  }),
});
