import { attr } from '@ember-data/model';
import AbstractAttributeDefinition from './abstract-attribute-definition';

export const attributeNames = {
  vipTier: 'VIP Tier',

  // Shopify-specific.
  customerTags: 'Customer tags',

  // BigCommerce-specific.
  customerGroup: 'Customer group',
};

export default AbstractAttributeDefinition.extend({
  isVisibleForVipRange: attr('boolean'),
  isVisibleForActivityRuleConditions: attr('boolean'),
});
