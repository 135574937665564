import { isPresent, isEmpty } from '@ember/utils';
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import Handlebars from 'handlebars';
import { helpers } from 'smile-admin/instance-initializers/handlebars';

const defaultHandlebarsOptions = {
  strict: true,
  knownHelpers: Object.keys(helpers).reduce(
    (knownHelpers, helper) => ({
      ...knownHelpers,
      [helper]: true,
    }),
    {}
  ),
};

/**
 * Changeset validator that validates a property is a valid handlebars template.
 * @param  {Object} options         Optional. Options for validations.
 * @param[templateDataKey] {String} Optional key set to the property key on the validated
 *                                  object that has data for the template.If this is
 *                                  missing, it will just validate that the template has
 *                                  valid Handlebars syntax.
 * @param[...]                      Options supported by Handlebars
 *                                  (https://handlebarsjs.com/reference.html)
 */
export default function validateHandlebars(options = {}) {
  let { templateDataKey, allowBlank } = options;
  delete options['templateDataKey'];
  delete options['allowBlank'];

  let handlebarsOptions = Object.assign({}, defaultHandlebarsOptions, options);

  return (key, newValue, oldValue, changes, content) => {
    if (allowBlank && isEmpty(newValue)) {
      return true;
    }

    // Return `true` if valid || error message string if invalid
    try {
      // If we have a reference to the preview data for the template, use them
      // to validate that unsupported variables are not used
      let data = isPresent(templateDataKey) ? content.get(templateDataKey) : {};
      let template = Handlebars.compile(newValue, handlebarsOptions);

      template(data);
    } catch (err) {
      let message;

      // Add a friendlier message in case a variable that is not supported is used
      if (err.message.indexOf('not defined in') !== -1) {
        let unsupportedVariable = err.message.match(/"(\w+)"/)[1];
        unsupportedVariable = unsupportedVariable.replace('"', '');
        message = `unsupported variable ${unsupportedVariable}`;
      } else if (
        err.message.indexOf('is not a function') !== -1 ||
        err.message.indexOf("Cannot use 'in' operator to search for") !== -1
      ) {
        // We'll show this as {description} is invalid, we can't provide a better message
      } else if (err.message.indexOf('Parse error') !== -1) {
        // We'll show this as {description} is invalid, we can't provide a better message
      } else if (
        err.message.indexOf("Cannot read property 'call' of undefined") !==
          -1 ||
        err.message.indexOf('call is not a function') !== -1 ||
        err.message.indexOf("evaluating 'helpers.") !== -1 ||
        err.message.match(/^helpers\..*is undefined$/g)
      ) {
        // Considering this true...notification HTML template throws this, not really sure why yet.
        // FF & Safari throw differently worded `helpers not defined` errors but the helpers still work correctly.
        return true;
      }
      let validationType = isPresent(message)
        ? 'handlebarsInvalidFor'
        : 'handlebarsInvalid';
      if (isPresent(message)) {
        options.errorMessage = message;
      }

      return buildMessage(key, {
        type: validationType,
        value: newValue,
        context: options,
      });
    }

    return true;
  };
}
