export default class AdditionalOrdersCalculator {
  constructor({
    monthOrders,
    planOrderLimit,
    monthlyOrdersCredits,
    monthlyOrdersAllowance,
    ordersAllowancePrice,
    monthOrdersExemptedByPricingProtection,
  }) {
    this.monthOrders = monthOrders || 0;
    this.planOrderLimit = planOrderLimit || 0;
    this.monthlyOrdersCredits = monthlyOrdersCredits || 0;
    this.monthlyOrdersAllowance = monthlyOrdersAllowance || 0;
    this.ordersAllowancePrice = ordersAllowancePrice || 0;
    this.monthOrdersExemptedByPricingProtection =
      monthOrdersExemptedByPricingProtection || 0;
  }

  get hasAdditionalOrders() {
    return this.additionalOrders > 0;
  }

  get additionalOrders() {
    return (
      this.monthOrders +
      (this.monthOrdersExemptedByPricingProtection || 0) -
      this.planOrderLimit
    );
  }

  get totalPrice() {
    return (
      this.additionalOrdersPrice -
      this.creditsSavings -
      this.pricingProtectionSavings
    );
  }

  get additionalOrdersPrice() {
    return this.itemQuantity * this.ordersAllowancePrice;
  }

  get itemQuantity() {
    return Math.floor(this.additionalOrders / this.monthlyOrdersAllowance);
  }

  get creditsSavingsItemQuantity() {
    let itemQuantity = Math.floor(
      this.monthlyOrdersCredits / this.monthlyOrdersAllowance
    );

    if (itemQuantity > this.itemQuantity) {
      itemQuantity = this.itemQuantity;
    }

    return itemQuantity;
  }

  get creditsSavings() {
    let creditsSavings =
      this.creditsSavingsItemQuantity * this.ordersAllowancePrice;
    if (creditsSavings > this.additionalOrdersPrice) {
      creditsSavings = this.additionalOrdersPrice;
    }

    return creditsSavings;
  }

  get pricingProtectionSavings() {
    const maxSavingsValue = this.additionalOrdersPrice - this.creditsSavings;

    let itemQuantity = Math.floor(
      (this.monthOrdersExemptedByPricingProtection || 0) /
        this.monthlyOrdersAllowance
    );

    let savings = itemQuantity * this.ordersAllowancePrice;

    if (savings > maxSavingsValue) {
      savings = maxSavingsValue;
    }

    return savings;
  }
}
