import { computed } from '@ember/object';
import { assert } from '@ember/debug';
import HandlebarsCompiler from 'smile-admin/objects/handlebars-compiler';

/**
 * Creates a computed property which takes an hbs template path, variables list path,
 * and data object path, and returns a compiled hbs value as a string.
 *
 * Example:
 *
 * templatePath: 'notification.hbsTemplate',
 * templateVariables: ['reward_program'],
 * content: {
 *   reward_program: {
       points_label: "FunBucks"
 *   }
 * }
 *
 * In the above example, if the template path returns `You have earned 100 {{reward_program.points_label}}!`
 * this computed property would return "You have earned 100 FunBucks!".
 *
 * @param  {String} templatePath           Required. The path to the handlebars template.
 * @param  {Array} templateVariablesPath   Required. The path to a list of available variables.
 * @param  {EmberObject} contentPath       Required. The path to an Ember Object containing key/value pairs for the available variables.
 *
 * @return {Ember.ComputedProperty}
 */
function compileHbsToString(template, templateVariables, content, sesh) {
  return HandlebarsCompiler.create({
    template,
    templateVariables,
    content,
    sesh,
  }).get('compiledTemplate');
}

export default function compiledHandlebarsValue(
  templatePath,
  templateVariablesPath,
  contentPath,
  sesh
) {
  assert('Missing required `templatePath` param', templatePath);
  assert('Missing required `templateVariables` param', templateVariablesPath);
  assert('Missing required `content` param', contentPath);

  return computed(
    templatePath,
    templateVariablesPath,
    contentPath,
    function () {
      let template = this.get(templatePath);
      let templateVariables = this.get(templateVariablesPath);
      let content = this.get(contentPath);

      if (!template || !templateVariables || !content) {
        return null;
      }

      return compileHbsToString(template, templateVariables, content, sesh);
    }
  ).readOnly();
}
