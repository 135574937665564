import Model, { attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const states = {
  syncing: 'syncing',
  synced: 'synced',
  paused: 'paused',
  off: 'off',
};

export default class ShopifyVipMetafieldSync extends Model {
  @attr('string') state;

  get isSyncing() {
    return this.state === states.syncing;
  }

  get isSynced() {
    return this.state === states.synced;
  }

  get isPaused() {
    return this.state === states.paused;
  }

  get isOff() {
    return this.state === states.off;
  }

  turnOn = memberAction({
    path: 'turn_on',
    after: serializeAndPush,
  });

  turnOff = memberAction({
    path: 'turn_off',
    after: serializeAndPush,
  });
}
