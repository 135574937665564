import config from 'smile-admin/config/environment';

export default {
  initialize(/* application */) {
    // Can't set this in the config/environments.js file since we don't have access
    // to the window object there.
    // We use this for setting callback urls when we authenticate with shopify
    // TODO is this still needed?
    config.BASE_URL = window.location.protocol + '//' + window.location.host;
  },
};
