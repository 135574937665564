import Model, { belongsTo, attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const exportType = {
  all: 'all',
  page: 'page',
  searchResults: 'searchResults',
};

export const stateTypes = {
  disabled: 'disabled',
  candidate: 'candidate',
};

export const friendlyExportType = {
  all: 'All customers',
  page: 'Current page',
  searchResults: 'Customers matching search',
};

export default Model.extend({
  first_name: attr('string'),
  last_name: attr('string'),
  email: attr('string'),
  points_balance: attr('number'),
  authentication_token: attr('string'),
  referral_url: attr('string'),
  external_url: attr('string'),
  referral_purchases_count: attr('number'),
  date_of_birth: attr('utc'),
  date_of_birth_updated_at: attr('utc'),
  registered_at: attr('utc'),
  stateChangedAt: attr('utc'),
  state: attr('string'),
  disabledByUser: attr('boolean'),
  isExcluded: attr('boolean'),

  currentVipPeriodEnd: attr('utc'),
  currentVipPeriodEndFormatted: attr('string'),
  deltaToNextVipTier: attr('number'),
  deltaToNextVipTierFormatted: attr('string'),
  deltaToRetainVipTier: attr('number'),
  deltaToRetainVipTierFormatted: attr('string'),
  nextVipPeriodEnd: attr('utc'),
  nextVipPeriodEndFormatted: attr('string'),
  nextVipTierName: attr('string'),
  vipMetric: attr('number'),
  vipMetricFormatted: attr('string'),
  vipTierAfterExpiryName: attr('string'),
  vipTierExpiresAt: attr('utc'),
  vipTierExpiresAtFormatted: attr('string'),
  vipMilestoneDescriptionTemplateField: attr('string'),

  account: belongsTo('account', { async: false, inverse: null }),
  vip_tier: belongsTo('vip-tier', { async: false, inverse: null }),
  nextVipTier: belongsTo('vip-tier', { async: false, inverse: null }),

  isDisabled: equal('state', 'disabled').readOnly(),
  isCandidate: equal('state', 'candidate').readOnly(),
  isMember: equal('state', 'member').readOnly(),

  fullName: computed('first_name', 'last_name', function () {
    return `${this.get('first_name') || ''} ${
      this.get('last_name') || ''
    }`.trim();
  }),

  name: computed('fullName', 'email', function () {
    let name = this.get('fullName');
    if (isPresent(name)) {
      return name;
    }

    let email = this.get('email');
    if (isPresent(email)) {
      return email;
    }

    return '(unknown)';
  }),

  /**
   * Version of the referral_url with a query params used to bust social
   * preview cache
   */
  referral_share_url: computed('referral_url', function () {
    let currentTime = new Date().getTime();
    let queryString = `${
      this.get('referral_url').indexOf('?') > -1 ? '&' : '?'
    }u=${currentTime}`;
    return `${this.get('referral_url')}${queryString}`;
  }),

  disable: memberAction({
    path: 'disable',
    type: 'post',
    after: serializeAndPush,
  }),
  undisable: memberAction({
    path: 'undisable',
    type: 'post',
    after: serializeAndPush,
  }),
});
