import Model, { hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { categoryNames } from 'smile-admin/utils/activity-rule';

export default Model.extend({
  name: attr('string'),
  description: attr('string'),
  image_url: attr('string'),
  imageSvg: attr('string'),
  category: attr('string'),
  source: attr('string'),
  activity_rule_type: attr('string'),
  requiredIntegrationType: attr('string'),
  supports_rate_limit_conditions: attr('boolean'),
  supports_variable_values: attr('boolean'),
  no_rate_limit_conditions_reason: attr('string'),
  template_variables: attr('object'),
  activity_template_variables: attr('object'),
  activity_rule_value_description_template_variables: attr('object'),
  default_activity_rule_name: attr('string'),
  default_activity_rule_name_template: attr('string'),
  default_activity_rule_details_template: attr('string'),
  default_activity_rule_image_url: attr('string'),
  default_activity_rule_value_description_template: attr('string'),
  default_fulfill_reason_text_template: attr('string'),

  condition_activity_attribute_definitions: hasMany(
    'activity-attribute-definition',
    { async: false, inverse: null }
  ),
  defaultRateLimitConditions: hasMany('condition', {
    async: false,
    inverse: null,
  }),

  sourceForDisplay: computed('category', 'source', function () {
    let { category, source } = this;

    switch (category) {
      case categoryNames.smileApps:
        return source;

      case categoryNames.custom:
        return 'Custom action';

      default:
        return null;
    }
  }).readOnly(),
});
