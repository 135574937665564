import UnauthenticatedRoute from 'smile-admin/routes/unauthenticated';

export default class AuthLoginRoute extends UnauthenticatedRoute {
  setupController(controller) {
    super.setupController(...arguments);

    controller.setProperties(
      this.controllerFor('auth').getProperties(
        'email',
        'hasRecoveredPassword',
        'hasResetPassword',
        'isResetPasswordTokenExpired'
      )
    );
  }
}
