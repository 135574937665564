import Model, { belongsTo, attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';
import { computed } from '@ember/object';

export default Model.extend({
  target_email: attr('string'),
  activity_id: attr('string'),
  created_at: attr('date'),
  updated_at: attr('date'),

  source: belongsTo('customer', { async: false, inverse: null }),
  target: belongsTo('customer', { async: false, inverse: null }),

  targetStatus: computed('target', 'activity_id', function () {
    if (isBlank(this.get('target'))) {
      return 'Waiting for signup';
    }

    if (isBlank(this.get('activity_id'))) {
      return 'Waiting for first order';
    }

    return 'First purchase complete, points awarded';
  }),
});
