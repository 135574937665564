import Model, { belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';

export const interactionMediums = {
  panelAndLauncher: 'panel_and_launcher',
  prompts: 'prompts',
};

export default Model.extend(
  addDefaultAndCustomizedFieldsToModel({
    // hex color values
    primaryColor: attr('string'),
    secondaryColor: attr('string'),

    // ex: '20px'
    smileUiDesktopBottomMargin: attr('string'),
    smileUiDesktopSideMargin: attr('string'),

    // ex: '30px' since this was originally used by smile-ui on mobile
    smileUiMobileBottomMargin: attr('string'),
    smileUiMobileSideMargin: attr('string'),

    // 'left' or 'right'
    smileUiMobilePosition: attr('string'),

    // presets are either 'primary' or 'secondary'
    buttonColorPreset: attr('string'),
    linkColorPreset: attr('string'),
    iconColorPreset: attr('string'),

    // 'black' or 'white'
    buttonFontColor: attr('string'),

    // 'dark' or 'light'
    theme: attr('string'),
  }),
  {
    smileUiDesktopBottomMarginMin: attr('string'),
    smileUiDesktopSideMarginMin: attr('string'),

    // 'left' or 'right'
    smileUiDesktopPosition: attr('string'),

    smileUiMobileBottomMarginMin: attr('string'),
    smileUiMobileSideMarginMin: attr('string'),

    interactionMedium: attr('string'),

    // button, link, and icon colors are hex
    // color values of 'primary' or 'secondary'
    buttonColor: attr('string'),
    linkColor: attr('string'),
    iconColor: attr('string'),

    customerLocale: attr('string'),
    updatedAt: attr('date'),

    account: belongsTo('account', { async: false, inverse: 'displaySetting' }),
    customerNotificationTheme: belongsTo('customer-notification-theme', {
      async: false,
      inverse: 'displaySetting',
    }),

    hasPromptsInteractionMedium: equal(
      'interactionMedium',
      interactionMediums.prompts
    ),
    hasPanelInteractionMedium: equal(
      'interactionMedium',
      interactionMediums.panelAndLauncher
    ),
  }
);
