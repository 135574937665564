import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    let serializedPayload = this._super(...arguments);

    if (serializedPayload.interval === 'quarter') {
      serializedPayload.interval = 'month';
      serializedPayload.interval_count = serializedPayload.interval_count * 3;
    }

    return serializedPayload;
  },

  normalize(type, payload) {
    let modifiedPayload = payload;

    if (payload.interval === 'month' && payload.interval_count % 3 === 0) {
      modifiedPayload.interval = 'quarter';
      modifiedPayload.interval_count = payload.interval_count / 3;
    }

    return this._super(type, modifiedPayload);
  },

  attrs: {
    billingSubscriptionTemplateProductPlans: {
      serialize: false,
      deserialize: 'records',
    },
  },
});
