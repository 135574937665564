/* import __COLOCATED_TEMPLATE__ from './set-brand-color.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';

@tagName('')
export default class SetBrandColorComponent extends Component {
  @service router;

  @action
  transitionToDisplayThemePage() {
    this.router.transitionTo('settings.branding.theme', {
      queryParams: { displaySection: 'colors' },
    });
  }
}
