import { htmlSafe } from '@ember/template';
import { dasherize } from '@ember/string';
import { assert } from '@ember/debug';

import { sanitizeCustomProperties } from './css.ts';

/**
 * CSS properties which accept numbers but are not in units of "px".
 * Source https://github.com/facebook/react/blob/704aeed022f4277cd5604bf6d76199a6cfe4707f/packages/react-dom-bindings/src/shared/isUnitlessNumber.js
 */
const UNITLESS_CSS_PROPERTIES = new Set([
  'animationIterationCount',
  'aspectRatio',
  'borderImageOutset',
  'borderImageSlice',
  'borderImageWidth',
  'boxFlex',
  'boxFlexGroup',
  'boxOrdinalGroup',
  'columnCount',
  'columns',
  'flex',
  'flexGrow',
  'flexPositive',
  'flexShrink',
  'flexNegative',
  'flexOrder',
  'gridArea',
  'gridRow',
  'gridRowEnd',
  'gridRowSpan',
  'gridRowStart',
  'gridColumn',
  'gridColumnEnd',
  'gridColumnSpan',
  'gridColumnStart',
  'fontWeight',
  'lineClamp',
  'lineHeight',
  'opacity',
  'order',
  'orphans',
  'scale',
  'tabSize',
  'widows',
  'zIndex',
  'zoom',
  // SVG-related properties
  'fillOpacity',
  'floodOpacity',
  'stopOpacity',
  'strokeDasharray',
  'strokeDashoffset',
  'strokeMiterlimit',
  'strokeOpacity',
  'strokeWidth',
]);

/**
 * Converts a CSSProperties object to a string of inline styles.
 * This function is used to sanitize the styles and convert them to a string
 * that can be safely used in an inline style attribute.
 */
export function htmlSafeStyle(
  styles: Record<string, string | number | undefined>,
) {
  if (!styles) {
    return undefined;
  }

  const sanitizedStyles = sanitizeCustomProperties(
    styles as React.CSSProperties,
  );
  if (!sanitizedStyles) {
    return undefined;
  }

  const style = Object.entries(sanitizedStyles).map(([key, value]) => {
    assert(
      `WARNING: You are using a numeric value (${value}) for the CSS property "${key}" without a unit (ex: "px")`,
      isNaN(Number(value)) ||
        UNITLESS_CSS_PROPERTIES.has(key) ||
        key.startsWith('--pc'),
    );

    return `${dasherize(key)}: ${value};`;
  });

  return htmlSafe(style.join(' '));
}
