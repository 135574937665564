import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class Error404Controller extends Controller {
  @service('router') router;
  @action
  goHome() {
    this.router.transitionTo('/');
  }
}
