import { isEmpty } from '@ember/utils';
import { getOwner } from '@ember/application';
import { regularExpressions } from 'ember-validators/format';

export default function validateActiveUrl(
  options = {
    allowBlank: true,
    message: 'Must be a valid and active URL, e.g. https://example.com',
  }
) {
  return (key, url = '', oldValue, changes, content) =>
    new Promise(async (resolve) => {
      if (options.allowBlank && isEmpty(url)) {
        return resolve(true);
      }

      if (isEmpty(url.match(regularExpressions.url))) {
        return resolve(options.message);
      }

      let owner = getOwner(content);
      let ajax = owner.lookup('service:ajax');

      try {
        const { is_valid: isValid } = await ajax.request(`/v1/urls/validate`, {
          data: { url },
        });

        resolve(isValid ? true : options.message);
      } catch (error) {
        resolve(options.message);
      }
    });
}
