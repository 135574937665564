import { singularize, underscore } from '@ember-data/request-utils/string';

/**
 * A handler for normalizing Active Record API style responses to JSON API which
 * EmberData expects by default (using the JSON:API Cache). This is likely a
 * much lighter & simpler solution than trying to implement the Cache interface.
 */
const ActiveRecordHandler = {
  async request(context, next) {
    const { content, request } = await next(context.request);
    return normalize(content, request.store.schema);
  },
};

const normalize = (response, schema) => {
  const normalizeResource = (resource, type) => {
    // Ensure type exists in schema
    if (!schema.doesTypeExist(type)) {
      throw new Error(`Unknown resource type: ${type}`);
    }

    // Normalize attributes
    const attributesSchema = schema.attributesDefinitionFor({ type });
    const attributes = Object.values(attributesSchema).reduce(
      (attrs, { name }) => {
        attrs[name] = resource[underscore(name)];
        return attrs;
      },
      {}
    );

    // Normalize relationships
    const relationshipsSchema = schema.relationshipsDefinitionFor({ type });
    const relationships = Object.keys(relationshipsSchema).reduce(
      (rels, rel) => {
        const data = resource[underscore(rel)];
        const relationshipType = relationshipsSchema[rel].type;

        if (data) {
          rels[rel] = {
            data: Array.isArray(data)
              ? data.map((item) => ({ type: relationshipType, id: item.id }))
              : { type: relationshipType, id: data.id },
          };
        }

        return rels;
      },
      {}
    );

    return {
      type,
      id: resource.id,
      attributes,
      relationships,
    };
  };

  const normalizeIncluded = (resource, type, included) => {
    const relationshipsSchema = schema.relationshipsDefinitionFor({ type });

    // Helper function to process relationships recursively
    const processRelationship = (data, relType) => {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          included.push(normalizeResource(item, relType));
          normalizeIncluded(item, relType, included);
        });
      } else {
        included.push(normalizeResource(data, relType));
        normalizeIncluded(data, relType, included);
      }
    };

    Object.keys(relationshipsSchema).forEach((rel) => {
      const data = resource[underscore(rel)];
      if (data) {
        processRelationship(data, relationshipsSchema[rel].type);
      }
    });
  };

  // Normalize the primary resource(s)
  // Get the top-level resource key
  const rootKey = Object.keys(response)[0];
  const primaryResources = response[rootKey];
  const type = singularize(rootKey);

  const data = Array.isArray(primaryResources)
    ? primaryResources.map((resource) => normalizeResource(resource, type))
    : normalizeResource(primaryResources, type);

  const included = [];
  if (Array.isArray(primaryResources)) {
    primaryResources.forEach((resource) => {
      normalizeIncluded(resource, type, included);
    });
  } else {
    normalizeIncluded(primaryResources, type, included);
  }

  return { data, included };
};

export default ActiveRecordHandler;
