import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

// List of includes used across various child routes.
export const referralIncludes = [
  'advocate_customer',
  'friend_customer',
  'advocate_reward_fulfillment.state',
  'friend_tracking_reward_fulfillment.state',
  'goal_order.external_url',
  'risks',
].join(',');

export default class ReferralsRewardProgramRoute extends Route {
  @service alert;
  @service errorHandler;
  @service router;
  @service sesh;
  @service store;

  model() {
    return this.store
      .query('reward-program', {
        account_id: this.get('sesh.account.id'),
        type: 'referrals',
        include: 'reward_program_definition',
      })
      .then((rewardPrograms) => rewardPrograms[0]);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      rewardProgram: model,
      router: this.router,
    });
  }
}
