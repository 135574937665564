import { slugs } from 'smile-admin/models/billing-subscription-template';
const checkmark = {
  componentName: 'polaris-icon',
  props: {
    source: 'smile/check',
  },
};

const smileIntegrationsIncludedFeature = {
  name: 'Smile integrations included',
  tooltip:
    '<p>Smile integrations allow you to combine your rewards program with other apps you use to supercharge your customer experience (e.g. emails, reviews, customer service, etc.)</p><a href="https://smile.io/apps" target="_blank" rel="noopener noreferrer">Learn more about Smile integrations</a>',
  [slugs.free]: 0,
  [slugs.starterWithOrderLimit]: 2,
  [slugs.growthWithOrderLimit]: 'Unlimited',
  [slugs.plus]: 'Unlimited',
};

const pointsProgramFeature = {
  name: 'Points program',
  tooltip:
    '<p>The Points program offers your customers a variety of ways to earn and redeem points for rewards at your store, transforming one-time shoppers into repeat sales.</p><a href="https://smile.io/programs/points" target="_blank" rel="noopener noreferrer">Learn more about Points</a>',
  [slugs.free]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: checkmark,
  [slugs.wixPremium]: checkmark,
};

const referralProgramFeature = {
  name: 'Referral program',
  tooltip:
    '<p>The Referral program incentivizes your existing customers to refer new customers to your ecommerce store through easy-to-understand discount rewards (e.g. Give $10 off to a friend, Get $10 off yourself after your friend’s first purchase).</p><a href="https://smile.io/programs/referrals" target="_blank" rel="noopener noreferrer">Learn more about Referrals</a>',
  [slugs.free]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: '-',
  [slugs.wixPremium]: checkmark,
};

const guestEarningFeature = {
  name: 'Guest earning',
  [slugs.free]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: checkmark,
  [slugs.wixPremium]: checkmark,
};

const automatedTranslationsFeature = {
  name: 'Automated translations',
  tooltip:
    '<p>Choose to display your loyalty program to shoppers in one of our preset languages.</p><a href="https://help.smile.io/en/articles/4036240-translation-and-supported-languages" target="_blank" rel="noopener noreferrer">Learn more about translations</a>',
  [slugs.free]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: checkmark,
  [slugs.wixPremium]: checkmark,
};

const shopifyPOSIntegrationFeature = {
  name: 'Shopify POS integration',
  [slugs.free]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const passwordlessLoginFeature = {
  name: 'Passwordless login',
  tooltip:
    '<p>Passwordless login allows your customers to earn and redeem points without ever having to create an account or remember yet another password</p><a href="https://youtu.be/uIJjteJUGgY" target="_blank" rel="noopener noreferrer">Learn more about passwordless login</a>',
  [slugs.free]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: checkmark,
  [slugs.wixPremium]: checkmark,
};

const advancedBrandingFeature = {
  name: 'Advanced branding',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: '-',
  [slugs.wixPremium]: checkmark,
};

const rewardEmailsFeature = {
  name: 'Customizable reward emails',
  tooltip:
    '<p>Our editor makes it easy for you to build eye-catching reward emails that fit seamlessly with the look and feel of your brand.</p><a href="https://smile.io/reward-emails" target="_blank" rel="noopener noreferrer">Learn more about reward emails</a>',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: 'Non-customizable',
  [slugs.wixPremium]: checkmark,
};

const shopifyFlowIntegrationFeature = {
  name: 'Shopify Flow integration',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const nudgesFeature = {
  name: 'Nudges',
  tooltip:
    '<p>Nudges are powerful, on-site reminders to encourage customer engagement at just the right time.</p><a href="https://smile.io/nudges" target="_blank" rel="noopener noreferrer">Learn more about Nudges</a>',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const loyaltyLandingPageFeature = {
  name: 'Loyalty Landing Page',
  tooltip:
    '<a href="https://help.smile.io/en/articles/8174498-loyalty-landing-page" target="_blank" rel="noopener noreferrer">Learn more about Loyalty Landing Page</a>',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const pointsAndRewardsExpirationFeature = {
  name: 'Points and rewards expiration',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
  [slugs.wixFree]: '-',
  [slugs.wixPremium]: checkmark,
};

const freeProductRewardsFeature = {
  name: 'Free product rewards',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const storeCreditRewardsFeature = {
  name: 'Store credit rewards',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const giftCardRewardsFeature = {
  name: 'Gift card rewards',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const vipProgramFeature = {
  name: 'VIP program',
  tooltip:
    '<p>The VIP program allows you to segment your customers into different tiers, creating a unique experience for your most loyal shoppers (e.g. higher points on purchases, larger birthday rewards, and exclusive perks).</p><a href="https://smile.io/programs/vip" target="_blank" rel="noopener noreferrer">Learn more about VIP</a>',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const apiAccessFeature = {
  name: 'API Access',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: '-',
  [slugs.plus]: checkmark,
};

const pointsRedemptionAtCheckoutFeature = {
  name: 'Points redemption at checkout',
  image: '/assets/images/shopify/shopify-plus-logo.svg',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const onDemandAccessToDataFeature = {
  name: 'On-demand access to your loyalty data ',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: '-',
  [slugs.plus]: checkmark,
};

const curatedReportFeature = {
  name: 'Curated reports for high volume business',
  [slugs.free]: '-',
  [slugs.starterWithOrderLimit]: '-',
  [slugs.growthWithOrderLimit]: '-',
  [slugs.plus]: checkmark,
};

const analyticsFeature = {
  name: 'Advanced analytics',
  [slugs.free]: '-',
  [slugs.wixFree]: '-',
  [slugs.wixPremium]: checkmark,
  [slugs.starterWithOrderLimit]: checkmark,
  [slugs.growthWithOrderLimit]: checkmark,
  [slugs.plus]: checkmark,
};

const customerSupportFeature = {
  name: 'Customer Support',
  [slugs.free]: 'Live chat',
  [slugs.starterWithOrderLimit]: 'Live chat',
  [slugs.growthWithOrderLimit]: 'Live chat',
  [slugs.plus]: 'Jump-the-line priority support',
};

export const shopifyFeatures = [
  smileIntegrationsIncludedFeature,
  pointsProgramFeature,
  referralProgramFeature,
  guestEarningFeature,
  automatedTranslationsFeature,
  shopifyPOSIntegrationFeature,
  advancedBrandingFeature,
  rewardEmailsFeature,
  shopifyFlowIntegrationFeature,
  nudgesFeature,
  analyticsFeature,
  loyaltyLandingPageFeature,
  pointsAndRewardsExpirationFeature,
  freeProductRewardsFeature,
  giftCardRewardsFeature,
  vipProgramFeature,
  pointsRedemptionAtCheckoutFeature,
  onDemandAccessToDataFeature,
  curatedReportFeature,
  apiAccessFeature,
  customerSupportFeature,
];

export const bigcommerceFeatures = [
  smileIntegrationsIncludedFeature,
  pointsProgramFeature,
  referralProgramFeature,
  automatedTranslationsFeature,
  advancedBrandingFeature,
  rewardEmailsFeature,
  nudgesFeature,
  pointsAndRewardsExpirationFeature,
  freeProductRewardsFeature,
  vipProgramFeature,
  storeCreditRewardsFeature,
  onDemandAccessToDataFeature,
  curatedReportFeature,
  apiAccessFeature,
  customerSupportFeature,
];

export const wixFeatures = [
  pointsProgramFeature,
  guestEarningFeature,
  automatedTranslationsFeature,
  passwordlessLoginFeature,
  advancedBrandingFeature,
  referralProgramFeature,
  rewardEmailsFeature,
  pointsAndRewardsExpirationFeature,
  analyticsFeature,
];
