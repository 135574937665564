import { run } from '@ember/runloop';
import { Promise } from 'rsvp';

let loadedScripts = {};

/**
 * Loads a script into a document, ensuring it's loaded only once when loading in the main browser
 * document.
 *
 * @param {*} url             The script to load
 * @param {HTMLDocument} doc  Optional. Only needed if you want to load the script in an iframe.
 *                            When present, will not cache the script, but re-load every time.
 * @returns
 */
export function loadScript(url, doc) {
  let isLoadingInMainDoc = !doc;
  doc = doc || document;

  let promise = loadedScripts[url];

  if (promise && isLoadingInMainDoc) {
    return promise;
  }

  promise = new Promise((resolve, reject) => {
    let element = doc.createElement('script');
    element.type = 'text/javascript';
    element.async = true;

    element.addEventListener(
      'load',
      () => {
        run(resolve);
      },
      false
    );

    element.addEventListener(
      'error',
      () => {
        let error = new Error(`Could not load script ${url}`);
        run(() => {
          reject(error);
        });
      },
      false
    );

    element.src = url;

    let head = doc.head || doc.getElementsByTagName('head')[0];
    head.appendChild(element);
  });

  loadedScripts[url] = promise;

  return promise;
}
