import Handlebars from 'handlebars';
import { formatCurrency } from '../helpers/format-currency';
import { formatPoints } from '../helpers/format-points';
import { pointsLabel } from '../helpers/points-label';

export const HELPERS_THAT_INCLUDE_INPUT_VALUE_IN_OUTPUT = ['lowercase'];

export const helpers = {
  format_currency: _wrapFormatCurrency,
  format_points: (applicationInstance) =>
    _wrapWithSesh(formatPoints, applicationInstance),
  format_points_label: (applicationInstance) =>
    _wrapWithSesh(pointsLabel, applicationInstance),
  subtract: () => subtract,
  or: () => or,
  lowercase: () => lowercase,
};

function _wrapWithSesh(helperFn, applicationInstance) {
  let sesh = applicationInstance.lookup('service:sesh');
  return (value) => helperFn(value, sesh);
}

function _wrapFormatCurrency(applicationInstance) {
  return _wrapWithSesh(
    (args, ...rest) => formatCurrency(args?.hash?.value, ...rest),
    applicationInstance
  );
}

function subtract() {
  // Hardcoded since it's only for previews.
  return 250;
}

function or(...args) {
  return args.find(Boolean) || args[args.length - 1];
}

function lowercase(string) {
  if (!string) return '';
  if (typeof string === 'string') {
    return string.toLowerCase();
  }
  return '';
}

export function initialize(applicationInstance) {
  Object.keys(helpers).forEach((helperName) => {
    Handlebars.registerHelper(
      helperName,
      helpers[helperName](applicationInstance)
    );
  });
}

export default {
  initialize: initialize,
};
