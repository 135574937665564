import Features from 'ember-feature-flags/services/features';
import { inject as service } from '@ember/service';
import { datadogRum } from '@datadog/browser-rum';

export default Features.extend({
  config: service(),

  enable(flag) {
    datadogRum.addFeatureFlagEvaluation(flag, true);
    this._super(...arguments);
  },

  disable(flag) {
    datadogRum.addFeatureFlagEvaluation(flag, false);
    this._super(...arguments);
  },

  // Overwrites addon to have appropriate logging messsage
  _logFeatureFlagMiss(feature) {
    // eslint-disable-next-line no-console
    if (console && console.info) {
      // eslint-disable-next-line no-console
      console.info('Rollout flag off:', feature);
    }
  },
});
