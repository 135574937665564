import SmileApplicationAdapter from '@smile-io/ember-smile-core/adapters/smile-application';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { storageFor } from 'ember-local-storage';
import serializeQueryParams from 'ember-fetch/utils/serialize-query-params';

/**
 * Allows us to pass query params to `findRecord`, `destroyRecord` etc.. This allows us to do
 * something like:
 *    store.findRecord('type', 123, { adapterOptions: { query: { foo: 'bar' } } });
 *
 * Recommended by Ember Data team: https://github.com/emberjs/data/issues/3596#issuecomment-126604014
 */
function addQueryParamsToDefaultUrl(url, snapshot) {
  let query = get(snapshot, 'adapterOptions.query');
  if (query) {
    // Assumes no query params are present already
    url = `${url}?${serializeQueryParams(query)}`;
  }

  return url;
}

export default class ApplicationAdapter extends SmileApplicationAdapter {
  @service config;
  @service session;

  @storageFor('sweet-tooth-session') localStorage;

  @readOnly('config.apiHost') host;
  @readOnly('config.apiNamespace') namespace;

  handleResponse(status /*headers, payload  , requestData */) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }

    return super.handleResponse(...arguments);
  }

  urlForFindRecord(id, modelName, snapshot) {
    let url = super.urlForFindRecord(...arguments);
    return addQueryParamsToDefaultUrl(url, snapshot);
  }

  urlForDeleteRecord(id, modelName, snapshot) {
    let url = super.urlForDeleteRecord(...arguments);
    return addQueryParamsToDefaultUrl(url, snapshot);
  }
}
