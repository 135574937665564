/* import __COLOCATED_TEMPLATE__ from './app-top-bar.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';

@tagName('')
export default class AppTopBar extends Component {
  @service media;
  @service sesh;
}
