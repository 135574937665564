import Controller from '@ember/controller';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { storageFor } from 'ember-local-storage';

export default Controller.extend({
  router: service('router'),
  queryParams: ['token', { redirectTo: 'redirect_to', isAdmin: 'is_admin' }],

  session: service(),
  localStorage: storageFor('sweet-tooth-session'),

  autoLogin: task(function* () {
    try {
      let { token, redirectTo, isAdmin } = this;

      // Keep track if this is a Smile.io admin logging in automatically
      // into an account. We avoid tracking various things if it's not the
      // actual merchant.
      if (isAdmin) {
        this.set('localStorage.isAdmin', true);
      }

      yield this.session.authenticate('authenticator:user', null, token);

      // If we have a redirect route use that, otherwise ember-simple-auth
      // will take us to the `home` route
      if (isPresent(redirectTo)) {
        this.router.transitionTo(redirectTo);
      }
    } catch (err) {
      if (err.status === 401) {
        this.send('goLogin');
        return;
      }

      // Should not really happen (maybe just report?)
      throw err;
    }
  }).drop(),
});
