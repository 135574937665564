import { computed } from '@ember/object';
import { camelize } from '@ember/string';

/**
 * Helper function to return a CP that parses the template variables "documentation"
 * returned by the API into a form that can be used to validate templated fields.
 *
 * Takes a string path to an object of template variables "documentation" in this form:
 *
 * {
 *   <template attribute name (underscored)>: [
 *     {
 *       property: <property path (can be nested e.g. 'customer.first_name')>,
 *       name: <merchant-friendly name for this property>,
 *       description: <description of this property>,
 *     },
 *     ...
 *   ]
 *   ...
 * }
 *
 * The returned CP returns a hash in this form:
 *
 * {
 *   <template attribute name (camel-cased)>: {
 *     <property path (root section, e.g. 'customer')>: {
 *       <property path (nested section, e.g. 'first_name')>: {}
 *     }
 *     ...
 *   }
 *   ...
 * }
 *
 * Example: if the object this CP is applied to has a `templateVariablesDetails` property like this:
 *
 *   templateVariablesDetails: {
 *     vip_program_details_description_template: [{
 *       property: 'customer.vip_tier.name',
 *       name: 'Vip tier',
 *       description: 'The name of the customers current vip tier'
 *     }, {
 *       property: 'customer.next_vip_tier.name',
 *       name: 'Next vip tier',
 *       description: 'The name of the next vip tier the customer can enter'
 *     }],
 *     vip_program_progress_template: [{
 *       property: 'customer.vip_milestone_to_next_tier_formatted',
 *       name: 'Milestone to next tier',
 *       description: 'The remaining progress for the customer until they reach the next tier'
 *     }],
 *   }
 *
 * and you add
 *
 *   dummyDataForTemplateValidations: buildDummyDataForTemplateValidations('templateVariablesDetails')
 *
 * to the object's properties, `dummyDataForTemplateValidations` will have this value:
 *
 *   {
 *     vipProgramDetailsDescriptionTemplate: {
 *       customer: {
 *         vip_tier: {
 *           name: {}
 *         },
 *         next_vip_tier: {
 *           name: {}
 *         },
 *         vip_milestone_to_next_tier_formatted: {}
 *       }
 *     },
 *     vipProgramProgressTemplate: {
 *       customer: {
 *         vip_milestone_to_next_tier_formatted: {},
 *         next_vip_tier: {
 *           name: {}
 *         }
 *       }
 *     }
 *   }
 */
export default function buildDummyDataForTemplateValidations(
  templateVariablesPath
) {
  return computed(templateVariablesPath, function () {
    let templateVariables = this.get(templateVariablesPath) || {};

    let dummyDataForTemplateValidations = {};

    Object.keys(templateVariables).forEach((templatedFieldName) => {
      let propDescriptors = templateVariables[templatedFieldName];

      let dataForTemplateField = {};
      propDescriptors.forEach(({ property: propPath }) => {
        // Handle nested properties by building a recursive hash of objects.
        let dataObj = dataForTemplateField;
        let propPathParts = propPath.split('.');

        propPathParts.forEach((propPathPart) => {
          if (!dataObj[propPathPart]) {
            dataObj = dataObj[propPathPart] = {};
          } else {
            dataObj = dataForTemplateField[propPathPart];
          }
        });
      });

      dummyDataForTemplateValidations[camelize(templatedFieldName)] =
        dataForTemplateField;
    });

    return dummyDataForTemplateValidations;
  });
}
