import validateSometimes from 'smile-admin/validators/sometimes';
import { validatePresence } from 'ember-changeset-validations/validators';
import moment from 'moment';
import {
  validateMomentIsAfter,
  validateMomentIsSameOrAfter,
  validateMomentIsSameOrBefore,
} from 'smile-admin/validators/moment';
import { computeStartTime } from 'smile-admin/helpers/activity-rule-bonus/schedule-form-data/compute-start-time';
import { combineDateAndTime } from 'smile-admin/utils/moment';

function whenNotStartingImmediately() {
  return !this.get('startsImmediately');
}

function hasStartTime() {
  return (
    this.get('startsImmediately') ||
    (this.get('startDate') && this.get('startTime'))
  );
}

function whenEndingAtCustomTime() {
  return hasStartTime.call(this) && this.get('endTimePreset') === 'custom';
}

export default {
  startDate: validateSometimes(
    [
      validatePresence(true),
      validateMomentIsSameOrAfter({
        getTargetMoment() {
          return moment().startOf('day');
        },
        message: 'Cannot be earlier than today',
      }),
    ],
    whenNotStartingImmediately
  ),
  startTime: validateSometimes(
    [
      validatePresence(true),
      validateSometimes(
        validateMomentIsAfter({
          getTargetMoment() {
            return moment();
          },
          message: 'Must be later than current time',
        }),
        function () {
          // Only need to validate the start time if the start day is the current day.
          return moment(this.get('startDate')).isSame(moment(), 'day');
        }
      ),
    ],
    whenNotStartingImmediately
  ),
  endTimePreset: validateSometimes(
    validatePresence({
      presence: true,
      message: 'You must choose an option for when the campaign should end',
    }),
    hasStartTime
  ),
  endDate: validateSometimes(
    [
      validatePresence(true),
      validateMomentIsSameOrAfter({
        getTargetMoment({ content, changes }) {
          const context = { ...content, ...changes };
          return computeStartTime({
            scheduleFormData: context,
            now: moment(),
          }).startOf('day');
        },
        message: 'Cannot be before start day',
      }),
    ],
    whenEndingAtCustomTime
  ),
  endTime: validateSometimes(
    [
      validatePresence(true),
      validateSometimes(
        validateMomentIsAfter({
          getTargetMoment({ content, changes }) {
            const context = { ...content, ...changes };
            const startTime = computeStartTime({
              scheduleFormData: context,
              now: moment(),
            });

            return combineDateAndTime({
              date: context.endTime,
              time: startTime,
            });
          },
          message: 'Must be later than start time',
        }),
        function () {
          // Only need to validate the end time if the start day is the same as the end day.
          const startDate = this.get('startsImmediately')
            ? moment()
            : this.get('startDate');
          return startDate.isSame(this.get('endDate'), 'day');
        }
      ),
    ],
    whenEndingAtCustomTime
  ),
  endsAt: validateSometimes(
    validateMomentIsSameOrBefore({
      getTargetMoment({ content, changes }) {
        const context = { ...content, ...changes };
        const startTime = computeStartTime({
          scheduleFormData: context,
          now: moment(),
        });
        return moment(startTime).add(1, 'week');
      },
      message: 'Cannot be more than 1 week after start time',
    }),
    whenEndingAtCustomTime
  ),
};
