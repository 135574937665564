import { computed } from '@ember/object';
import { assert } from '@ember/debug';
import categorize from 'smile-admin/utils/categorize';

/**
 * Creates a computed property that maps listings into categories.
 *
 * The resulting property value looks like this:
 *
 * [
 *   { category: 'Category 1', listings: [{},{},{}] },
 *   { category: 'Category 2', listings: [{},{},{}] },
 *   { category: 'Category 3', listings: [{},{},{}] },
 * ].
 *
 * @param  {String} inputList          Required. The name of the property containing the list to categorize.
 * @param  {String} categoryPath       Required. The path to the listing's category property.
 * @param  {String} categorySortOrder  Required. The ordering of the categories.
 *
 * @return {Ember.ComputedProperty}
 */
export default function categorizeCP(
  inputListPath,
  categoryPath,
  categorySortOrder
) {
  assert('Missing required `inputListPath` param', inputListPath);
  assert('Missing required `categoryPath` param', categoryPath);
  assert('Missing required `categorySortOrder` param', categorySortOrder);

  return computed(inputListPath, function () {
    let inputList = this.get(inputListPath);
    return categorize(inputList, categoryPath, categorySortOrder);
  }).readOnly();
}
