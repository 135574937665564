import { computed } from '@ember/object';
import groupBy from 'smile-admin/utils/group-by';

export default function computedGroupBy(collection, property) {
  let dependentKey = collection + '.@each.' + property;

  return computed(dependentKey, function () {
    let items = this.collection;
    return groupBy(items, property);
  });
}
