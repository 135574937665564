import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  type: attr('string'),
  downloadableFileUrl: attr('string'),
  startDate: attr('string'),
  endDate: attr('string'),
  seriesInterval: attr('string'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  trackableTask: belongsTo('trackable-task', { async: false, inverse: null }),
});
