import {
  validatePresence,
  validateFormat,
} from 'ember-changeset-validations/validators';

export default {
  name: validatePresence(true),
  external_reward_type: validatePresence(true),
  customized_default_reward_name_template: validatePresence(true),
  reward_fulfillment_url_template: validateFormat({ type: 'url' }),
};
