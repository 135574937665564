import { pluralize } from 'ember-inflector';
import moment from 'moment';
import { formatNumber } from 'smile-admin/helpers/format-number';

class EndTimePresetOption {
  static generateId({ count, unit }) {
    return `${count}.${unit}`;
  }

  constructor({ count, unit }) {
    this.count = count;
    this.unit = unit;
  }

  get friendlyUnit() {
    const { count, unit } = this;
    return pluralize(count, unit, { withoutCount: true });
  }

  get id() {
    const { count, friendlyUnit } = this;
    return EndTimePresetOption.generateId({ count, unit: friendlyUnit });
  }

  get displayName() {
    const { count, friendlyUnit } = this;
    return `${formatNumber(count)} ${friendlyUnit}`;
  }

  getProjectedEndTime({ startTime }) {
    const { count, unit } = this;
    return moment(startTime).add(count, unit);
  }
}

const endTimePresetOptions = [
  new EndTimePresetOption({
    count: 1,
    unit: 'day',
  }),
  new EndTimePresetOption({
    count: 2,
    unit: 'day',
  }),
  new EndTimePresetOption({
    count: 3,
    unit: 'day',
  }),
];

export default endTimePresetOptions;
