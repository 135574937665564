import Model, { belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';

export const intervals = {
  monthly: 'month',
  quarterly: 'quarter',
  yearly: 'year',
};

export default Model.extend({
  amountCents: attr('number'),
  interval: attr('string'),
  intervalCount: attr('number'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  billingProduct: belongsTo('billing-product', { async: false, inverse: null }),
  billingPlanUsageAllowance: belongsTo('billing-plan-usage-allowance', {
    async: false,
    inverse: null,
  }),

  isMonthly: equal('interval', intervals.monthly),
  isQuarterly: equal('interval', intervals.quarterly),
  isYearly: equal('interval', intervals.yearly),
});
