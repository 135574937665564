import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  displayName: attr('string'),
  isPublic: attr('boolean'),
  shortDescription: attr('string'),
  longDescriptionHtml: attr('string'),
  privacyPolicyUrl: attr('string'),
  submissionStatus: attr('string'),
  slug: attr('string'),
  iconUrl: attr('string'),
  benefitsList: attr('array'),
  websiteUrl: attr('string'),
  documentationUrl: attr('string'),
  category: attr('string'),

  app: belongsTo('app', { async: false, inverse: 'appListing' }),
});
