/* import __COLOCATED_TEMPLATE__ from './feedback-form.hbs'; */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { resolve } from 'rsvp';
import { dropTask } from 'ember-concurrency';
import { tagName } from '@ember-decorators/component';

@tagName('')
export default class FeedbackFormComponent extends Component {
  /**
   * Callback when the feedback form is submitted
   * Provides `feedbackText` and `wasContentUseful`
   * as arguments.
   *
   * @type {Function}
   * @public
   */
  onSubmitFeedback = resolve;

  @computed()
  get radioOptions() {
    return [
      { label: 'This is useful', value: true },
      { label: "This isn't useful", value: false },
    ];
  }

  @dropTask
  submitFeedback = function* submitFeedback() {
    let { feedbackText, wasContentUseful, onSubmitFeedback } = this;
    yield onSubmitFeedback(wasContentUseful, feedbackText);
  };

  @action
  changeWasContentUseful([selectedValue]) {
    this.set('wasContentUseful', selectedValue);
  }
}
