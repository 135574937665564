/* import __COLOCATED_TEMPLATE__ from './upgrade-trigger.hbs'; */
import Component from '@ember/component';

/**
 * Upgrade trigger.
 * Use it where you need a full title and description with an upgrade trigger.
 *
 * Usage:
 *    {{#upgrade-trigger-provider ... as |provider|}}
 *      {{upgrade-trigger provider=provider ...}}
 *    {{/upgrade-trigger-provider}}
 */
export default Component.extend({
  tagName: '',

  /**
   * The heading text.
   *
   * @property heading
   * @type {String}
   * @default null
   * @public
   */
  heading: null,

  /**
   * Description for the upgrade trigger.
   *
   * @property description
   * @type {String}
   * @default null
   * @public
   */
  description: null,

  /**
   * Wrapper component to render around content.
   * If left null, will render a sectioned `polaris-card`.
   *
   * @property wrapperComponent
   * @type {Component}
   * @default null
   * @public
   */
  wrapperComponent: null,
});
