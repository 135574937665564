import { computed, get } from '@ember/object';
import { assert } from '@ember/debug';
import { capitalize, classify } from '@ember/string';
import { decoratorWithRequiredParams } from '@ember-decorators/utils/decorator';

/**
 * Decorator to find a subscription template by slug and interval
 *
 * ```js
 * import Component from '@ember/component';
 * import { subscriptionTemplateFor } from 'smile-admin/utils/decorators/billing';
 *
 * export default class ExampleComponent extends Component {
 *   // All the subscription templates to search through
 *   subscriptionTemplates;
 *
 *    // Returns the monthly Free subscription template
 *   @subscriptionTemplateFor('subscriptionTemplates', { slug: 'free' })
 *   freeSubscriptionTemplate;
 *
 *    // Returns the yearly Growth subscription template
 *   @subscriptionTemplateFor('subscriptionTemplates', { slug: 'growth', interval: 'year' })
 *   growthSubscriptionTemplateYearly;
 * }
 * ```
 */
export const subscriptionTemplateFor = decoratorWithRequiredParams(
  (target, key, desc, params) => {
    let [subscriptionTemplatesName, options] = params;

    assert(
      '@subscriptionTemplateFor expects a property name (that contains all subscription templates) as the first parameter.',
      typeof subscriptionTemplatesName === 'string'
    );

    let { slug, interval = 'month' } = options;
    assert(
      '@subscriptionTemplateFor expects an object with a `slug` property as the second parameter.',
      typeof slug !== 'undefined'
    );

    let cp = computed(`${subscriptionTemplatesName}.[]`, function () {
      let { subscriptionTemplates = [] } = this;
      return subscriptionTemplates.find(
        (template) =>
          get(template, `has${classify(slug)}Slug`) &&
          get(template, `is${capitalize(interval)}ly`)
      );
    });

    return cp(target, key, desc);
  }
);
