/* import __COLOCATED_TEMPLATE__ from './banner-images.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',

  ajax: service(),
  config: service(),
  errorHandler: service(),
  features: service(),
  sesh: service(),

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {},

  actions: {
    handleBannerDrop(panelHeaderChangeset, { iconUrl, iconS3Key }) {
      this.onChange(
        'panelHeader',
        panelHeaderChangeset,
        'bannerImageUrl',
        iconUrl
      );
      this.onChange(
        'panelHeader',
        panelHeaderChangeset,
        'bannerImageTempS3Key',
        iconS3Key
      );
    },

    handleBrandIconDrop(panelHeaderChangeset, { iconUrl, iconS3Key }) {
      this.onChange('panelHeader', panelHeaderChangeset, 'iconUrl', iconUrl);
      this.onChange(
        'panelHeader',
        panelHeaderChangeset,
        'iconTempS3Key',
        iconS3Key
      );
    },

    removeBrandIcon(panelHeaderChangeset) {
      this.onChange('panelHeader', panelHeaderChangeset, 'iconUrl', null);
      this.onChange('panelHeader', panelHeaderChangeset, 'iconTempS3Key', null);
    },

    removeBannerImage(panelHeaderChangeset) {
      this.onChange(
        'panelHeader',
        panelHeaderChangeset,
        'bannerImageUrl',
        null
      );
      this.onChange(
        'panelHeader',
        panelHeaderChangeset,
        'bannerImageTempS3Key',
        null
      );
    },

    handleError(err) {
      this.errorHandler.handle(err);
    },
  },
});
