import { helper } from '@ember/component/helper';
import numeral from 'numeral';

/**
 * Helper to format billing subscription counts.
 * Standard numeral format with the small tweak to take into account Infinity,
 * in which case `Unlimited` is retuned.
 *
 * @param {Number} number   Number to format
 * @param {format} options  A numeral format string.
 */
export function formatCount([number], options) {
  if (number === Infinity) {
    return 'Unlimited';
  }

  let { format } = options || {};
  if (typeof number === 'undefined') {
    number = null;
  }

  if (isNaN(number)) {
    number = null;
  }

  return numeral(number).format(format);
}

export default helper(formatCount);
