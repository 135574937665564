import Helper from '@ember/component/helper';
import { observer } from '@ember/object';
import { isBlank } from '@ember/utils';
import HandlebarsCompiler from 'smile-admin/objects/handlebars-compiler';
import { inject as service } from '@ember/service';

/**
 * Helper to compile Handlebar templates on the fly.
 *
 * @param {String} template           The Handlebars template
 * @param {Object} templateVariables  The variables required for the template.
 * @param {Ember.Object} context      The object that contains the variables.
 *
 *  Usage:
 *    {{render-handlebars template templateVariables context}}
 */
export default Helper.extend({
  sesh: service(),

  compute([template, templateVariables, context, defaultText]) {
    if (isBlank(template)) {
      if (!isBlank(defaultText)) {
        return defaultText;
      }

      return;
    }

    if (isBlank(context) || isBlank(templateVariables)) {
      return template;
    }

    this.set(
      'compiler',
      HandlebarsCompiler.create({
        template,
        templateVariables,
        content: context,
        sesh: this.get('sesh'),
      })
    );

    let compiledTemplate = this.get('compiler.compiledTemplate');
    if (isBlank(compiledTemplate)) {
      return defaultText;
    }

    return compiledTemplate;
  },

  /**
   * When the context changes we want the value returned by the helper to
   * be be recomputed.
   */
  // eslint-disable-next-line ember/no-observers
  contentDidChange: observer('compiler.compiledTemplate', function () {
    this.recompute();
  }),
});
