import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, filterBy, readOnly } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import { customizableProperty } from 'smile-admin/utils/customizable-property';
import { types as conditionTypes } from 'smile-admin/models/condition';

export const rewardValueTypes = {
  variable: 'variable',
  fixed: 'fixed',
};

export default Model.extend({
  name: attr('string'),
  details: attr('string'),
  value_description: attr('string'),
  value_description_template: attr('string'),
  image_url: attr('string'),
  rewarded_count: attr('number'),

  type: attr('string'),
  activity_type: attr('string'),
  reward_value_type: attr('string'),
  is_enabled: attr('boolean'),
  is_discontinued: attr('boolean'),

  /**
   * When reward_value_type = variable, otherwise the value is set on the reward.
   */
  step_attribute_name: attr('string'),
  step: attr('number'),
  multiplier: attr('number'),
  min: attr('number'),
  max: attr('number'),

  customized_name: attr('string'),
  customized_name_template: attr('string'),
  customized_details_template: attr('string'),
  customized_value_description_template: attr('string'),
  customized_fulfill_reason_text_template: attr('string'),
  customized_image_url: attr('string'),
  customized_image_temp_s3_key: attr('string'),
  imageSvg: attr('string'),

  like_url: attr('string'), // facebook_like
  share_url: attr('string'), // facebook_share, twitter_share
  share_message: attr('string'), // twitter_share
  username: attr('string'), // instagram_follow, twitter_follow, tiktok_follow

  /**
   * TODO: I switched these from 'utc' to 'date' because our custom
   * utc transform breaks sorting via the native ember way:
   * http://emberjs.com/api/classes/Ember.computed.html#method_sort
   *
   * The utc transform has a blog with it - perhaps we can figure out
   * how to give it sorting ability
   */
  created_at: attr('date'),
  updated_at: attr('date'),

  customized_award_conditions: hasMany('condition', {
    async: false,
    inverse: null,
  }),
  customized_cancel_conditions: hasMany('condition', {
    async: false,
    inverse: null,
  }),
  reward_program: belongsTo('reward-program', { async: false, inverse: null }),
  reward: belongsTo('reward', { async: false, inverse: null }),
  activity_rule_definition: belongsTo('activity-rule-definition', {
    async: false,
    inverse: null,
  }),
  activityRuleBonuses: hasMany('activity-rule-bonus', {
    async: false,
    inverse: 'activityRule',
  }),

  hasFixedRewardValueType: equal('reward_value_type', 'fixed'),
  hasVariableRewardValueType: equal('reward_value_type', 'variable'),

  rateLimit: readOnly('rateLimits.0'),

  /**
   * TODO this should probably be removable
   */
  customizedName: customizableProperty('name'),
  customizableName: customizableProperty('name', 'customized_name_template'),
  customizableImageUrl: customizableProperty('image_url'),
  rateLimits: filterBy(
    'customized_award_conditions',
    'type',
    conditionTypes.rateLimit
  ),
  attributeComparisonAwardConditions: filterBy(
    'customized_award_conditions',
    'type',
    'attribute_comparison'
  ),

  isActive: computed('is_enabled', 'is_discontinued', function () {
    return this.get('is_enabled') && !this.get('is_discontinued');
  }),

  customizedNameTemplate: customizableProperty(
    'activity_rule_definition.default_activity_rule_name_template',
    'customized_name_template'
  ),

  enable: memberAction({
    path: 'enable',
    after: serializeAndPush,
  }),
  disable: memberAction({
    path: 'disable',
    after: serializeAndPush,
  }),
  removeImage: memberAction({
    path: 'remove_image',
    after: serializeAndPush,
  }),
  createCustomizedAwardCondition: memberAction({
    path: 'customized_award_conditions',
    type: 'post',
    after: serializeAndPush,
  }),
});
