import Model, { belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const states = {
  paid: 'paid',
  refunded: 'refunded',
};

export default Model.extend({
  points_spent: attr('number'),
  state: attr('string'),
  stateChangedAt: attr('utc'),
  created_at: attr('utc'),
  updated_at: attr('utc'),

  customer: belongsTo('customer', { async: false, inverse: null }),
  rewardFulfillment: belongsTo('reward-fulfillment', {
    async: false,
    inverse: null,
  }),

  isRefunded: equal('state', states.refunded).readOnly(),

  refund: memberAction({
    path: 'refund',
    type: 'post',
    after: serializeAndPush,
  }),
});
