import { reads } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { exchangeTypes } from 'smile-admin/models/points-product';
import pointsProductValidations from 'smile-admin/validations/points-product';
import RewardEngine from 'smile-admin/objects/reward-engine';

/**
 * Dummy default values engine as specd in the following link
 * https://docs.google.com/document/d/1afwd1HRgiWtbwu0tFMy2VkjSMwnto1c_03jMdf_nZ-w/edit#heading=h.oc94xjitavuo
 */
export default class PointsProductEngine {
  pointsProductChangeset = null;

  @tracked pointsProduct = null;

  @reads('pointsProduct.reward')
  reward;

  @reads('pointsProduct.reward.reward_definition')
  rewardDefinition;

  constructor(pointsProduct) {
    this.pointsProduct = pointsProduct;
    this.createChangeset();
  }

  createChangeset() {
    if (!this.pointsProduct) {
      return null;
    }

    let pointsProductChangeset = new Changeset(
      this.pointsProduct,
      lookupValidator(pointsProductValidations),
      pointsProductValidations,
      { skipValidate: true }
    );

    this.pointsProductChangeset = pointsProductChangeset;
  }

  getDefaults(options = {}) {
    let { only } = options;
    only = typeof only === 'string' ? [only] : only;

    let { pointsProductChangeset, reward } = this;
    let defaults = {
      points_price: 500,
    };

    let hasVariableExchangeType = pointsProductChangeset
      ? pointsProductChangeset.get('exchange_type') === exchangeTypes.variable
      : this.pointsProduct.hasVariableExchangeType;

    if (
      this.rewardDefinition.supportsVariableValues &&
      hasVariableExchangeType
    ) {
      defaults = {
        ...defaults,
        variable_points_step: 100,
        variable_points_step_reward_value: 1,
        variable_points_min: pointsProductChangeset.get('variable_points_step'),
        variable_points_max:
          pointsProductChangeset.get('variable_points_step') * 2,
      };
    } else if (reward.hasPercentageValue || reward.isFreeShippingType) {
      defaults = { ...defaults, points_price: 1000 };
    } else if (reward.isFreeProductType) {
      defaults = { ...defaults, points_price: 100 };
    }

    let rewardEngine = new RewardEngine(reward);
    let rewardDefaults = rewardEngine.getDefaults();
    Object.keys(rewardDefaults).forEach(
      (key) =>
        (defaults = {
          ...defaults,
          [`reward.${key}`]: rewardDefaults[key],
        })
    );

    if (only) {
      return only.reduce(
        (result, key) => ({ ...result, [key]: defaults[key] }),
        {}
      );
    }

    return defaults;
  }
}
