import Inflector from 'ember-inflector';

export function initialize(/* application */) {
  let { inflector } = Inflector;

  // Set up custom pluralizations.
  inflector.irregular('reward used', 'rewards used');
  inflector.irregular('reward redeemed', 'rewards redeemed');
}

export default {
  initialize,
};
