import Ephemeral from 'ember-simple-auth/session-stores/ephemeral';

/**
 * Registers the `Ephemeral` session-store from `ember-simple-auth` which is not done by default,
 * since it's not recommended to be used in production. However, it's a great fit for our Shopify
 * embedded use-case.
 */
export default {
  name: 'ember-simple-auth-ephemeral-storage',

  initialize(registry) {
    registry.register('session-store:ephemeral', Ephemeral);
  },
};
