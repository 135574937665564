import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  scope: attr('string'),
  shouldReauthenticate: attr('boolean'),

  newIntegration: belongsTo('new-integration', {
    async: false,
    inverse: 'oauth2AccessToken',
  }),
});
