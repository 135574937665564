import OriginalIntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

const availableLanguagesMetaInfo = {
  'en-US': {
    title: 'English (US)',
    value: 'en-US',
    emoji: '🇺🇸',
  },
  'fr-FR': {
    title: 'Français',
    value: 'fr-FR',
    emoji: '🇫🇷',
  },
  'de-DE': {
    title: 'Deutsche',
    value: 'de-DE',
    emoji: '🇩🇪',
  },
  'pt-BR': {
    title: 'Português',
    value: 'pt-BR',
    emoji: '🇧🇷',
  },
  'es-ES': {
    title: 'Español',
    value: 'es-ES',
    emoji: '🇪🇸',
  },
  'zh-CN': {
    title: '简体中文',
    value: 'zh-CN',
    emoji: '🇨🇳',
  },
};

export default class IntlService extends OriginalIntlService {
  @service
  featureRollouts;

  get runtimeLocales() {
    return [
      availableLanguagesMetaInfo['de-DE'],
      availableLanguagesMetaInfo['en-US'],
      availableLanguagesMetaInfo['es-ES'],
      availableLanguagesMetaInfo['fr-FR'],
      availableLanguagesMetaInfo['pt-BR'],
      availableLanguagesMetaInfo['zh-CN'],
    ];
  }
}
