import Model, { attr } from '@ember-data/model';

export default Model.extend({
  type: attr('string'),
  default_name: attr('string'),
  reward_program_definition_id: attr('number'),
  default_subject_template: attr('string'),
  default_email_html_template: attr('string'),
  public_email_template_variables: attr('object'),
  private_email_template_variables: attr('object'),
});
