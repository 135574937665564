import Model, { attr } from '@ember-data/model';

export const durations = {
  forever: 'forever',
  once: 'once',
  repeating: 'repeating',
};
export const discountTypes = {
  fixedAmount: 'fixed_amount',
  percentage: 'percentage',
};

export default Model.extend({
  type: attr('string'),
  discountType: attr('string'),
  duration: attr('string'),
  durationCount: attr('number'),
  reason: attr('string'),
  amountCentsOff: attr('number'),
  percentOff: attr('number'),
});
