import Model, { belongsTo, attr } from '@ember-data/model';
import { bool } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export default Model.extend({
  brandColor: attr('string'),
  customerLocale: attr('string'),
  launcherIcon: attr('string'),
  nextStep: attr('string'),
  quizVersion: attr('string'),
  rewardProgramName: attr('string'),
  textColor: attr('string'),
  createdAt: attr('date'),
  updatedAt: attr('date'),
  finishedAt: attr('date'),

  account: belongsTo('account', {
    async: false,
    inverse: 'onboardingQuizAnswerSet',
  }),
  accountIndustry: belongsTo('account-industry', {
    async: false,
    inverse: null,
  }),

  isComplete: bool('finishedAt'),

  checkSmileRequirements: memberAction({
    path: 'check_smile_requirements',
    type: 'put',
  }),

  finish: memberAction({
    path: 'finish',
    type: 'put',
    after: serializeAndPush,
  }),
});
