import { modifier } from 'ember-modifier';

interface EventListenerSignature {
  Args: {
    Positional: [event: string, handler: (event: Event) => void];
    Named: {
      capture?: boolean;
      passive?: boolean;
    };
  };
}

export default modifier<EventListenerSignature>(
  (_element: Element, [event, handler], { capture, passive }) => {
    window.addEventListener(event, handler, { capture, passive });

    return () => {
      window.removeEventListener(event, handler);
    };
  },
);
