/**
 * Ensures we only show the Intercom widget on the pages we want it.
 */
export function initialize(appInstance) {
  let router = appInstance.lookup('service:router');
  let applicationController = appInstance.lookup('controller:application');
  router.on('routeDidChange', () => {
    if (!router.currentRoute) {
      return;
    }

    let hideHelpWidget = shouldHideHelpWidget(appInstance, router.currentRoute);
    applicationController.send('setHelpWidgetVisibility', !hideHelpWidget);
  });
}

export default {
  initialize,
};

/**
 * Checks whether the help widget should be hidden or not.
 *
 * Starting with the `routeInfo` node will traverse ancestors until it finds
 * a route with `hideHelpWidget = true`. If none is found, returns `false`.
 *
 * @param {ApplicationInstance} appInstance
 * @param {RouteInfo} routeInfo
 * @returns {Boolean}
 */
export function shouldHideHelpWidget(appInstance, routeInfo) {
  let route = appInstance.lookup(`route:${routeInfo.name}`);
  if (route && route.hideHelpWidget) {
    return true;
  }

  return (
    Boolean(routeInfo.parent) &&
    shouldHideHelpWidget(appInstance, routeInfo.parent)
  );
}
