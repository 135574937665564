import Model, { belongsTo, attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';

export default Model.extend({
  properties: attr('object'),
  isExpired: attr('boolean'),

  expiresAt: attr('date'),
  createdAt: attr('date'),
  updatedAt: attr('date'),

  billingSubscription: belongsTo('billing-subscription', {
    async: false,
    inverse: 'billingPaymentMethod',
  }),

  // Stripe
  brand: readOnly('properties.brand'),
  lastFourDigits: readOnly('properties.last4'),
  expiryMonth: readOnly('properties.exp_month'),
  expiryYear: readOnly('properties.exp_year'),
});
