import OriginalHistoryLocation from '@ember/routing/history-location';
import { inject as service } from '@ember/service';

/**
 * This class overwrites the original HistoryLocation class to support seamless navigation when
 * embedded inside Shopify.
 *
 *  - when embedded inside Shopify, our app is loaded inside an iframe in Shopify, so we use the
 *    Shopify AppBridge to update the top window
 *  - when the app is not embeeded inside Shopify, we just leave the default behavior
 */
export default class HistoryLocation extends OriginalHistoryLocation {
  @service shopify;

  pushState(path) {
    if (this.shopify.isEmbedded) {
      this.shopify.pushHistory(path);
    } else {
      super.pushState(path);
    }
  }

  replaceState(path) {
    if (this.shopify.isEmbedded) {
      this.shopify.replaceHistory(path);
    } else {
      super.replaceState(path);
    }
  }
}
