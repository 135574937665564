import Model, { belongsTo, attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';

export const templateVariables = {
  title: {
    shopify_increase_points_spending_at_cart: [
      {
        value: '{{points_program.points_label_plural}}',
        label: 'Currency name',
      },
    ],
  },
  subtitle: {
    shopify_increase_points_spending_at_cart: [
      {
        value: '{{customer.points_balance_formatted}}',
        label: 'Customer points balance',
      },
    ],
    shopify_increase_referral_url_sharing_at_thank_you: [
      { value: '{{advocate_reward.name}}', label: 'The reward name' },
    ],
  },
};

export default Model.extend(
  addDefaultAndCustomizedFieldsToModel({
    title: attr('string'),
    subtitle: attr('string'),
    cta: attr('string'),
  }),
  {
    type: attr('string'),
    iconUrl: attr('string'),
    imageSvg: attr('string'),
    urlPath: attr('string'),
    targetedParties: attr('string'),
    visibilityConditions: attr('array'),
    isEnabled: attr('boolean'),
    usesDefaultIcon: attr('boolean'),
    defaultIconStyle: attr('string'),
    customizedIconTempS3Key: attr('string'),
    isAvailableOnCurrentPlan: attr('boolean'),
    requiredFeatureFlag: attr('string'),

    nudgeDefinition: belongsTo('nudge-definition', {
      async: false,
      inverse: null,
    }),

    disable: memberAction({
      path: 'disable',
      type: 'put',
      after: serializeAndPush,
    }),

    enable: memberAction({
      path: 'enable',
      type: 'put',
      after: serializeAndPush,
    }),
  }
);
