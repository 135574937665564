import { validateFormat } from 'ember-changeset-validations/validators';

export default {
  customerSignupUrl: validateFormat({
    description: 'Signup URL',
    type: 'url',
    allowBlank: true,
  }),
  customerLoginUrl: validateFormat({
    description: 'Login URL',
    type: 'url',
    allowBlank: true,
  }),
};
