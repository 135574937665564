import numeral from 'numeral';
import {
  validateLength,
  validateFormat,
  validateNumber,
  validatePresence,
} from 'ember-changeset-validations/validators';
import { isBlank } from '@ember/utils';
import validateSometimes from 'smile-admin/validators/sometimes';
import { exchangeTypes } from 'smile-admin/models/points-product';

const validatePointsMinIncrement =
  () => (key, newValue, oldValue, changes, content) => {
    if (isBlank(newValue)) {
      return true;
    }

    let increment = parseInt(
      changes.variable_points_step || content.variable_points_step
    );

    let incrementFormatted = numeral(increment).format('0,0');

    newValue = parseInt(newValue);

    if (newValue < increment) {
      return `Minimum points must be ${incrementFormatted} or greater`;
    }

    // Extra checks just to make sure we don't divide by 0
    let remainder = increment !== 0 ? newValue % increment : 0;

    if (remainder !== 0) {
      // Round up or down to closest increment based on the value entered
      let suggestedValue =
        remainder > increment / 2
          ? newValue + (increment - remainder)
          : newValue - remainder;

      return `Minimum points must be in increments of ${incrementFormatted}. Did you mean ${numeral(
        suggestedValue
      ).format('0,0')} points?`;
    }

    return true;
  };

const validateDecimalPlaces =
  ({ allowableDecimalPlaces }) =>
  (key, newValue) => {
    if (!newValue) {
      return true;
    }

    let decimals = ((newValue.match && newValue.match(/\./g)) || []).length;

    if (decimals === 0) {
      return true;
    }

    if (decimals > 1) {
      return 'Value must be a valid number';
    }

    let valueDecimalPlaces = newValue.split('.')[1].length || 0;

    if (valueDecimalPlaces > allowableDecimalPlaces) {
      return `Value supports up to ${allowableDecimalPlaces} decimal places`;
    }

    return true;
  };

export default {
  points_price: validateSometimes(
    validateNumber({
      integer: true,
      gt: 0,
      description: 'Price',
    }),
    function () {
      return this.get('exchange_type') === exchangeTypes.fixed;
    }
  ),
  variable_points_step: validateSometimes(
    validateNumber({
      integer: true,
      gt: 0,
      description: 'Increment',
    }),
    function () {
      return this.get('exchange_type') === exchangeTypes.variable;
    }
  ),
  variable_points_step_reward_value: validateSometimes(
    validateNumber({
      integer: true,
      gt: 0,
      description: 'Reward value',
    }),
    function () {
      return this.get('exchange_type') === exchangeTypes.variable;
    }
  ),
  variable_points_min: validateSometimes(
    [
      validateNumber({
        integer: true,
        allowBlank: true,
        gt: 0,
        description: 'Points minimum',
      }),
      validatePointsMinIncrement(),
    ],
    function () {
      return this.get('exchange_type') === exchangeTypes.variable;
    }
  ),
  variable_points_max: validateNumber({
    integer: true,
    allowBlank: true,
    gt: 0,
    description: 'Points maximum',
  }),
  reward: {
    entitled_product_variant_ids: validateSometimes(
      [
        validatePresence({
          presence: true,
          description: 'Product',
        }),
      ],
      function () {
        return this.get('reward.isShopifyFreeProductType');
      }
    ),
    maximum_shipping_amount: validateSometimes(
      [
        validateNumber({
          allowBlank: true,
          gt: 0,
          description: 'Shipping amount',
        }),
        validateDecimalPlaces({ allowableDecimalPlaces: 2 }),
      ],
      function () {
        return this.get('reward.supportsMaximumShippingAmount');
      }
    ),
    value: validateSometimes(
      [
        validateNumber({
          positive: true,
          gt: 0,
          description: 'Discount value',
        }),
        validateDecimalPlaces({ allowableDecimalPlaces: 2 }),
        validateSometimes(
          validateNumber({
            lte: 100,
            description: 'Discount value',
          }),
          function () {
            return this.get('reward.hasPercentageValue');
          }
        ),
      ],
      function () {
        return this.get('exchange_type') === exchangeTypes.fixed;
      }
    ),
    minimum_order_amount: validateSometimes(
      validateNumber({
        integer: true,
        allowBlank: true,
        gt: 0,
        description: 'Minimum purchase',
      }),
      function () {
        return this.get('reward.supportsMinimumOrderAmount');
      }
    ),
    min_purchase: validateSometimes(
      validateNumber({
        integer: true,
        allowBlank: true,
        gt: 0,
        description: 'Minimum purchase',
      }),
      function () {
        return this.get('reward.supportsMinPurchase');
      }
    ),
    prefix: validateSometimes(
      validateFormat({
        allowBlank: true,
        // eslint-disable-next-line no-useless-escape
        regex: /^[a-zA-Z0-9_\-]*$/,
        message:
          'Prefix may only contain letters, numbers, white space, underscores, and hypens',
      }),
      function () {
        return this.get('sesh.hasBigcommercePlatform');
      }
    ),
    customized_fulfillment_action_text_template: validateLength({
      allowBlank: true,
      max: 20,
      description: 'Button text',
    }),
    customized_fulfillment_action_url_template: validateFormat({
      type: 'url',
      allowBlank: true,
      description: 'URL',
    }),
    entitled_collection_ids: validateSometimes(
      validatePresence({
        presence: true,
        description: 'Collection',
      }),
      function () {
        return (
          this.get('sesh.hasShopifyPlatform') &&
          this.get('reward.hasShopifyCollectionLimitation')
        );
      }
    ),
  },
};
