import numeral from 'numeral';

export default class BillingSubscriptionTemplateData {
  constructor(billingSubscriptionTemplate, account) {
    this.billingSubscriptionTemplate = billingSubscriptionTemplate;
    this.account = account;
  }

  get basePlan() {
    return this.billingSubscriptionTemplate.basePlan;
  }

  get membersPlan() {
    return this.billingSubscriptionTemplate.membersAddOnNewBillingPlan;
  }

  get ordersPlan() {
    return this.billingSubscriptionTemplate.ordersAddOnNewBillingPlan;
  }

  get appsPlan() {
    return this.billingSubscriptionTemplate.appsAddOnNewBillingPlan;
  }

  get basePlanAllowance() {
    return this.basePlan.billingPlanUsageAllowance;
  }

  get membersPlanAllowance() {
    return this.membersPlan && this.membersPlan.billingPlanUsageAllowance;
  }

  get ordersPlanAllowance() {
    return this.ordersPlan && this.ordersPlan.billingPlanUsageAllowance;
  }

  get appsPlanAllowance() {
    return this.appsPlan && this.appsPlan.billingPlanUsageAllowance;
  }

  get basePlanName() {
    return this.basePlan.billingProduct.name;
  }

  get friendlyBasePlanName() {
    return this.basePlan.billingProduct.displayName;
  }

  get slug() {
    return this.billingSubscriptionTemplate.slug;
  }

  get basePlanPrice() {
    return this.basePlan.amountCents || 0;
  }

  get ordersPlanPrice() {
    return this.ordersPlan?.amountCents || 0;
  }

  get membersPlanPrice() {
    return this.membersPlan?.amountCents || 0;
  }

  get appsPlanPrice() {
    return this.appsPlan?.amountCents || 0;
  }

  get isMembersInfinite() {
    return this.basePlanAllowance.isProgramMembersInfinite;
  }

  get isAppsInfinite() {
    return this.basePlanAllowance.isSmileAppsInfinite;
  }

  get isMonthlyOrdersInfinite() {
    return this.basePlanAllowance.isMonthlyOrdersInfinite;
  }

  get accountMembersCount() {
    return this.account.members_count || 0;
  }

  // TODO We should remove the account reference from
  // the billing_subscription_template_data, and maybe
  // move this logic to the billing_subscription_data
  get accountOrdersCount() {
    return this.account.totalOrders28d;
  }

  get basePlanMonthlyOrders() {
    return this.isOrdersInfinite
      ? Infinity
      : this.basePlanAllowance.monthlyOrders;
  }

  get basePlanMembersCount() {
    return this.isMembersInfinite
      ? Infinity
      : this.basePlanAllowance.programMembersCount;
  }

  get membersPlanMembersCount() {
    return this.membersPlanAllowance?.programMembersCount || 0;
  }

  get ordersPlanMonthlyOrders() {
    return this.ordersPlanAllowance?.monthlyOrders || 0;
  }

  get creditsMembers() {
    return this.account.usageCredits.map((credit) => credit.programMembers);
  }

  get creditsMembersCount() {
    return this.creditsMembers.reduce((total, count) => total + count, 0);
  }

  get membersUsagePercentage() {
    return (this.accountMembersCount / this.membersCountTotal) * 100;
  }

  get membersCountTotal() {
    return this.basePlanMembersCount + this.creditsMembersCount;
  }

  get requiredMembersSubscriptionItemQuantity() {
    // If the subscription template doesn't have a members plan we shouldn't continue
    if (!this.membersPlan) {
      return 0;
    }

    let { membersPlanMembersCount } = this;
    // Includes base plan members count & credits
    let { membersCountTotal: allowedMembersCount, accountMembersCount } = this;
    if (accountMembersCount < allowedMembersCount) {
      return 0;
    }

    return Math.ceil(
      (accountMembersCount - allowedMembersCount) / membersPlanMembersCount
    );
  }

  get requiredMembersPlanMembersCountTotal() {
    return (
      this.requiredMembersSubscriptionItemQuantity *
      this.membersPlanMembersCount
    );
  }

  get membersPlanPriceTotal() {
    return this.requiredMembersSubscriptionItemQuantity * this.membersPlanPrice;
  }

  get monthlyOrdersTotal() {
    return this.account.monthlyOrdersTotal;
  }

  get ordersPlanPriceTotal() {
    return (
      this.requiredMonthlyOrdersSubscriptionItemQuantity * this.ordersPlanPrice
    );
  }

  get requiredMonthlyOrdersSubscriptionItemQuantity() {
    // If the subscription is not yet active yet, we never compute monthly orders overages, since
    // we bill them at the end of the month not upfront (especially applies for billing offers)
    if (!this.ordersPlan) {
      return 0;
    }

    let { ordersPlanMonthlyOrders, monthlyOrdersTotal, accountOrdersCount } =
      this;

    if (accountOrdersCount < monthlyOrdersTotal) {
      return 0;
    }

    return Math.floor(
      (accountOrdersCount - monthlyOrdersTotal) / ordersPlanMonthlyOrders
    );
  }

  get basePlanAppsCount() {
    return this.isAppsInfinite
      ? Infinity
      : this.basePlanAllowance.smileAppsCount;
  }

  get appsPlanAppsCount() {
    return this.appsPlanAllowance?.smileAppsCount || 0;
  }

  get creditsApps() {
    return this.account.usageCredits.map((credit) => credit.smileApps);
  }

  get creditsAppsCount() {
    return this.creditsApps.reduce((total, count) => total + count, 0);
  }

  get appsCountTotal() {
    return this.basePlanAppsCount + this.creditsAppsCount;
  }

  get accountAppsCount() {
    let nonPlatformNewIntegrations = this.account.newIntegrations.filter(
      (integration) => integration.isPlatformApp === false
    );
    return nonPlatformNewIntegrations.length || 0;
  }

  get supportsApps() {
    return !!this.appsCountTotal;
  }

  get priceTotal() {
    return this.basePlanPrice + this.membersPlanPriceTotal;
  }

  get priceTotalPerMonth() {
    if (this.billingSubscriptionTemplate.isMonthly) {
      return this.priceTotal;
    }
    if (this.billingSubscriptionTemplate.isQuarterly) {
      return this.priceTotal / 4;
    }
    if (this.billingSubscriptionTemplate.isYearly) {
      return this.priceTotal / 12;
    }

    return null;
  }

  get hasZeroCost() {
    return numeral(this.priceTotal).value() === 0;
  }
}
