export const scrollable = {
  props: { 'data-polaris-scrollable': true },
  selector: '[data-polaris-scrollable]',
};

export const overlay = {
  props: { 'data-polaris-overlay': true },
  selector: '[data-polaris-overlay]',
};

export const layer = {
  props: { 'data-polaris-layer': true },
  selector: '[data-polaris-layer]',
};

export const dataPolarisTopBar = {
  props: { 'data-polaris-top-bar': true },
  selector: '[data-polaris-top-bar]',
};

export const portal = {
  props: ['data-portal-id'],
  selector: '[data-portal-id]',
};
