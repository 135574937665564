import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    billingPaymentMethod: { serialize: false, deserialize: 'records' },
    activeBillingDiscount: { serialize: false, deserialize: 'records' },
    pendingBillingDiscount: { serialize: false, deserialize: 'records' },
    billingSubscriptionItems: { serialize: false, deserialize: 'records' },
    billingSubscriptionDefaults: { serialize: false, deserialize: 'records' },
  },
});
