import Model, { belongsTo, attr } from '@ember-data/model';

export default class DailyAccountSummary extends Model {
  @attr('number') totalPointsEarned;
  @attr('number') totalPointsRedeemed;
  @attr('number') totalPointsTransactions;
  @attr('number') totalReferralsCompleted;
  @attr('number') totalNewMembers;
  @attr('number') totalFirstTimeEarners;
  @attr('number') totalEarners;
  @attr('number') totalRedeemers;
  @attr('number') totalOrderValueWithSmileDiscountCode;
  @attr('date') date;

  @belongsTo('account', { async: false, inverse: null })
  account;
}
