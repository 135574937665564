import { validateFormat } from 'ember-changeset-validations/validators';
import { hexColorMatcher } from 'smile-admin/utils/regex';

const hexColorValidations = {
  hexColor: validateFormat({
    regex: hexColorMatcher,
    message: 'Must be a valid hex color',
  }),
};

export { hexColorValidations };
