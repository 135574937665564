import {
  validatePresence,
  validateLength,
} from 'ember-changeset-validations/validators';
import validateSometimes from 'smile-admin/validators/sometimes';
import validateHandlebars from 'smile-admin/validators/handlebars';
import { types } from 'smile-admin/models/referral-sharing-option';

export default {
  meta_tag_title: validateSometimes(
    validatePresence({ presence: true, description: 'Title' }),
    function () {
      return this.get('type') === types.metaTags && this.get('is_enabled');
    }
  ),

  meta_tag_description: validateSometimes(
    validatePresence({ presence: true, description: 'Description' }),
    function () {
      return this.get('type') === types.metaTags && this.get('is_enabled');
    }
  ),

  meta_tag_image_url: validateSometimes(
    validatePresence({
      presence: true,
      description: 'Image',
      message: 'Please provide an image to customize display.',
    }),
    function () {
      return this.get('type') === types.metaTags && this.get('is_enabled');
    }
  ),

  customized_twitter_share_message_template: validateSometimes(
    [
      validateHandlebars({
        templateDataKey: 'previewData',
        allowBlank: true,
        description: 'X message',
      }),
      validateLength({
        max: 280,
        allowBlank: true,
        allowNone: true,
        description: 'X message',
      }),
    ],
    function () {
      return this.get('type') === types.x;
    }
  ),

  customized_email_title_template: validateSometimes(
    validateHandlebars({
      templateDataKey: 'previewData',
      allowBlank: true,
      description: 'Email title',
    }),
    function () {
      return this.get('type') === types.email;
    }
  ),

  customized_email_body_template: validateSometimes(
    validateHandlebars({
      templateDataKey: 'previewData',
      allowBlank: true,
      description: 'Email body',
    }),
    function () {
      return this.get('type') === types.email;
    }
  ),
};
