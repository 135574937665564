/* import __COLOCATED_TEMPLATE__ from './meta-tags-card.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { isNone } from '@ember/utils';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { tagName } from '@ember-decorators/component';
import { dropTask } from 'ember-concurrency';
import referralSharingOptionValidations from 'smile-admin/validations/referral-sharing-option';
import { types } from 'smile-admin/models/referral-sharing-option';

@tagName('')
export default class ReferralSharingOptionMetaTagsCard extends Component {
  @service ajax;
  @service alert;
  @service config;
  @service errorHandler;
  @service sesh;
  @service store;

  metaTagsSharingOptionChangeset = null;

  isEditing = false;

  onAfterSave() {}

  @readOnly(
    'referralSharingOptionDefinitionListing.referral_sharing_option_definition'
  )
  referralSharingOptionDefinition;

  setReferralSharingOptionChangeset() {
    let { store, sesh } = this;
    let referralSharingOptions = store.peekAll('referral-sharing-option');
    let metaTagsSharingOption = referralSharingOptions.find(
      (option) => option.type === types.metaTags
    );

    if (isNone(metaTagsSharingOption)) {
      metaTagsSharingOption = store.createRecord('referral-sharing-option', {
        account: sesh.account,
        reward_program: sesh.referralsRewardProgram,
        sort_order:
          this.referralSharingOptionDefinition
            .referral_sharing_option_definition_listing.sort_order,
        type: types.metaTags,
      });
    }

    this.set(
      'metaTagsSharingOptionChangeset',
      new Changeset(
        metaTagsSharingOption,
        lookupValidator(referralSharingOptionValidations),
        referralSharingOptionValidations,
        { skipValidate: true }
      )
    );
  }

  @dropTask
  *saveMetaTagsSharingOption(metaTagsSharingOptionChangeset) {
    try {
      yield metaTagsSharingOptionChangeset.validate();
      if (metaTagsSharingOptionChangeset.get('isInvalid')) {
        return;
      }

      metaTagsSharingOptionChangeset.execute();

      let referralSharingOptionChanges =
        metaTagsSharingOptionChangeset.get('changes');

      yield metaTagsSharingOptionChangeset.save();
      this.onAfterSave(referralSharingOptionChanges);
      this.set('isEditing', false);

      this.alert.info('Display settings saved');
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  init() {
    super.init(...arguments);
    this.setReferralSharingOptionChangeset();
  }

  destroy() {
    if (this.metaTagsSharingOptionChangeset.get('isNew')) {
      this.metaTagsSharingOptionChangeset.rollback();
    }

    super.destroy(...arguments);
  }

  @action
  setImage(metaTagsSharingOptionChangeset, { iconUrl, iconS3Key }) {
    metaTagsSharingOptionChangeset.setProperties({
      meta_tag_image_temp_s3_key: iconS3Key,
      meta_tag_image_url: iconUrl,
    });
  }

  @action
  removeImage(metaTagsSharingOptionChangeset) {
    metaTagsSharingOptionChangeset.setProperties({
      meta_tag_image_temp_s3_key: null,
      meta_tag_image_url: null,
    });
  }

  @action
  handleImageUploadError(err) {
    this.errorHandler.handle(err);
  }

  @action
  cancelEdit(metaTagsSharingOptionChangeset) {
    metaTagsSharingOptionChangeset.rollback();
    this.set('isEditing', false);
  }

  @action
  changeIsEnabled(metaTagsSharingOptionChangeset, key, value) {
    metaTagsSharingOptionChangeset.set(key, value);
    // We manually validate these fields which should only be valid when `is_enabled` is `true`,
    // because Changeset will not no trigger re-validation of all fields when one is changed, but
    // only for the field being changed
    if (value === false) {
      metaTagsSharingOptionChangeset.validate('meta_tag_title');
      metaTagsSharingOptionChangeset.validate('meta_tag_description');
      metaTagsSharingOptionChangeset.validate('meta_tag_image_url');
    }
  }
}
