import Model, { belongsTo, attr } from '@ember-data/model';
import { bool } from '@ember/object/computed';

export const operators = {
  include: 'include?',
  exclude: 'exclude?',
  equal: '==',
  notEqual: '!=',
};

export const types = {
  rateLimit: 'activity_rule_rate_limit',
};

export default Model.extend({
  type: attr('string'),
  not_matched_reason: attr('string'),
  // activity-rule-rate-limit condition
  max_matches: attr('number'),
  interval: attr('string'),
  interval_count: attr('number'),
  // activity-rule-after-integration-launch condition
  integration_type: attr('string'),
  // activity-rule-operator-comparison condition
  operator: attr('string'),

  // NOTE: this explicitly does not specify the attr type, because it can be
  // different based on condition
  /* eslint-disable-next-line ember/no-empty-attrs */
  value: attr(),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  account: belongsTo('account', { async: false, inverse: null }),
  activity_attribute_definition: belongsTo('activity-attribute-definition', {
    async: false,
    inverse: null,
  }),
  customer_attribute_definition: belongsTo('customer-attribute-definition', {
    async: false,
    inverse: null,
  }),

  isActivityCondition: bool('activity_attribute_definition'),
  isCustomerCondition: bool('customer_attribute_definition'),
});
