import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';
import validateSometimes from 'smile-admin/validators/sometimes';

export default {
  value: [
    validatePresence({ presence: true, description: 'Value' }),
    validateNumber({ positive: true, integer: true, description: 'Value' }),
  ],

  entitled_collection_ids: validateSometimes(
    validatePresence({
      presence: true,
      description: 'Collection',
    }),
    function () {
      return (
        this.get('sesh.hasShopifyPlatform') &&
        this.get('hasShopifyCollectionLimitation')
      );
    }
  ),

  entitled_product_variant_ids: validateSometimes(
    [
      validatePresence({
        presence: true,
        description: 'Product',
      }),
    ],
    function () {
      return this.get('isShopifyFreeProductType');
    }
  ),
};
