import Helper from '@ember/component/helper';
import numeral from 'numeral';
import { inject as service } from '@ember/service';

/**
 * Usage:
 *
 * Defaults
 * {{format-currency 1}} -> $1
 * {{format-currency 1.3}} -> $1.3
 *
 * Force no decimals (used for 1 point = $1 spent)
 * {{format-currency 1 format="0"}} -> $1
 *
 * Force decimals (used for 1 point = $1 spent)
 * {{format-currency 1 format="0,.00"}} -> $1.00
 */
export default Helper.extend({
  sesh: service(),

  compute([amount], { format }) {
    return formatCurrency(amount, this.get('sesh'), format);
  },
});

/**
 * Utility to format currency amount with optional specific numeral.js format.
 * @param  {int} amount               The currency amount
 * @param  {Service} sesh             This is our 'sesh' service, which needs to be passed in as we
 *                                    cant' inject it into utility functions.
 * @param  {string} format            Optional. A valid numeral.js format option.
 *                                    Default: '0,[.]00'.
 * @return {string}
 */
export function formatCurrency(amount, sesh, format) {
  format = format || '0,[.]00';
  amount = parseFloat(amount) || 0;

  let account = sesh.get('account');
  let currency = account.get('currency');
  let formattedAmount = numeral(amount).format(format);

  if (account.get('currencyDisplayType') === 'symbol') {
    let currencySymbol = currency.get('symbol');
    return currency.get('symbolFirst')
      ? `${currencySymbol}${formattedAmount}`
      : `${formattedAmount}${currencySymbol}`;
  }

  let currencyCode = currency.get('isoCode');
  return `${formattedAmount} ${currencyCode}`;
}
