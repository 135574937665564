import { helper } from '@ember/component/helper';
import numeral from 'numeral';

export function billingFormatPrice(amountCents, suffix = true) {
  let price = (amountCents || 0) / 100;
  let formattedPrice = `${numeral(price).format('$0,0[.]00')}`;
  if (suffix) {
    formattedPrice = `${formattedPrice} USD`;
  }

  return formattedPrice;
}

export default helper(function ([amountCents], { suffix }) {
  return billingFormatPrice(amountCents, suffix);
});
