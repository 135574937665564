/* import __COLOCATED_TEMPLATE__ from './placement.hbs'; */
import Component from '@ember/component';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  sesh: service(),

  tagName: '',

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {},

  isDesktopMode: equal('selectedTab', 'desktop'),

  isMobileModeAndMobileLauncherDisabled: computed(
    'isDesktopMode',
    'launcher.isVisibleOnMobile',
    function () {
      return !this.isDesktopMode && !this.get('launcher.isVisibleOnMobile');
    }
  ).readOnly(),

  actions: {
    updateMargin(displaySettingChangeset, customizablePropertyPath, newValue) {
      this.onChange(
        'displaySetting',
        displaySettingChangeset,
        customizablePropertyPath,
        `${newValue}px`
      );
    },

    updatePlacementProperty(displaySettingChangeset, value) {
      if (this.sesh.isOnReducedFreeBranding) {
        // Under reduced free branding, launcher position on mobile
        // is always pinned to the same value as on desktop. This is
        // handled by Core so we only need to update the desktop
        // position here.
        this.onChange(
          'displaySetting',
          displaySettingChangeset,
          'smileUiDesktopPosition',
          value
        );

        return;
      }

      const devicePosition = this.isDesktopMode
        ? 'smileUiDesktopPosition'
        : 'customizableSmileUiMobilePosition';

      this.onChange(
        'displaySetting',
        displaySettingChangeset,
        devicePosition,
        value
      );
    },
  },
});
