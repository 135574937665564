import { validatePresence } from 'ember-changeset-validations/validators';

export default {
  name: validatePresence({ presence: true, description: 'Action title' }),
  namePastTense: validatePresence({
    presence: true,
    description: 'Action title once completed',
  }),
  triggerType: validatePresence(true),
};
