/* import __COLOCATED_TEMPLATE__ from './home-card-stack.hbs'; */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { storageFor } from 'ember-local-storage';
import { inject as service } from '@ember/service';

@tagName('')
export default class HomeCardStackComponent extends Component {
  @service sesh;

  dismissedCards = [];

  @storageFor('sweet-tooth-session')
  localStorage;

  @computed('dismissedCards.[]')
  get shouldRender() {
    return this.dismissedCards.length !== this.homeCards.length;
  }

  hasCardBeenDismissed(type) {
    let {
      localStorage,
      sesh: { account },
    } = this;

    switch (type) {
      case 'add_points_program_type':
        return localStorage.get('hasDismissedAddProgramTypePointsCard');

      case 'add_referrals_program_type':
        return localStorage.get('hasDismissedAddProgramTypeReferralsCard');

      case 'guest_referrals_disabled_notice':
        return !account.showGuestReferralsDisabledNotice;

      case 'html_emails_deprecation_info':
        return !account.showHtmlEmailUpdatesCard;

      case 'referral_share_link':
        return localStorage.get('hasDismissedSendReferralLinksCard');

      default:
        return false;
    }
  }

  init() {
    super.init(...arguments);
    this.updateDismissedCards();
  }

  @action
  updateDismissedCards() {
    this.homeCards.forEach((card) => {
      if (this.hasCardBeenDismissed(card.type)) {
        if (!this.dismissedCards.includes(card)) {
          this.dismissedCards.push(card);
        }
      }
    });
  }
}
