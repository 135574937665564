import {
  validateNumber,
  validateFormat,
  validateLength,
  validatePresence,
} from 'ember-changeset-validations/validators';
import validateSometimes from 'smile-admin/validators/sometimes';
import { types as activityRuleTypes } from 'smile-admin/utils/activity-rule';
import { rewardValueTypes } from 'smile-admin/models/activity-rule';
import { facebookUrlWithPageMatcher } from 'smile-admin/utils/regex';

export default {
  multiplier: validateSometimes(
    validateNumber({
      gt: 0,
      integer: true,
      description: 'Points amount',
    }),
    function () {
      return this.get('reward_value_type') === rewardValueTypes.variable;
    }
  ),
  reward: {
    value: validateSometimes(
      validateNumber({
        gt: 0,
        integer: true,
        description: 'Points amount',
      }),
      function () {
        return this.get('reward_value_type') === rewardValueTypes.fixed;
      }
    ),
  },
  like_url: validateSometimes(
    validateFormat({
      regex: facebookUrlWithPageMatcher,
      message: 'Must be a valid Facebook page URL',
    }),
    function () {
      return this.get('type') === activityRuleTypes.facebookLike;
    }
  ),
  share_url: validateSometimes(
    validateFormat({
      type: 'url',
      description: 'Share URL',
      message: '{description} must be a valid URL',
    }),
    function () {
      return (
        [
          activityRuleTypes.facebookShare,
          activityRuleTypes.twitterShare,
        ].indexOf(this.get('type')) !== -1
      );
    }
  ),
  share_message: validateSometimes(
    validatePresence({ presence: true, description: 'Message' }),
    function () {
      return this.get('type') === activityRuleTypes.twitterShare;
    }
  ),
  username: [
    ...validateSometimes(
      [
        validateLength({ min: 1, max: 15 }),
        validateFormat({
          regex: /^[a-zA-Z0-9_]*$/,
        }),
      ],
      function () {
        /**
         * Twitter username rules https://support.twitter.com/articles/101299
         */
        return this.get('type') === activityRuleTypes.twitterFollow;
      }
    ),
    ...validateSometimes(
      [
        validateLength({ min: 1, max: 24 }),
        validateFormat({
          regex: /^[a-zA-Z0-9_.]*$/,
          message: 'Must be a valid TikTok username',
        }),
      ],
      function () {
        /**
         * TikTok username rules https://linktr.ee/blog/how-to-change-tiktok-username
         */
        return this.get('type') === activityRuleTypes.tiktokFollow;
      }
    ),
    ...validateSometimes(
      [
        validateLength({ min: 1, max: 30 }),
        validateFormat({
          /**
           * Validate Instagram username: max 30 chars, A-Z, a-z, 0-9, _ or .
           * Adapted from: http://blog.jstassen.com/2016/03/code-regex-for-instagram-username-and-hashtags/
           */
          regex:
            /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
        }),
      ],
      function () {
        return this.get('type') === activityRuleTypes.instagramFollow;
      }
    ),
  ],
};
