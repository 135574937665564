import Model, { belongsTo, attr } from '@ember-data/model';
import { bool, equal, alias, and } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const exchangeTypes = {
  fixed: 'fixed',
  variable: 'variable',
};

export default Model.extend({
  is_enabled: attr('boolean'),
  exchange_description: attr('string'),
  exchange_description_template: attr('string'),
  exchange_type: attr('string'),
  template_variables: attr('object'),

  // Properties for exchange_type == 'fixed'
  points_price: attr('number'),

  // Properties for exchange_type == 'variable'
  variable_points_step: attr('number'),
  variable_points_step_reward_value: attr('number'),
  variable_points_min: attr('number'),
  variable_points_max: attr('number'),

  /**
   * TODO: I switched these from 'utc' to 'date' because our custom
   * utc transform breaks sorting via the native ember way:
   * http://emberjs.com/api/classes/Ember.computed.html#method_sort
   *
   * The utc transform has a blog with it - perhaps we can figure out
   * how to give it sorting ability
   */
  created_at: attr('date'),
  updated_at: attr('date'),

  account: belongsTo('account', { async: false, inverse: null }),
  reward: belongsTo('reward', { async: false, inverse: null }),
  rewardProgram: belongsTo('reward-program', { async: false, inverse: null }),

  hasMin: bool('variable_points_min'),
  hasMax: bool('variable_points_max'),
  hasFixedExchangeType: equal('exchange_type', exchangeTypes.fixed),
  hasVariableExchangeType: equal('exchange_type', exchangeTypes.variable),
  // NOTE: This is required to compile the `exchange_description_template`, don't remove
  is_fixed_exchange: alias('hasFixedExchangeType'),
  min_and_max: and('hasMin', 'hasMax'),

  enable: memberAction({
    path: 'enable',
    after: serializeAndPush,
  }),
  disable: memberAction({
    path: 'disable',
    after: serializeAndPush,
  }),
  purchase: memberAction({
    path: 'purchase',
    type: 'post',
    after: serializeAndPush,
  }),
});
