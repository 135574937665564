import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    /**
     * Embedded customer objects
     */
    activity: { serialize: 'id', deserialize: 'records' },
    customer: { serialize: 'id', deserialize: 'records' },
    points_transaction: { serialize: 'id', deserialize: 'records' },
  },
});
