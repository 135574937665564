import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  template_variables: attr('object'),
  referral_sharing_option_type: attr('string'),

  email_title_template: attr('string'),
  email_body_template: attr('string'),
  supports_preview: attr('boolean'),

  referral_sharing_option_definition_listing: belongsTo(
    'referral-sharing-option-definition-listing',
    { async: false, inverse: 'referral_sharing_option_definition' }
  ),
});
