import Model, { attr } from '@ember-data/model';

/**
 * Example
 * {
 *   "name":"(GMT-08:00) Pacific Time (US & Canada)",
 *   "iana":"America/Los_Angeles",
 * }
 */
export default Model.extend({
  name: attr('string'),
  iana: attr('string'),
});
