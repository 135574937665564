import { modifier } from 'ember-modifier';
import { assert } from '@ember/debug';

interface DataAttrsArgs {
  attrs: Record<string, string | number | boolean>;
}

export default modifier((element: HTMLElement, _: [], named: DataAttrsArgs) => {
  assert(
    'dataAttrs can only be installed on HTML elements!',
    element instanceof HTMLElement,
  );

  const { attrs } = named;
  assert(
    `'attrs' argument to 'dataAttrs' must be an object, but was ${typeof attrs}`,
    typeof attrs === 'object' && attrs !== null,
  );

  // Iterate over the keys in the attrs object and set the data attributes
  Object.keys(attrs).forEach((key) => {
    const value = attrs[key];
    element.setAttribute(`${key}`, String(value));
  });
});
