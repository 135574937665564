import { A } from '@ember/array';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

export default function groupBy(items, property) {
  items = items || [];

  return items.reduce((groups, item) => {
    let value = get(item, property);
    let group = groups.find((group) => group.value === value);

    if (isPresent(group)) {
      get(group, 'items').push(item);
    } else {
      group = { property: property, value: value, items: [item] };
      groups.push(group);
    }

    return groups;
  }, A());
}
