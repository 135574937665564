import {
  validatePresence,
  validateNumber,
} from 'ember-changeset-validations/validators';
import { types } from 'smile-admin/models/condition';
import validateSometimes from 'smile-admin/validators/sometimes';

export default {
  max_matches: validateSometimes(
    [
      validatePresence({ presence: true, description: 'Earning limit' }),
      validateNumber({
        gte: 1,
        integer: true,
        description: 'Earning limit',
        message: '{description} must be at least 1',
      }),
    ],
    function () {
      return this.get('type') === types.rateLimit;
    }
  ),
};
