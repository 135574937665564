import Service from '@ember/service';
import { themeDefault } from '@shopify/polaris-tokens';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  getBreakpointsQueryEntries,
  getMatches,
  navigationBarCollapsed,
  type BreakpointsMatches,
} from '../utilities/breakpoints.ts';

export default class PolarisService extends Service {
  @tracked breakpoints: BreakpointsMatches = {} as BreakpointsMatches;
  @tracked isNavigationCollapsed: boolean = false;

  private mediaQueryLists: MediaQueryList[] = [];

  @action
  updateBreakpoints() {
    this.breakpoints = getMatches();
  }

  @action
  updateIsNavigationCollapsed() {
    this.isNavigationCollapsed = navigationBarCollapsed().matches;
  }

  constructor() {
    super();

    this.updateBreakpoints();
    this.updateIsNavigationCollapsed();
    this.setupListeners();
  }

  setupListeners() {
    const breakpointsQueryEntries = getBreakpointsQueryEntries(
      themeDefault.breakpoints,
    );

    this.mediaQueryLists = breakpointsQueryEntries.map(([, query]) =>
      window.matchMedia(query),
    );

    this.mediaQueryLists.forEach((mql) => {
      if (mql.addListener) {
        mql.addListener(this.updateBreakpoints);
      } else {
        mql.addEventListener('change', this.updateBreakpoints);
      }
    });

    window.addEventListener('resize', this.updateIsNavigationCollapsed);
  }

  willDestroy() {
    this.mediaQueryLists.forEach((mql) => {
      if (mql.removeListener) {
        mql.removeListener(this.updateBreakpoints);
      } else {
        mql.removeEventListener('change', this.updateBreakpoints);
      }
    });

    window.removeEventListener('resize', this.updateIsNavigationCollapsed);
  }
}
