import Model, { attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';

const salesChannelTypes = {
  onlineStore: 'online_store',
  pointsOfSale: 'point_of_sale',
};

export default Model.extend({
  name: attr('string'),
  type: attr('string'),
  key: attr('string'),
  imageUrl: attr('string'),

  // BigCommerce-specific fields.
  codeSnippet: attr('string'),

  // Online store sales channel fields.
  customerSignupUrl: attr('string'),
  customerLoginUrl: attr('string'),

  isOnlineStoreType: equal('type', salesChannelTypes.onlineStore).readOnly(),
  isPointOfSaleType: equal('type', salesChannelTypes.pointsOfSale).readOnly(),
});
