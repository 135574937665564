import { helper } from '@ember/component/helper';
import { combineDateAndTime } from 'smile-admin/utils/moment';

// Calculate the start time for a campaign schedule.
// Takes an optional `now` parameter to return when an immediate start is selected,
// otherwise `null` is returned to match our internal representation of "start now".
export function computeStartTime({ scheduleFormData, now }) {
  const { startsImmediately, startDate, startTime } = scheduleFormData || {};

  if (startsImmediately) {
    return now || null;
  }

  return combineDateAndTime({
    date: startDate,
    time: startTime,
  });
}

export default helper((_, { scheduleFormData, now }) =>
  computeStartTime({ scheduleFormData, now })
);
