import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { bool } from '@ember/object/computed';

export const types = {
  referralSharingLinkEmail: 'referral_sharing_link_email',
};

export default Model.extend({
  name: attr('string'),
  type: attr('string'),

  supportsProgress: attr('boolean'),
  supportsRollback: attr('boolean'),
  supportsCancel: attr('boolean'),

  isAdminMigration: attr('boolean'),

  startedAt: attr('date'),
  finishedAt: attr('date'),
  finishedStatus: attr('string'),

  rolledBackAt: attr('date'),

  progressMax: attr('number'),
  progressValue: attr('number'),
  statusDescription: attr('string'),

  downloadableFileUrl: attr('string'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  isStarted: bool('startedAt').readOnly(),
  isFinished: bool('finishedAt').readOnly(),

  isRunning: computed('isStarted', 'isFinished', function () {
    return this.isStarted && !this.isFinished;
  }).readOnly(),

  isSyncing: computed('finishedStatus', function () {
    return this && !this.get('finishedStatus');
  }).readOnly(),

  showSyncProgress: computed(function () {
    return this.finishedStatus === 'succeeded' ? false : true;
  }),
});
