/**
 * Smile's defaults for the Ace editor.
 *
 * Contains all the currently possible option configurations.
 * For a list of configuration options for the Ace editor see
 * https://github.com/ajaxorg/ace/wiki/Configuring-Ace
 *
 */
export const defaultOptions = {
  // Editor options
  selectionStyle: undefined,
  highlightActiveLine: undefined,
  highlightSelectedWord: undefined,
  readOnly: undefined,
  cursorStyle: undefined,
  mergeUndoDeltas: undefined,
  behavioursEnabled: undefined,
  wrapBehavioursEnabled: undefined,
  autoScrollEditorIntoView: undefined,
  copyWithEmptySelection: undefined,
  useSoftTabs: undefined,
  navigateWithinSoftTabs: undefined,
  enableMultiselect: undefined,

  // Renderer options
  hScrollBarAlwaysVisible: undefined,
  vScrollBarAlwaysVisible: undefined,
  highlightGutterLine: undefined,
  animatedScroll: undefined,
  showInvisibles: undefined,
  showPrintMargin: undefined,
  printMarginColumn: undefined,
  printMargin: undefined,
  fadeFoldWidgets: undefined,
  showFoldWidgets: undefined,
  showLineNumbers: undefined,
  showGutter: undefined,
  displayIndentGuides: undefined,
  fontSize: undefined,
  fontFamily: undefined,
  maxLines: 100,
  minLines: 0,
  scrollPastEnd: undefined,
  fixedWidthGutter: undefined,
  theme: undefined,

  // MouseHandler options
  scrollSpeed: undefined,
  dragDelay: undefined,
  dragEnabled: undefined,
  focusTimout: undefined,
  tooltipFollowsMouse: undefined,

  // Session options
  firstLineNumber: undefined,
  overwrite: undefined,
  newLineMode: undefined,
  useWorker: false,
  tabSize: undefined,
  wrap: true,
  foldStyle: undefined,
  mode: 'ace/mode/handlebars',

  // Editor options defined by extensions
  // Requires ext-language_tools.js
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  enableSnippets: true,
  // Requires ext-emmet.js and the emmet library
  enableEmmet: undefined,
  // Requires ext-elastic_tabstops_lite.js
  useElasticTabstops: undefined,
};

export const configOptions = Object.freeze(Object.keys(defaultOptions));
