import { validatePresence } from 'ember-changeset-validations/validators';
import { isPresent } from '@ember/utils';
import validateSometimes from 'smile-admin/validators/sometimes';

export default {
  customizedIconUrl: validateSometimes(
    validatePresence({ presence: true, description: 'Image' }),
    function () {
      return (
        !this.get('customizableUsesDefaultIcon') &&
        !isPresent(this.get('customizedIconTempS3Key'))
      );
    }
  ),
  customizedIconTempS3Key: validateSometimes(
    validatePresence({ presence: true, description: 'Image' }),
    function () {
      return (
        !this.get('customizableUsesDefaultIcon') &&
        !isPresent(this.get('customizedIconUrl'))
      );
    }
  ),
};
