import { singularize, pluralize } from 'ember-inflector';
import {
  getModelName,
  getStoreFromRecord,
} from 'smile-admin/utils/models/build-url';

export function serializeAndPush(response) {
  let store = getStoreFromRecord(this);

  // Response is always of the form { underscored_model_name(s): Object | Array }
  // so we grab the model based on response (because some endpoints return a different resource)
  // TODO stop returning a different resource, most of the times it's just an association
  let modelNameUnderscored = singularize(Object.keys(response)[0]);
  let recordClass = store.modelFor(modelNameUnderscored);
  let modelName = getModelName(recordClass);
  let serializer = store.serializerFor(modelName);

  let normalized;

  if (response.hasOwnProperty(pluralize(modelNameUnderscored))) {
    let doc = response;
    normalized = serializer.normalizeArrayResponse(
      store,
      recordClass,
      doc,
      null,
      'findAll'
    );
  } else {
    let doc = response;
    normalized = serializer.normalizeSingleResponse(
      store,
      recordClass,
      doc,
      `${doc[modelNameUnderscored].id || '(unknown)'}`,
      'findRecord'
    );
  }

  return store.push(normalized);
}
