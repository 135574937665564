import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    webhooks: { serialize: 'records', deserialize: 'records' },
    rewardDefinitionListings: { serialize: 'records', deserialize: 'records' },
    activityRuleDefinitionListings: {
      serialize: 'records',
      deserialize: 'records',
    },
    activityRules: { serialize: false, deserialize: 'records' },
    rewards: { serialize: false, deserialize: 'records' },
    salesChannels: { serialize: 'records', deserialize: 'records' },
    app: { serialize: 'id', deserialize: 'records' },
    connectedAccount: { serialize: 'id', deserialize: 'records' },
    lastInitialSyncTask: { serialize: 'id', deserialize: 'records' },
    lastExcludeCustomersByTagsTask: {
      serialize: false,
      deserialize: 'records',
    },
    customerIdentityOrigin: { serialize: false, deserialize: 'records' },
    oauth2AccessToken: { serialize: false, deserialize: 'records' },
    totalOrders28dBeforeInstall: {
      key: 'total_orders_28d_before_install',
    },
  },
});
