import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default Helper.extend({
  sesh: service(),

  compute([value]) {
    return pointsLabel(value, this.get('sesh'));
  },
});

/**
 * Helper utility that returns the account's points label singular or plural, based on the `value` param.
 * By default, if no `value` is passed, returns the plural version of points label.
 *
 * @param  {Number}   value   Optional. Amount based on which it will return singular (==1) or plural points label.
 * @param  {Service}  sesh    Our `sesh` service which knows about the account's points labels.
 * @return {String}           The singular or plural points label.
 */
export function pointsLabel(value, sesh) {
  value = value || 0;

  let pointsLabel;
  if (value === 1) {
    pointsLabel = sesh.get('pointsLabelSingular');
  } else {
    pointsLabel = sesh.get('pointsLabelPlural');
  }

  // Point(s) is not a proper noun so the first letter should not be capitalized
  if (['Point', 'Points'].includes(pointsLabel)) {
    pointsLabel = pointsLabel.toLowerCase();
  }

  return pointsLabel;
}
