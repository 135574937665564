import validateHandlebars from 'smile-admin/validators/handlebars';
import validateSmileUiDescriptionField from 'smile-admin/validators/smile-ui-description-field';

export default {
  customizedPointsExplainerDescription: [
    validateSmileUiDescriptionField(),
    validateHandlebars({
      allowBlank: true,
      templateDataKey:
        'dummyDataForTemplateValidations.pointsExplainerDescription',
      description: 'Description',
    }),
  ],

  customizedReferralLandingClaimDescriptionTemplate: [
    validateSmileUiDescriptionField(),
    validateHandlebars({
      allowBlank: true,
      templateDataKey:
        'dummyDataForTemplateValidations.referralLandingClaimDescriptionTemplate',
      description: 'Description',
    }),
  ],

  customizedReferralsSummaryDescriptionTemplate: [
    validateSmileUiDescriptionField(),
    validateHandlebars({
      allowBlank: true,
      templateDataKey:
        'dummyDataForTemplateValidations.referralsSummaryDescriptionTemplate',
      description: 'Description',
    }),
  ],

  customizedJoinProgramProgramDescription: validateSmileUiDescriptionField(),
  customizedReferralsExplainerDescription: validateSmileUiDescriptionField(),
  customizedVipProgramTiersDescription: validateSmileUiDescriptionField(),
  customizedBirthdayFormDescription: validateSmileUiDescriptionField(),
  customizedReferralDetailDescription: validateSmileUiDescriptionField(),
  customizedSignInFormDescription: validateSmileUiDescriptionField(),
};
