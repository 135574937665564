/* eslint-disable ember/no-new-mixins */
import { defineProperty, computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import HandlebarsCompiler from 'smile-admin/objects/handlebars-compiler';

export function buildTemplateCompilers(options) {
  normalizeOptions(options);

  let HandlebarsTemplateFactory = Mixin.create({
    init() {
      this._super(...arguments);

      Object.keys(options).forEach((attr) => {
        let option = options[attr];
        let {
          compilerPropertyName,
          compiledPropertyName,
          templateName,
          templateVariables,
          context,
        } = option;

        defineProperty(
          this,
          compilerPropertyName,
          computed(function () {
            let compilerContext =
              typeof context === 'string' ? this.get(context) : this;
            let template = compilerContext.get(templateName);

            return HandlebarsCompiler.create({
              template,
              templateVariables,
              // The context is our actual model or an association of the model
              content: compilerContext,
            });
          })
        );

        defineProperty(
          this,
          compiledPropertyName,
          computed(`${compilerPropertyName}.compiledTemplate`, function () {
            return this.get(`${compilerPropertyName}.compiledTemplate`);
          })
        );
      });
    },

    destroy() {
      this._super(...arguments);

      Object.keys(options).forEach((option) => {
        let { compilerPropertyName } = option;
        this.get(compilerPropertyName).destroy();
      });
    },
  });

  // Label mixin under a named scope for Ember Inspector
  // HandlebarsTemplateFactory[Ember.NAME_KEY] = 'HandlebarsTemplate';

  return HandlebarsTemplateFactory;
}

/**
 * Normalizes options.
 *
 * By default, the compiled template CP will be named as the key in the templates
 * definitions with `Compiled` appended to it.
 * For ex: `customizedNameTemplateCompiled` from below
 *
 *    const HandlebarsTemplates = buildTemplateCompilers({
        customizedNameTemplate: {
        templateName: 'customizedNameTemplate',
        templateVariables: 'reward_definition.template_variables',
        context: this,
      }
 */
function normalizeOptions(options) {
  let compilableAttrs = Object.keys(options);

  compilableAttrs.forEach((attr) => {
    let templateDefinition = options[attr];
    templateDefinition.compiledPropertyName =
      templateDefinition.compiledPropertyName || `${attr}Compiled`;
    templateDefinition.compilerPropertyName =
      templateDefinition.compilerPropertyName || `${attr}Compiler`;

    options[attr] = templateDefinition;
  });
}
