import DataLoaderComponent from '@smile-io/ember-smile-core/components/data-loader';
import { inject as service } from '@ember/service';
import { computed, set } from '@ember/object';
import { task } from 'ember-concurrency';

export default DataLoaderComponent.extend({
  ajax: service(),
  store: service(),

  /**
   * Flag to determine which endpoint data should be retrieved from.
   * If truthy, we'll use `/customers/search`, otherwise it'll be
   * `/customers`. By default we'll use the search endpoint if
   * `searchTerm` is present, but this can be overridden by the
   * outside world if desired.
   *
   * @property shouldUseSearchEndpoint
   * @type {Boolean}
   * @public
   */
  shouldUseSearchEndpoint: computed('searchTerm', '_shouldUseSearchEndpoint', {
    get() {
      if (this._shouldUseSearchEndpoint) {
        return this._shouldUseSearchEndpoint;
      }

      return !!this.searchTerm;
    },

    set(key, value) {
      set(this, '_shouldUseSearchEndpoint', !!value);
      return value;
    },
  }),

  /**
   * @public
   */
  autoload: true,

  modelName: 'customer',

  /**
   * Overridden data retrieval task to handle
   * searching for customers when a search term
   * is present.
   */
  _fetchData: task(function* () {
    if (this.shouldUseSearchEndpoint) {
      return yield this.searchCustomers.perform();
    }

    // Perform our super's underlying task.
    return yield this.makeStoreRequest.perform();
  })
    .restartable()
    .evented(),

  searchCustomers: task(function* () {
    let { params, searchTerm } = this;
    let data = Object.assign({}, params, {
      q: searchTerm,
    });

    let { store, ajax } = this;
    let results = yield ajax.request(`/v1/customers/search`, { data });

    store.pushPayload('customer', results);
    let resultsIds = results.customers.map((customer) => customer.id);
    // Normalize results while still maintaining their order
    let normalizedResults = resultsIds.map((customerId) =>
      store.peekRecord('customer', customerId)
    );

    return normalizedResults;
  })
    .restartable()
    .evented(),

  didReceiveAttrs() {
    if (this.autoload) {
      this._super(...arguments);
    }
  },
});
