/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { resolve } from 'rsvp';

export default Mixin.create({
  alert: service(),
  errorHandler: service(),

  /** @public */
  rewardProgram: null,

  /** @public */
  onEnableRewardProgram: resolve,

  /** @public */
  onDisableRewardProgram: resolve,

  enableRewardProgram: task(function* () {
    try {
      yield this.get('onEnableRewardProgram')();
      this.alert.info(`${this.rewardProgram.get('name')} program activated`);
    } catch (err) {
      // TODO: check if we get a 422 (DS.InvalidError) back for referrals
      // - if we do, this should be a warning not an error.
      // We can't do this until we've pulled the new Ember Data errors in.
      this.errorHandler.handle(err);
    }
  }).drop(),

  disableRewardProgram: task(function* () {
    try {
      yield this.get('onDisableRewardProgram')();
      this.alert.info(`${this.rewardProgram.get('name')} program deactivated`);
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }).drop(),
});
