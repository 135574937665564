/* import __COLOCATED_TEMPLATE__ from './upgrade-trigger-provider.hbs'; */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { underscore, capitalize } from '@ember/string';
import { tagName } from '@ember-decorators/component';
import { dropTask } from 'ember-concurrency';
import { slugs } from 'smile-admin/models/billing-subscription-template';

const adaptersToTrackUpgradeTrigger = ['GoogleTagManager'];

@tagName('')
export default class UpgradeTriggerProvider extends Component {
  @service billing;
  @service featureRollouts;
  @service metrics;
  @service router;
  @service sesh;

  /**
   * Action triggered. Required only when you really need to overwrite the action.
   * Optional.
   *
   * @type {Promise|Function}
   * @default null
   * @public
   */
  onTriggerUpgrade = null;

  /**
   * The new billing system's required plan name
   *
   * @type {String}
   * @default 'Growth'
   * @public
   */
  planName = 'Growth';

  /**
   * Tracking event data.
   *
   * @typedef trackingEvent
   * @type {Object}
   * @property {String} upgradeTriggerName  The trigger name that triggered the tracking event.
   *                                        Used to uniquely identify from where the upgrade was triggered.
   * @property {String} pageName            The page that triggered the tracking event. Used to uniquely
   *                                        identify the page from where the upgrade was triggered.
   * @property {String} [event]             The tracking event name.
   *                                        You shouldn't really need to change this.
   * @public
   */
  trackingEvent = {};

  /**
   * The feature we are trying to get access to by upgrading. The minimum billing
   * plan is retrieved from the API based on this feature. This replaces planSlug
   * and planName going forward.
   *
   * @type {String}
   * @default null
   * @public
   */
  forFeature = null;

  /**
   * The new billing system's required plan slug
   *
   * @type {String}
   * @public
   */
  @computed('_planSlug')
  get planSlug() {
    if (this._planSlug) {
      return this._planSlug;
    }

    return slugs.growth;
  }

  set planSlug(value) {
    this.set('_planSlug', value);
  }

  /**
   * True, when we show upgrade modal to the merchant (new billing only).
   *
   * @type {Boolean}
   * @default false
   * @private
   */
  showUpgradeModal = false;

  /**
   * By default, the subscription template is only loaded once the upgrade modal is opened.
   * However, setting this to true will pre-fetch the subscription template so that it's available
   * ealier for dynamic content.
   */
  shouldPrefetchSubscription = false;

  init() {
    super.init(...arguments);

    if (this.shouldPrefetchSubscription) {
      this.fetchSubscriptionTemplate.perform();
    }
  }

  // Maintain support for dynamic CTA text until we migrate all triggers to use forFeature
  @computed('planName', '_triggerLabel')
  get triggerLabel() {
    if (this._triggerLabel) {
      return this._triggerLabel;
    } else if (this.forFeature) {
      return 'Upgrade';
    } else {
      return `Upgrade to ${this.planName}`;
    }
  }

  set triggerLabel(value) {
    this.set('_triggerLabel', value);
  }

  @dropTask
  *triggerUpgrade() {
    this.trackUpgradeTriggerEvent();
    if (isPresent(this.onTriggerUpgrade)) {
      yield this.onTriggerUpgrade();
    } else {
      if (!this.subscriptionTemplate) {
        yield this.fetchSubscriptionTemplate.perform();
      }

      this.set('showUpgradeModal', true);
    }
  }

  @dropTask
  *fetchSubscriptionTemplate() {
    if (isPresent(this.forFeature)) {
      yield this._fetchSubscriptionTemplateForFeature.perform();
    } else {
      yield this._fetchSubscriptionForSlug.perform();
    }
  }

  @dropTask
  *_fetchSubscriptionTemplateForFeature() {
    let subscriptionTemplates =
      yield this.billing.querySubscriptionTemplates.perform({
        for_feature: this.forFeature,
      });
    this.set('subscriptionTemplate', subscriptionTemplates[0]);

    yield this.billing.fetchSubscriptionTemplates.perform();

    let yearlySubscriptionTemplate = this.billing.subscriptionTemplates.find(
      (template) =>
        template.slug === this.subscriptionTemplate.slug && template.isYearly
    );

    this.set('yearlySubscriptionTemplate', yearlySubscriptionTemplate);
  }

  @dropTask
  *_fetchSubscriptionForSlug() {
    yield this.billing.fetchSubscriptionTemplates.perform();

    let subscriptionTemplate = this.billing.subscriptionTemplates.find(
      (template) => template.slug === this.planSlug
    );
    this.set('subscriptionTemplate', subscriptionTemplate);
  }

  trackUpgradeTriggerEvent() {
    let eventData = {
      event: 'Core - Upgrade trigger clicked',
    };
    Object.keys(this.trackingEvent).forEach((key) => {
      eventData[underscore(key)] = this.trackingEvent[key];
    });

    adaptersToTrackUpgradeTrigger.forEach((adapter) => {
      this.metrics.trackEvent(adapter, eventData);
    });
  }

  @action
  handleSubscriptionConfirmation(provider, subscriptionTemplate) {
    if (subscriptionTemplate.hasEnterpriseSlug) {
      return this.billing.contactSales(
        'I’m looking for more info on the Enterprise plan.'
      );
    }

    return provider.tasks.subscribeToSubscriptionTemplate.perform(
      subscriptionTemplate
    );
  }

  @action
  contactSales() {
    this.billing.contactSales(...arguments);
  }

  @action
  trackUpgrade(subscription) {
    this.billing.trackEvent(
      `Billing - ${subscription.basePlanName} modal upgrade button clicked`,
      { 'Billing Provider': capitalize(subscription.type) }
    );
  }

  @action
  transitionToBilling() {
    this.router.transitionTo('settings.billing');
  }
}
