import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { allowedRoutesForRestrictedAccessOverLimit } from '../router';
import { storageFor } from 'ember-local-storage';

export default class MainAppRoute extends Route {
  @service billing;
  @service errorHandler;
  @service router;
  @service sesh;
  @service store;

  @storageFor('sweet-tooth-session') localStorage;

  queryParams = {
    chargeId: {
      replace: true,
    },
    bypassUpgradeScreen: {
      replace: true,
    },
  };

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.sesh.attemptUpgradeScreenBypass(
      transition.to.queryParams?.bypass_upgrade_screen === 'true'
    );
  }

  setupController(controller) {
    super.setupController(...arguments);

    let { chargeId } = controller;
    // If we have a chargeId (meaning we're redirected back from Shopify Payments) trigger finishing
    // the Shopify payment flow
    if (chargeId) {
      this.billing.processShopifyPaymentsCallback.perform(chargeId);

      // We reset the query param right here (as early as possible), to avoid re-running the task in
      // case merchant refreshes or something
      schedule('afterRender', this, function () {
        set(controller, 'chargeId', null);
      });
    }
  }

  redirect(model, transition) {
    // If the quiz is not complete, redirect there
    if (!this.sesh.isOnboardingComplete) {
      return this.router.transitionTo('start');
    }

    if (this.localStorage.get('lockedPendingPayment')) {
      return this.router.replaceWith('restricted-access');
    }

    if (
      this.localStorage.get('shouldSeeLockoutScreen') &&
      (!allowedRoutesForRestrictedAccessOverLimit.includes(
        transition.targetName
      ) ||
        (transition.targetName === 'settings.billing.index' &&
          this.sesh.improveBillingUsability))
    ) {
      return this.router.replaceWith('restricted-access-over-limit');
    }
  }

  activate() {
    super.activate(...arguments);

    this.controllerFor('application').enableNav();
    this.send('enableTopBar');
  }
}
