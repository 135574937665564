import Model, { belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import { memberAction } from 'ember-api-actions';

export const customActivityDefinitionType = 'custom';

export default Model.extend({
  activityType: attr('string'),
  type: attr('string'),
  name: attr('string'),
  nameImperative: attr('string'),
  namePastTense: attr('string'),
  description: attr('string'),
  imageUrl: attr('string'),

  // For Custom ones only
  defaultImageUrl: attr('string'),
  customizedDefaultImageUrl: attr('string'),
  customizedDefaultImageTempS3Key: attr('string'),

  // Shopify Flow Action only
  triggerType: attr('string'),
  isFlowBeta: attr('boolean'),

  account: belongsTo('account', { async: false, inverse: null }),
  app: belongsTo('app', { async: false, inverse: 'activityDefinitions' }),
  newIntegration: belongsTo('new-integration', { async: false, inverse: null }),

  isCustomType: equal('type', customActivityDefinitionType),

  removeImage: memberAction({
    path: 'remove_image',
    after: serializeAndPush,
  }),
});
