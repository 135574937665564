/* import __COLOCATED_TEMPLATE__ from './program-health.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { formatCurrency } from 'smile-admin/helpers/format-currency';
import { firstOrderActivityRule } from 'smile-admin/utils/activity-rule';
import { exchangeTypes } from 'smile-admin/models/points-product';

export default class ProgramHealthStartGuideStep extends Component {
  @service sesh;
  @service router;
  @service utils;

  get items() {
    return [
      {
        title: 'Can customers earn?',
        description:
          'Merchants who add at least 3 ways to earn have the most success with Smile.',
        isLoading: this.args.isFetchingEnabledActivityRules,
        isComplete: this.args.enabledActivityRules?.length >= 3,
        actionAccessibilityLabel:
          'Navigate to Points program to add a new earning action',
        onClick: () =>
          this.router.transitionTo('reward-programs.points.actions.index', {
            queryParams: {
              select_activity_rule: true,
            },
          }),
      },
      {
        title: 'Can customers redeem?',
        description:
          'At least 3 rewards incentivizes customers to redeem points sooner and leads to repeat sales.',
        isLoading: this.args.isFetchingEnabledPointsProducts,
        isComplete: this.args.enabledPointsProducts?.length >= 3,
        actionAccessibilityLabel:
          'Navigate to Points program to add a new reward',
        onClick: () =>
          this.router.transitionTo('reward-programs.points.rewards.index', {
            queryParams: {
              select_reward: true,
            },
          }),
      },
      {
        title: 'Do you give between 5 and 10% back?',
        description: `Customers can easily see value when offering 5 to 10 points per ${formatCurrency(
          1,
          this.sesh
        )} spent.`,
        isLoading:
          this.args.isFetchingEnabledActivityRules ||
          this.args.isFetchingEnabledPointsProducts,
        isComplete: this.isGiveBackStepComplete,
        isDisabled: !this.orderRule,
        disabledTooltipText:
          "The 'Place an order' earning rule must be active for this checklist item to be enabled",
        actionAccessibilityLabel:
          'Open help article about Points program configuration recommendations in new window',
        onClick: () =>
          this.utils.openExternalLink(
            'https://help.smile.io/en/articles/5941093-recommendations-for-optimized-points-and-rewards'
          ),
      },
    ];
  }

  get orderRule() {
    const { enabledActivityRules } = this.args;

    if (!enabledActivityRules) {
      return null;
    }
    return firstOrderActivityRule(enabledActivityRules);
  }

  get isGiveBackStepComplete() {
    const { enabledPointsProducts } = this.args;

    if (!enabledPointsProducts) {
      return false;
    }

    if (!this.orderRule) {
      return false;
    }

    return enabledPointsProducts.some((pointsProduct) => {
      const rewardingRate = this.getRewardingRate(pointsProduct);
      return rewardingRate >= 0.05 && rewardingRate <= 0.1;
    });
  }

  getRewardingRate(pointsProduct) {
    const { multiplier: actionMultiplier, step: actionStep } = this.orderRule;

    const rewardPointsStep =
      pointsProduct.exchange_type === exchangeTypes.variable
        ? pointsProduct.variable_points_step
        : pointsProduct.points_price;

    const rewardPointsStepRewardValue =
      pointsProduct.exchange_type === exchangeTypes.variable
        ? pointsProduct.variable_points_step_reward_value
        : pointsProduct.reward.value;

    return (
      (actionMultiplier * rewardPointsStepRewardValue) /
      (rewardPointsStep * actionStep)
    );
  }
}
