import Model, { belongsTo, attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';

export default Model.extend({
  quantity: attr('number'),

  createdAt: attr('date'),
  updatedAt: attr('date'),

  newBillingPlan: belongsTo('new-billing-plan', {
    async: false,
    inverse: null,
  }),

  // The `main` subscription item is the one having the new-billing-plan's billing-product
  // `type` equal to `plan`
  isBaseItem: readOnly('newBillingPlan.billingProduct.isPlanType'),
  isAppsAddOnItem: readOnly('newBillingPlan.billingProduct.isAppsAddOnType'),
  isMembersAddOnItem: readOnly(
    'newBillingPlan.billingProduct.isMembersAddOnType'
  ),
  isOrdersAddOnItem: readOnly(
    'newBillingPlan.billingProduct.isOrdersAddOnType'
  ),
});
