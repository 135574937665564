import ApplicationSerializer from 'smile-admin/serializers/application';

const onlyDeserialize = { serialize: false, deserialize: 'records' };

export default ApplicationSerializer.extend({
  attrs: {
    advocateCustomer: onlyDeserialize,
    friendCustomer: onlyDeserialize,
    advocateRewardFulfillment: onlyDeserialize,
    friendTrackingRewardFulfillment: onlyDeserialize,
    goalOrder: onlyDeserialize,
  },
});
