import ApplicationAdapter from './application';
import { isPresent } from '@ember/utils';

export default ApplicationAdapter.extend({
  /**
   * NOTE: This method is _private_, but at this point there's no better way
   * of adding a custom header on the fly only for a specific request.
   * This should be relatively safe, `ember-simple-auth` also does this, but
   * make sure to check release notes when updating `ember-data`
   */
  ajaxOptions(url, type, options) {
    let hash = this._super(...arguments);

    let { data: { adapterOptions } = {} } = options;
    let { headers } = adapterOptions || {};
    headers = headers || {};

    if (isPresent(adapterOptions)) {
      delete options.data.adapterOptions;
    }

    Object.keys(headers).forEach(
      (header) => (hash.headers[header] = headers[header])
    );

    return hash;
  },
});
