/* import __COLOCATED_TEMPLATE__ from './smile-ui-misconfiguration.hbs'; */
import Component from '@ember/component';
import { capitalize } from '@ember/string';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  sesh: service(),

  router: service(),

  tagName: '',

  platformType: readOnly('sesh.account.platformType'),

  learnMoreUrl: computed('platformType', function () {
    let learnMoreUrlPath = '';

    learnMoreUrlPath = this.get('sesh.platformIntegration.isStencilEnabled')
      ? 'bigcommerce/applying-smile-on-bigcommerce-with-stencil'
      : 'bigcommerce/applying-smile-on-bigcommerce-with-blueprint';

    return `https://help.smile.io/apps-and-platforms/${learnMoreUrlPath}`;
  }),

  secondaryCtaEvent: computed('platformType', function () {
    let platformType = this.get('platformType');
    let platformTypeFormatted = '';

    if (platformType === 'bigcommerce') {
      // Determine if they are on Stencil
      platformTypeFormatted =
        this.get('sesh.platformIntegration.isStencilEnabled') === true
          ? `${capitalize(platformType)} Stencil`
          : `${capitalize(platformType)} Blueprint`;
    } else {
      platformTypeFormatted = capitalize(platformType);
    }

    return `KB - Apply Smile on ${platformTypeFormatted} clicked`;
  }),

  actions: {
    transitionToPlatformSettings() {
      this.router.transitionTo('settings.platform', {
        queryParams: { showDetailsOnLoad: true },
      });
    },
  },
});
