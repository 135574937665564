import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computed, observer } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import {
  alias,
  bool,
  equal,
  filterBy,
  or,
  readOnly,
} from '@ember/object/computed';
import { isNone } from '@ember/utils';
import { task } from 'ember-concurrency';
import { storageFor } from 'ember-local-storage';
import { datadogRum } from '@datadog/browser-rum';
import isValidMoment from 'smile-admin/utils/computed/is-valid-moment';

const userIncludes = [
  'account.billing_subscriptions.billing_payment_method',
  'account.billing_subscriptions.pending_billing_discount.billing_coupon',
  'account.billing_subscriptions.billing_subscription_items.new_billing_plan.billing_plan_usage_allowance',
  'account.billing_subscriptions.billing_subscription_items.new_billing_plan.billing_product',
  'account.billing_subscriptions.billing_subscription_defaults.new_billing_plan.billing_plan_usage_allowance',
  'account.billing_subscriptions.billing_subscription_defaults.new_billing_plan.billing_product',
  'account.reward_programs',
  'account.feature_rollouts',
  'account.new_feature_flags',
  'account.onboarding_quiz_answer_set.account_industry',
  'account.is_currency_code_editable',
  'account.usage_credits',
  'account.display_setting',
  'account.account_order_limit',
  'account.account_data_set',
  'account.new_integrations.app.app_listing',
  'account.new_integrations.connected_account',
  'account.new_integrations.is_platform_app',
  'account.new_integrations.oauth_connect_url',
  'account.new_integrations.sales_channels',
  'account.new_integrations.customer_identity_origin',
];

export default Service.extend({
  config: service(),
  errorHandler: service(),
  features: service(),
  featureRollouts: service(),
  intercom: service(),
  intl: service(),
  metrics: service(),
  router: service(),
  session: service(),
  shopify: service(),
  store: service(),

  localStorage: storageFor('sweet-tooth-session'),

  user: null,

  bypassUpgradeScreen: tracked({ value: false }),
  isInitialPageLoad: tracked({ value: true }),

  account: alias('user.account'),
  integrations: alias('account.newIntegrations'),

  pointsLabelSingular: alias('pointsRewardProgram.points_label_singular'),
  pointsLabelPlural: alias('pointsRewardProgram.points_label_plural'),
  defaultPointsLabelPlural: readOnly(
    'pointsRewardProgram.default_points_label_plural'
  ),
  defaultPointsLabelSingular: readOnly(
    'pointsRewardProgram.default_points_label_singular'
  ),

  hasPointsInstalled: bool('pointsRewardProgram').readOnly(),
  hasReferralsInstalled: bool('referralsRewardProgram').readOnly(),
  hasMilestoneVipInstalled: bool('milestoneVipRewardProgram').readOnly(),

  hasShopifyPlatform: equal('account.platformType', 'shopify'),
  hasBigcommercePlatform: equal('account.platformType', 'bigcommerce'),
  hasWixPlatform: equal('account.platformType', 'wix'),
  hasCustomPlatform: equal('account.platformType', 'custom'),

  hasEverEnabledPoints: isValidMoment('pointsRewardProgram.lastEnabledAt'),
  hasEverEnabledReferrals: isValidMoment(
    'referralsRewardProgram.lastEnabledAt'
  ),
  hasEverEnabledMilestoneVip: isValidMoment(
    'milestoneVipRewardProgram.lastEnabledAt'
  ),

  hasEverEnabledRewarding: or(
    'hasEverEnabledPoints',
    'hasEverEnabledReferrals',
    'hasEverEnabledMilestoneVip'
  ),

  isAnyRewardingProgramEnabled: computed(
    'pointsRewardProgram.is_enabled',
    'referralsRewardProgram.is_enabled',
    'milestoneVipRewardProgram.is_enabled',
    function () {
      return (
        this.pointsRewardProgram?.is_enabled ||
        this.referralsRewardProgram?.is_enabled ||
        this.milestoneVipRewardProgram?.is_enabled
      );
    }
  ),

  usesPrompts: readOnly('account.displaySetting.hasPromptsInteractionMedium'),

  platformIntegrations: filterBy('integrations', 'isPlatformApp'),
  platformIntegration: readOnly('platformIntegrations.0'),
  nonPlatformIntegrations: filterBy('integrations', 'isPlatformApp', false),

  rewardPrograms: filterBy('account.reward_programs', 'isDeleted', false),

  pointsRewardProgram: computed('rewardPrograms.[]', function () {
    return this.get('rewardPrograms').find((item) => item.type === 'points');
  }),

  referralsRewardProgram: computed('rewardPrograms.[]', function () {
    return this.get('rewardPrograms').find((item) => item.type === 'referrals');
  }),

  milestoneVipRewardProgram: computed('rewardPrograms.[]', function () {
    return this.get('rewardPrograms').find(
      (item) => item.type === 'milestone_vip'
    );
  }),

  // eslint-disable-next-line ember/no-observers
  _setupFeatureFlags: observer('account.new_feature_flags', function () {
    let features = this.get('account.new_feature_flags');
    this.get('features').setup(features);
  }),

  // eslint-disable-next-line ember/no-observers
  _setupFeatureRollouts: observer('account.featureRollouts.[]', function () {
    let featureRolloutsMap = {};
    this.get('account.featureRollouts').map(function (flagName) {
      this[flagName] = true;
    }, featureRolloutsMap);
    this.get('featureRollouts').setup(featureRolloutsMap);
  }),

  isOnReducedFreeBranding: computed(
    'features.customizeBranding',
    'account.eligibleForReducedFreeBranding',
    function () {
      return (
        this.account.eligibleForReducedFreeBranding &&
        !this.features.get('customizeBranding')
      );
    }
  ).readOnly(),

  isCandidateEarningSupported: computed(function () {
    return this.hasShopifyPlatform || this.hasWixPlatform;
  }).readOnly(),

  get improveBillingUsability() {
    return (
      this.featureRollouts.isEnabled('improveBillingUsability') ||
      (this.localStorage.get('isAdmin') &&
        this.featureRollouts.isEnabled('improveBillingUsabilityTesting'))
    );
  },

  setupSession: task(function* setupSession() {
    if (!this.session.isAuthenticated) {
      return;
    }

    try {
      let user = this.shopify.isEmbedded
        ? yield this.store.queryRecord('user', {
            me: true,
            reload: true,
            include: userIncludes.join(','),
          })
        : yield this.store.findRecord(
            'user',
            this.session.data.authenticated.id,
            {
              reload: true,
              include: userIncludes.join(','),
            }
          );

      this.set('user', user);

      this.localStorage.setProperties({
        onboardingQuizCompleted: this.account.isQuizComplete,
        lockedPendingPayment: this.account.lockedPendingPayment,
        shouldSeeLockoutScreen: this.account.shouldSeeLockoutScreen,
      });

      this._setupDatadog();
      this.setLocale(this.account.displaySetting?.customerLocale);
      this._setupFeatureFlags();
      this._setupFeatureRollouts();
      this._setupIntercom();

      scheduleOnce('afterRender', this, this._setupMetrics);

      if (this.shopify.isEmbedded) {
        this.shopify.createNavigationMenu();
      }

      // If a user has already installed the app on one store
      // and completed the onboarding process, we need to ensure
      // they have also completed the quiz when installing the app
      // on another store using the same browser.
      if (!this.account.isQuizComplete) {
        this.router.transitionTo('start');
      }
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }).restartable(),

  _setupDatadog() {
    datadogRum.setUser({
      id: this.user.id,
      name: this.user.name,
      email: this.user.email,
    });

    const source = window.top !== window.self ? 'embedded' : 'standalone';

    datadogRum.setGlobalContextProperty('smile-admin-source', source);
    datadogRum.setGlobalContextProperty('platform', this.account.platformType);
  },

  _setupMetrics() {
    this.metrics.activateAdapters(this.config.get('lazyMetricsAdapters'));
    this.metrics.context = this.accountTrackingInfo;
  },

  setLocale(locale) {
    this.intl.setLocale(locale || 'en-US');
  },

  _setupIntercom() {
    if (
      this.localStorage.get('isAdmin') ||
      !this.config.get('intercom.enabled')
    ) {
      return;
    }

    let {
      intercom,
      user: { id, intercomHash },
    } = this;

    intercom.set('user', {
      id,
      hash: intercomHash,
    });
  },

  accountTrackingInfo: computed(
    'account.{id,chargeableSubscription.basePlanName}',
    'user.{id,email,first_name,last_name,created_at}',
    function () {
      const { user, account } = this;

      if (isNone(user) || isNone(account)) {
        return null;
      }

      const { id: user_id, email, first_name, last_name, created_at } = user;

      const {
        id: account_id,
        chargeableSubscription: { basePlanName: plan },
      } = account;

      return {
        user_id,
        account_id,
        plan,
        email,
        first_name,
        last_name,
        created_at,
      };
    }
  ),

  attemptUpgradeScreenBypass(tryBypass) {
    this.bypassUpgradeScreen = tryBypass && this.localStorage.get('isAdmin');
  },

  get isOnboardingComplete() {
    return this.account
      ? this.account.isQuizComplete
      : this.localStorage.get('onboardingQuizCompleted');
  },

  get isShopifyEmbedded() {
    return (
      this.shopify.isEmbedded && this.localStorage.get('hasShopifyPlatform')
    );
  },
});
