/* import __COLOCATED_TEMPLATE__ from './step.hbs'; */
import Component from '@glimmer/component';

export default class MilestonesStep extends Component {
  get headline() {
    return this.args.completed ? 'congratulations!' : this.args.headline;
  }

  get icon() {
    if (!this.args.completed) {
      return this.args.icon;
    }

    return this.isLastStep
      ? 'smile/notification-referral-completed'
      : 'smile/check-icon';
  }

  get iconWrapperClass() {
    return this.icon == 'smile/check-icon' ? 'grey-check' : 'step-icon';
  }

  get isLastStep() {
    return this.args.icon == 'smile/basket';
  }

  get stepIndicatorLineClass() {
    if (this.isLastStep) {
      return '';
    }

    let cssClass = 'horizontal-line';

    if (this.args.completed) {
      return `${cssClass} step-completed-line`;
    } else {
      return cssClass;
    }
  }
}
