import numeral from 'numeral';
import { isBlank } from '@ember/utils';

export default function prettyNumeral(value) {
  if (isBlank(value)) {
    return '';
  }

  if (value >= 10000000) {
    return numeral(value).format('0.0a').toUpperCase();
  } else if (value >= 1000000) {
    return numeral(value).format('0.00a').toUpperCase();
  } else if (value >= 100000) {
    return numeral(value).format('0a').toUpperCase();
  } else if (value >= 10000) {
    return numeral(value).format('0.0a').toUpperCase();
  } else {
    return numeral(value).format('0,0').toUpperCase();
  }
}
