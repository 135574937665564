import { isEmpty } from '@ember/utils';

/**
 *
 * Utility that maps listings into categories.
 *
 * Result looks like this
 *
 * [
 *   { category: 'Category 1', listings: [{},{},{}] },
 *   { category: 'Category 2', listings: [{},{},{}] },
 *   { category: 'Category 3', listings: [{},{},{}] },
 * ].
 *
 * @param  {String} inputList          Required. The list to categorize.
 * @param  {String} categoryPath       Required. The path to the listing's category property.
 * @param  {String} categorySortOrder  Required. The ordering of the categories.
 *
 * @return {Ember.ComputedProperty}
 */
export default function categorize(inputList, categoryPath, categorySortOrder) {
  let groups = {};

  inputList.forEach((listing) => {
    let category = listing.get(categoryPath);

    // Create the group if not created yet.
    if (!(category in groups)) {
      groups[category] = [];
    }

    // Place listing in the group.
    groups[category].push(listing);
  });

  let orderedGroups = [];
  Object.keys(groups).forEach((key) => {
    if (!isEmpty(groups[key])) {
      orderedGroups.push({ category: key, listings: groups[key] });
    }
  });

  orderedGroups.sort(
    (a, b) =>
      categorySortOrder.indexOf(a.category) >
      categorySortOrder.indexOf(b.category)
  );

  return orderedGroups;
}
