import {
  validateFormat,
  validateLength,
  validatePresence,
  validateConfirmation,
} from 'ember-changeset-validations/validators';
import { validateEmailDisposability } from 'smile-admin/validators/email';

const minPasswordLength = 8;

const emailValidations = {
  email: validateFormat({ type: 'email' }),
};

const nonDisposableEmailValidations = {
  email: [validateFormat({ type: 'email' }), validateEmailDisposability()],
};

const passwordValidations = {
  password: [
    validateLength({ min: minPasswordLength }),
    validatePresence(true),
  ],
};

const userValidations = {
  first_name: validatePresence(true),
  last_name: validatePresence(true),
  ...emailValidations,
  password: validateLength({
    min: minPasswordLength,
    allowNone: true,
    allowBlank: true,
  }),
  passwordConfirmation: validateConfirmation({ on: 'password' }),
};

export default userValidations;
export { emailValidations, nonDisposableEmailValidations, passwordValidations };
