import Model, { attr } from '@ember-data/model';
import { isEmpty, isNone } from '@ember/utils';
import { computed } from '@ember/object';

export default Model.extend({
  status: attr('string'),
  statusText: attr('string'),
  responseJSON: attr('object'),

  /**
   * Set this if the error is created from code
   * and not from a jquery promise error
   */
  customErrorMessage: attr('string'),

  message: computed(function () {
    if (!isEmpty(this.get('customErrorMessage'))) {
      return this.get('customErrorMessage');
    }

    if (!isNone(this.get('responseJSON'))) {
      return this.get('responseJSON').message;
    }

    if (this.get('status') === 0) {
      return 'Could not connect to Smile.io. Please try again in a few moments.';
    }

    if (this.get('status') === 500) {
      return 'There was an error processing this action. The Smile.io team has been notified. Please contact help@smile.io to speak with a human.';
    }

    return this.get('status') + ' ' + this.get('statusText');
  }),
});
