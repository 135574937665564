import { validatePresence } from 'ember-changeset-validations/validators';
import validateSometimes from 'smile-admin/validators/sometimes';
import validateHandlebars from 'smile-admin/validators/handlebars';
import {
  requiredVariablesMap,
  cannotEditBodyTemplate,
} from 'smile-admin/models/customer-notification';
import { HELPERS_THAT_INCLUDE_INPUT_VALUE_IN_OUTPUT } from 'smile-admin/instance-initializers/handlebars';

const validateVariableInclusion =
  ({ field }) =>
  (key, newValue, oldValue, changes, content) => {
    let map = requiredVariablesMap[field];
    let notificationType = content.get('type');
    let requiredVariables = map[notificationType];

    // If no `newValue`, then the default field will be used
    // and therefor the custom field is considered valid.
    if (!newValue || !requiredVariables) {
      return true;
    }

    // Remove helpers from the value to be able to validate the required variables
    HELPERS_THAT_INCLUDE_INPUT_VALUE_IN_OUTPUT.forEach((helper) => {
      newValue = newValue.replaceAll(helper, '');
    });
    newValue = newValue.replaceAll(/\s/g, '');

    // Generate array of missing variables
    let missingVariables = requiredVariables.filter(
      (variable) => newValue.indexOf(variable.value) === -1
    );

    if (!missingVariables.length) {
      return true;
    }

    missingVariables = missingVariables.map((variable) => variable.value);

    // Generate user friendly list, e.g. "var1, var2, and var3"
    let missingVariablesString = missingVariables
      .join(', ')
      .replace(/,(?!.*,)/gim, ' and');

    return `Template must include ${missingVariablesString}`;
  };

export default {
  name: validatePresence(true),
  customizableTitleTemplate: validateSometimes(
    [
      validateHandlebars({
        templateDataKey: 'previewData',
        strict: false,
        description: 'Email title',
        allowBlank: true,
      }),
      validateVariableInclusion({ field: 'title' }),
    ],
    function () {
      return !this.get('usesCustomizedTemplate');
    }
  ),
  customizableBodyTemplate: validateSometimes(
    [
      validateHandlebars({
        templateDataKey: 'previewData',
        strict: false,
        description: 'Email description',
        allowBlank: true,
      }),
      validateVariableInclusion({ field: 'body' }),
    ],
    function () {
      return (
        !this.get('usesCustomizedTemplate') &&
        cannotEditBodyTemplate.indexOf(this.get('type')) === -1
      );
    }
  ),
  customizableSubjectTemplate: [
    validatePresence({
      presence: true,
      description: 'Email subject',
    }),
    validateHandlebars({
      templateDataKey: 'previewData',
      strict: false,
      description: 'Email subject',
    }),
  ],
  customizedEmailHtmlTemplate: validateHandlebars({
    templateDataKey: 'previewData',
    strict: false,
    description: 'Email body',
    allowBlank: true,
  }),
  customizableCtaTemplate: validateHandlebars({
    templateDataKey: 'previewData',
    strict: false,
    description: 'Button text',
    allowBlank: true,
  }),
};
