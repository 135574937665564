import * as glimmerRuntime from '@glimmer/runtime';

/**
 * Checks if a value is a curried component definition.
 *
 * Example: value={{component ...}}
 */

export const isCurriedComponentDefinition = function <T>(
  value: T,
): value is Exclude<T, undefined> {
  return value instanceof glimmerRuntime.CurriedValue;
};

export const isGlimmerComponentClass = function <T>(
  value: T,
): value is Exclude<T, undefined> {
  return typeof value === 'function';
};

export const isTemplateOnlyComponentClass = function <T>(
  value: T,
): value is Exclude<T, undefined> {
  return value instanceof glimmerRuntime.TemplateOnlyComponent;
};

export function isComponent<T>(value: T): value is Exclude<T, undefined> {
  if (typeof value === 'string' || value == null) {
    return false;
  }

  return (
    isGlimmerComponentClass(value) ||
    isTemplateOnlyComponentClass(value) ||
    isCurriedComponentDefinition(value)
  );
}
