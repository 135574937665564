import { operators } from 'smile-admin/models/activity-rule-bonus';

export default class ActivityRuleBonusEngine {
  getDefaults({ account, activityRule, activityRuleBonusDefinition }) {
    return {
      type: activityRuleBonusDefinition.type,
      account,
      activityRule,
      activityRuleBonusDefinition,
      bonusOperator: operators.multiplication,
      bonusOperand: 2,
    };
  }
}
