import { camelize } from '@ember/string';

export default function addDefaultAndCustomizedFieldsToModel(model) {
  return Object.keys(model).reduce((transformedModel, fieldName) => {
    let value = model[fieldName];
    return {
      ...transformedModel,
      [fieldName]: value,
      [camelize(`default_${fieldName}`)]: value,
      [camelize(`customized_${fieldName}`)]: value,
    };
  }, {});
}
