import ColorsDropdownComponent from 'smile-admin/components/smile-ui/forms/colors-dropdown';

const blackHex = '#000000';
const whiteHex = '#FFFFFF';

const options = [
  {
    label: 'Black',
    value: blackHex,
    hex: blackHex,
  },
  {
    label: 'White',
    value: whiteHex,
    hex: whiteHex,
  },
];

export default class BlackWhiteColorsDropdownComponent extends ColorsDropdownComponent {
  options = options;
}
