/* import __COLOCATED_TEMPLATE__ from './polaris-label-with-character-count.hbs'; */
import Component from '@glimmer/component';
import { tagName } from '@ember-decorators/component';
import { isPresent } from '@ember/utils';

/**
 * Public interface:
 *    label {String}
 *    text {String}
 *    maxTextLength {Number}
 */
@tagName('')
export default class PolarisLabelWithCharacterCountComponent extends Component {
  get textLength() {
    return isPresent(this.args.text) ? this.args.text.length : 0;
  }

  get hasValidMaxTextLength() {
    let { maxTextLength } = this.args;

    return typeof maxTextLength === 'number' && maxTextLength > 0;
  }

  get isTextLongerThanLimit() {
    let {
      textLength,
      hasValidMaxTextLength,
      args: { maxTextLength },
    } = this;

    return hasValidMaxTextLength && textLength > maxTextLength;
  }

  get textLengthStatus() {
    let {
      textLength,
      hasValidMaxTextLength,
      args: { maxTextLength },
    } = this;

    let textLengthStatus = textLength || 0;

    if (hasValidMaxTextLength) {
      textLengthStatus = `${textLengthStatus}/${maxTextLength}`;
    }

    return textLengthStatus;
  }
}
