/* import __COLOCATED_TEMPLATE__ from './smile-branding.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  features: service(),

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {},

  actions: {
    updateSmileBrandingVisibility(panelChangeset, [showSmileBranding]) {
      this.onChange(
        'panel',
        panelChangeset,
        'customizableShowSmileBranding',
        showSmileBranding
      );
      this.onChange(
        'panel',
        panelChangeset,
        'hideSmileBranding',
        !showSmileBranding
      );
    },
  },
});
