import { helper } from '@ember/component/helper';

/**
 * Accepts a list of (unnamed) string values (ex: "30px")
 * and returns the first truthy value as a number (ex: 30)
 *
 * Usage example: providing an initial value for customizable
 * side margins in smile-ui
 *
 * {{polaris-text-field
 *   label="Side margins"
 *   type="number"
 *   suffix="px"
 *   value=(smile-ui/pixels-to-number
 *     model.customizedSmileUiDesktopSideMargin
 *     model.defaultSmileUiDesktopSideMargin
 *   )
 *   onChange=(action (mut model.customizedSmileUiDesktopSideMargin))
 * }}
 *
 */

export function pixelsToNumber(...values) {
  let [firstValue] = values.filter(Boolean);
  return parseInt(firstValue);
}

export default helper(function (values) {
  return pixelsToNumber(...values);
});
