import { helper } from '@ember/component/helper';
import { isNone } from '@ember/utils';
import { classify } from '@ember/string';
import { defineProperty } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { customizableProperty } from 'smile-admin/utils/customizable-property';

/**
 * Helper to create a proxy around a model with customizable properties.
 * This allows us to keep our models free of `customizable-property` CPs,
 * whilst still being able to both get and set customizable properties
 * using the same property name for symmetry, and also prevents the CPs
 * from interfering with changeset state flags (e.g. isDirty).
 *
 * Example: given a `customizable-button` model,
 *   app/models/customizable-button.js:
 *
 *     ...
 *     buttonText: DS.attr('string'),
 *     customizedButtonText: DS.attr('string'),
 *     ...
 *
 * using this helper on an instance of the `customizable-button` model
 * would return an object with a `customizableButtonText` property
 * whose getter would read the model's `buttonText` property, while
 * the setter would update the model's `customizedButtonText` property.
 */

// Finds pairs of attributes named as `myAttr` and `customizedMyAttr`.
function findCustomizableAttributePairs(model) {
  let attributes = model.get('constructor.attributes');
  let customizableAttributePairs = [];

  attributes.forEach((_, attributeName) => {
    // NOTE: this only supports camelCased attribute names for now!
    let customizableAttributeName = `customized${classify(attributeName)}`;
    if (attributes.get(customizableAttributeName)) {
      customizableAttributePairs.push({
        attributeName,
        customizableAttributeName,
      });
    }
  });

  return customizableAttributePairs;
}

// Used to add methods that proxy to the underlying content.
function proxyToContent(methodName) {
  return function (...invocationArgs) {
    return this.content[methodName](...invocationArgs);
  };
}

const CustomizableProxy = ObjectProxy.extend({
  save: proxyToContent('save'),
});

export function createCustomizableProxy(model) {
  if (isNone(model)) {
    return null;
  }

  let customizableAttributePairs = findCustomizableAttributePairs(model);
  let proxy = CustomizableProxy.create({ content: model });

  customizableAttributePairs.forEach(
    ({ attributeName, customizableAttributeName }) => {
      defineProperty(
        proxy,
        `customizable${classify(attributeName)}`,
        customizableProperty(attributeName, customizableAttributeName)
      );
    }
  );

  return proxy;
}

export default helper(function ([model]) {
  return createCustomizableProxy(model);
});
