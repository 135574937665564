/* import __COLOCATED_TEMPLATE__ from './billing-provider.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { resolve } from 'rsvp';
import { task } from 'ember-concurrency';

/**
 * Provider component exposes billing business logic backed by the `billing` service.
 * TODO #billing-2018-cleanup get rid of this provider? Since we have the service, this
 * component is only a layer of indirection, we could use the service directly
 */
export default Component.extend({
  tagName: '',

  billing: service(),
  sesh: service(),

  /**
   * Callback triggered after a billing-subscription offer is activated.
   *
   * @type {Function}
   * @public
   */
  onAfterActivateSubscription: resolve,

  /**
   * Callback triggered after a billing-subscription offer is declined.
   *
   * @type {Function}
   * @public
   */
  onAfterDeclineSubscription: resolve,

  /**
   * Callback triggered after subscribing to a billing-subscription-template.
   *
   * @type {Function}
   * @public
   */
  onAfterSubscribeToSubscriptionTemplate: resolve,

  isPaymentUpdateRequired: reads('billing.isPaymentUpdateRequired'),

  paymentUpdateRequiredText: computed(
    'billing.chargeableSubscription.{isStripeType,isShopifyType}',
    function () {
      let subscription = this.billing.chargeableSubscription;

      if (subscription.isShopifyType) {
        return 'Go to Shopify';
      } else if (subscription.isStripeType) {
        return 'Update credit card';
      }

      return null;
    }
  ).readOnly(),

  activateSubscription: task(function* (
    subscription,
    refreshFeatureFlags = true
  ) {
    subscription = yield this.billing.activateSubscription.perform(
      subscription,
      refreshFeatureFlags
    );

    if (!subscription || !subscription.isActive) {
      return;
    }

    yield this.onAfterActivateSubscription();

    return subscription;
  }).drop(),

  declineSubscription: task(function* (subscription) {
    subscription = yield this.billing.declineSubscription.perform(subscription);

    if (!subscription || !subscription.isDeclined) {
      return;
    }

    yield this.onAfterDeclineSubscription();

    return subscription;
  }).drop(),

  subscribeToSubscriptionTemplate: task(function* (
    subscriptionTemplate,
    options = {}
  ) {
    let subscription =
      yield this.billing.subscribeToSubscriptionTemplate.perform(
        subscriptionTemplate,
        options
      );

    if (!subscription || !subscription.isActive) {
      return;
    }

    yield this.onAfterSubscribeToSubscriptionTemplate(subscription);

    return subscription;
  }).drop(),

  actions: {
    contactSales(message) {
      this.billing.contactSales(message);
    },
  },
});
