import ApplicationSerializer from 'smile-admin/serializers/application';

const deserializeOnly = {
  serialize: false,
  deserialize: 'records',
};

export default ApplicationSerializer.extend({
  attrs: {
    panelHeader: { embedded: 'always' },
    wallpaperOptions: deserializeOnly,
  },
});
