import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { memberAction } from 'ember-api-actions';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';

export const visibilityOptions = {
  desktopAndMobile: 'desktop_and_mobile',
  desktop: 'desktop',
  none: 'none',
};

export default Model.extend(
  addDefaultAndCustomizedFieldsToModel({
    text: attr('string'),
    mobileText: attr('string'),

    textColor: attr('string'),
    borderRadiusStyle: attr('string'),
    colorPreset: attr('string'),

    visibilitySetting: attr('string'),
    hiddenUrlPaths: attr('array'),

    // This is deliberately not flagged as a boolean because otherwise
    // ember-data coerces null values for customizedUsesDefaultIcon to
    // false which interferes with our customizedOrDefault logic.
    usesDefaultIcon: attr(),

    hideOnIndexPage: attr('boolean'),
  }),
  {
    color: attr('string'),

    /** @type {'text' | 'image' | 'text_and_icon'} */
    layout: attr('string'),

    /** @type {'icon' | 'text'} */
    mobileLayout: attr('string'),

    // Desktop icon attributes.
    iconUrl: attr('string'), // computed as usesDefaultIcon ? defaultIconUrl : customIconUrl
    defaultIcon: attr('string'), // label of one of our built-in icons, used to change the selected default icon
    defaultIconUrl: attr('string'), // URL to one of our built-in icons
    customizedIconUrl: attr('string'), // URL to a merchant-provided icon
    customizedIconTempS3Key: attr('string'), // S3 key for merchant-provided icon
    updatedAt: attr('date'),

    iconOptions: hasMany('icon-option', { async: false, inverse: null }),

    isVisibleOnMobile: computed(
      'visibilitySetting',
      'customizedVisibilitySetting',
      function () {
        let { visibilitySetting, customizedVisibilitySetting } = this;

        visibilitySetting =
          typeof customizedVisibilitySetting === 'boolean'
            ? customizedVisibilitySetting
            : visibilitySetting;

        return visibilitySetting && visibilitySetting.includes('mobile');
      }
    ).readOnly(),

    removeImage: memberAction({ path: 'remove_image', type: 'put' }),
  }
);
