import { validatePresence } from 'ember-changeset-validations/validators';

const rewardProgramNameValidations = {
  rewardProgramName: validatePresence({
    presence: true,
    description: 'Program name',
  }),
};

export { rewardProgramNameValidations };
