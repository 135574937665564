import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { or, bool } from '@ember/object/computed';
import numeral from 'numeral';

export const sourceTypes = {
  adjustment: 'adjustment',
  rewardFulfillment: 'fulfilled_reward',
  pointsPurchase: 'points_purchase',
  expiry: 'expiry',
  partialRefund: 'partial_refund',
};

export default Model.extend({
  points_amount: attr('number'),
  points_change: attr('number'),
  comment: attr('string'),
  internal_note: attr('string'),
  description: attr('string'),

  pointsPurchaseId: attr('string'),

  // For model creation only.
  // TODO remove these
  customer_id: attr('string'),
  account_id: attr('string'),

  is_cancelled: attr('boolean'),
  does_cancel: attr('boolean'),
  created_at: attr('date'),
  updated_at: attr('date'),

  activity: belongsTo('activity', {
    async: false,
    inverse: 'points_transactions',
  }),
  customer: belongsTo('customer', { async: false, inverse: null }),
  pointsPurchase: belongsTo('points-purchase', { async: false, inverse: null }),

  hasPointsPurchase: bool('pointsPurchaseId').readOnly(),
  descriptionForMerchant: or('internal_note', 'description'),

  earnsPoints: computed('points_change', function () {
    var points_change = this.get('points_change');
    return points_change > 0;
  }),

  spendsPoints: computed('points_change', function () {
    var points_change = this.get('points_change');
    return points_change < 0;
  }),

  actionString: computed('earnsPoints', 'does_cancel', function () {
    if (this.get('earnsPoints')) {
      return 'earned';
    } else if (this.get('does_cancel')) {
      return 'deducted';
    } else {
      return 'spent';
    }
  }),

  actionDescription: computed('actionString', 'points_amount', function () {
    // Should this use points label instead of literal 'points'?
    return `${this.get('actionString')} ${numeral(
      this.get('points_amount')
    ).format('0,0')} points`;
  }).readOnly(),
});
