import Model, { attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  description: attr('string'),
  short_description: attr('string'),
  poster_image_url: attr('string'),
  reward_program_type: attr('string'),
  top_industries: attr('array'),
  requiredFeatureFlag: attr('string'),
});
