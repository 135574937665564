/* import __COLOCATED_TEMPLATE__ from './review-request.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { storageFor } from 'ember-local-storage';

const defaultRatingDescription = {
  icon: 'smile/emoji/smile',
  text: 'Leave us your feedback!',
};
const ratingDescriptions = [
  {
    icon: 'smile/emoji/frown',
    text: "Yikes! Tell us what's wrong!",
  },
  {
    icon: 'smile/emoji/confused',
    text: 'Oh no! Tell us how we can improve',
  },
  {
    icon: 'smile/emoji/neutral',
    text: 'We’d love to hear why',
  },
  {
    icon: 'smile/emoji/smile',
    text: 'Great! Tell us how to get 5 stars',
  },
  {
    icon: 'smile/emoji/star-eyes',
    text: 'Yay! Click to rate us on the app store',
  },
];

export default class ReviewRequest extends Component {
  @service config;
  @service utils;

  @storageFor('sweet-tooth-session')
  localStorage;

  @tracked previewedRating = null;
  @tracked showFeedbackRequest = false;

  defaultRatingDescription = defaultRatingDescription;
  ratingDescriptions = ratingDescriptions;

  get showReviewRequest() {
    return (
      !this.args.account.reviewRating &&
      !this.localStorage.get('hasDismissedReviewRequest')
    );
  }

  @action
  handleRatingClicked(accountChangeset, rating) {
    // StarRating component uses zero-based ratings.
    rating = rating + 1;
    accountChangeset.set('reviewRating', rating);

    if (rating < 5) {
      this.showFeedbackRequest = true;
      return;
    }

    // 5-star rating, instantly redirect the merchant to leave a review.
    this.utils.openExternalLink(
      `${this.config.get('apiHost')}/v1/leave_us_a_review?account_id=${
        this.args.account.id
      }`
    );

    this.saveRating(accountChangeset);
  }

  @action
  handleDismissReviewRequest() {
    this.localStorage.set('hasDismissedReviewRequest', true);
  }

  @action
  saveRating(accountChangeset) {
    accountChangeset.save();
  }
}
