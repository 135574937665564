export const getReports = (sesh) => {
  const currencyCodeBanner = {
    bannerText: `All currency is displayed in your store’s base currency (${sesh.account.currencyCode}).`,
    bannerStatus: 'info',
  };
  const customersWhoCanRedeemBanner = {
    bannerText: `These are customers who have enough points to redeem on a reward right now.`,
    bannerStatus: 'info',
  };
  const membersBanner = {
    bannerText: `A member is a customer with a store account.`,
    bannerStatus: 'info',
  };
  const benchmarksBanner = {
    bannerText: `Compare your loyalty metrics to stores like yours that use Smile.`,
    bannerStatus: 'info',
  };

  return [
    // Attribution category
    {
      title: 'Sales influenced by Smile over time',
      key: 'sales_influenced_by_smile_over_time',
      category: 'Sales attribution',
      ...currencyCodeBanner,
    },

    // Industry benchmarks category
    {
      title: 'Smile benchmarks',
      key: 'smile_benchmarks',
      category: 'Benchmarks',
      ...benchmarksBanner,
    },

    // Points category
    {
      title: 'Points activity over time',
      key: 'points_activity_over_time',
      category: 'Points',
    },
    {
      title: 'First-time vs repeat earners',
      key: 'first_time_vs_repeat_earners_over_time',
      category: 'Points',
    },
    {
      title: 'First-time vs repeat redeemers',
      key: 'first_time_vs_repeat_redeemers_over_time',
      category: 'Points',
    },
    {
      title: 'Top ways customers earn',
      key: 'top_ways_to_earn',
      category: 'Points',
    },
    {
      title: 'Top ways customers redeem',
      key: 'top_ways_to_redeem',
      category: 'Points',
    },
    {
      title: 'Outstanding points over time',
      key: 'outstanding_points_over_time',
      category: 'Points',
    },
    {
      title: 'Total members over time',
      key: 'total_members_over_time',
      category: 'Points',
      ...membersBanner,
    },

    // Key metrics category
    {
      title: 'Redemption rate over time',
      key: 'redemption_rate_over_time',
      category: 'Key metrics',
    },
    {
      title: 'Reward usage rate over time',
      key: 'reward_usage_rate_over_time',
      category: 'Key metrics',
    },
    {
      title: 'Order earning rate over time',
      key: 'order_earning_rate_over_time',
      category: 'Key metrics',
    },

    // Financial value category
    {
      title: 'Financial value of outstanding points',
      key: 'financial_value_of_outstanding_points',
      category: 'Finance',
      ...currencyCodeBanner,
    },
    {
      title: 'Financial value of discounts issued over time',
      key: 'financial_value_of_discounts_issued_over_time',
      category: 'Finance',
      ...currencyCodeBanner,
    },

    // Referrals category
    {
      title: 'Sales from referred customers over time',
      key: 'sales_from_referred_customers_over_time',
      category: 'Referrals',
      ...currencyCodeBanner,
    },
    {
      title: 'Referral traffic over time',
      key: 'referral_traffic_over_time',
      category: 'Referrals',
    },
    {
      title: 'Referral conversion rate over time',
      key: 'referral_conversion_rate_over_time',
      category: 'Referrals',
    },
    {
      title: 'Top referrers',
      key: 'top_referrers',
      category: 'Referrals',
    },

    // VIP category
    {
      title: 'VIP tier changes over time',
      key: 'vip_tier_changes_over_time',
      category: 'VIP',
    },
    {
      title: 'VIP tier members over time',
      key: 'vip_tier_members_over_time',
      category: 'VIP',
    },

    // Data export category
    {
      title: 'List of Smile influenced orders',
      key: 'list_of_all_smile_influenced_orders',
      category: 'Data export',
      ...currencyCodeBanner,
    },
    {
      title: 'List of discounts created by Smile',
      key: 'list_of_all_discounts_created_by_smile',
      category: 'Data export',
    },
    {
      title: 'List of points transactions',
      key: 'list_of_all_points_transactions',
      category: 'Data export',
    },
    {
      title: 'List of customers',
      key: 'list_of_all_customers',
      category: 'Data export',
    },
    {
      title: 'List of customers who can redeem',
      key: 'list_of_all_customers_who_can_redeem',
      category: 'Data export',
      ...customersWhoCanRedeemBanner,
    },
    {
      title: 'List of customers by VIP tier',
      key: 'list_of_all_customers_by_vip_tier',
      category: 'Data export',
    },
    {
      title: 'List of points redemptions',
      key: 'list_of_all_points_redemptions',
      category: 'Data export',
    },
    {
      title: 'List of referrals',
      key: 'list_of_all_referrals',
      category: 'Data export',
    },
    {
      title: 'List of orders placed by referred customers',
      key: 'list_of_orders_placed_by_referred_customers',
      category: 'Data export',
      ...currencyCodeBanner,
    },
    {
      title: 'List of VIP tier changes',
      key: 'list_of_all_vip_tier_changes',
      category: 'Data export',
    },
  ];
};
