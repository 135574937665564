import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PlanCanceledRoute extends Route {
  @service router;

  beforeModel(transition) {
    // Simple protection to make sure the user
    // can't access this route directly
    if (!transition.to.queryParams.allowed) {
      this.router.transitionTo('home');
    }
  }

  activate() {
    super.activate(...arguments);
    this.controllerFor('application').disableNav();
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controllerFor('application').enableNav();
  }
}
