/* import __COLOCATED_TEMPLATE__ from './colors-dropdown.hbs'; */
import Component from '@ember/component';
import { computed, set } from '@ember/object';

export default Component.extend({
  tagName: '',

  /**
   * Array of objects used to render the list
   * of colors in the popover.
   *
   * ex: { label: 'Primary', value: 'primary', hex: '#FF000' }
   * ex: { label: 'White', value: '#FFFFFF', hex: '#FFFFFF' }
   *
   * @property options
   * @public
   * @type {Object[]}
   * @default []
   */
  options: computed('_options', {
    get() {
      if (this._options) {
        return this._options;
      }
      return [];
    },

    set(key, value) {
      set(this, '_options', value);
      return value;
    },
  }),

  /**
   * Action called when a color is selected
   * from the list in the dropdown.
   *
   * @property onSelect
   * @public
   * @type {Function}
   * @default noop
   */
  onSelect() {},

  actions: {
    onSelect(colorOption) {
      this.set('selected', colorOption);
      this.get('onSelect')(colorOption.value);
    },
  },
});
