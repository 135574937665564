import EmberObject, { setProperties } from '@ember/object';
import { assert } from '@ember/debug';

/**
 * Manages the account's billing state.
 *
 * We don't use directly ember-data relationships for this because we want to
 * have better control on the flows (ex: view-offer modal...merchants clicks accept
 * the moment we push the updated billing-subscription in the store, it will update
 * as the active one, causing the modal to look borked).
 */
export default EmberObject.extend({
  /**
   * The current account
   *
   * @type {DS.Model}
   * @default null
   * @required
   */
  account: null,

  /**
   * The current account's chargeable billing-subscription.
   *
   * @type {DS.Model}
   * @default null
   * @public
   */
  chargeableSubscription: null,

  /**
   * If exists, the billing-subscription offer for this account.
   *
   * @type {DS.Model}
   * @default null
   * @public
   */
  subscriptionOffer: null,

  /**
   * True, when the merchant has a billing-subscription offer.
   *
   * @type {DS.Model}
   * @default null
   * @public
   */
  hasSubscriptionOffer: null,

  /**
   * List of flags for merchant actions: accepted/declined offer, upgraded/downgraded.
   *
   * @type {Object}
   * @property {Boolean} hasAcceptedOffer         True, when merchant accepted the billing-subscription offer.
   * @property {Boolean} hasDeclinedOffer         True, when merchant declined the billing-subscription offer.
   * @property {Boolean} hasUpgraded              True, when merchant upgraded their billing-subscription.
   * @property {Boolean} hasSwitchedToAnnual      True, when merchant switched to annual subscription.
   * @property {Boolean} hasDowngraded            True, when merchant downgraded the billing-subscription.
   * @property {Boolean} hasUpdatedPaymentMethod  True, when updated the payment method.
   */
  flags: null,

  updateState(newState = {}) {
    let account = this.account;
    let state = {
      chargeableSubscription: account.chargeableSubscription,
      subscriptionOffer: account.billingSubscriptionOffer,
      hasSubscriptionOffer: account.hasBillingSubscriptionOffer,
      ...newState,
    };

    setProperties(this, state);
  },

  updateFlags(newFlags = {}) {
    let flags = {
      hasAcceptedOffer: false,
      hasDeclinedOffer: false,
      hasUpgraded: false,
      hasSwitchedToAnnual: false,
      hasDowngraded: false,
      hasUpdatedPaymentMethod: false,
      ...newFlags,
    };

    this.updateState({ flags });
  },

  init() {
    this._super(...arguments);
    assert(
      '[objects/account-billing-state] Missing required `account` param!',
      this.get('account')
    );

    this.set('flags', {});
    this.updateState();
  },
});
