/* import __COLOCATED_TEMPLATE__ from './feedback-provider.hbs'; */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';
import { storageFor } from 'ember-local-storage';
import { tagName } from '@ember-decorators/component';

/**
 * Provider component for properties, tasks etc. required for feedback-bar.
 */
@tagName('')
export default class FeedbackProviderComponent extends Component {
  /**
   * Type of home card the feedback bar is for.
   *
   * @type {String}
   * @required
   * @public
   */
  type = null;

  @storageFor('session-storage')
  sessionStorage;

  @computed('type')
  get friendlyType() {
    return dasherize(this.type);
  }

  @computed('friendlyType')
  get hasFeedback() {
    return Boolean(this.get(`sessionStorage.${this.friendlyType}`));
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    assert(
      '[smile/home-card/feedback-provider] missing required `type` property',
      isPresent(this.type)
    );
  }

  @action
  submitFeedback() {
    this.sessionStorage.set(this.friendlyType, true);
  }
}
