import Model, { attr } from '@ember-data/model';

export default Model.extend({
  attribute_name: attr('string'),
  attribute_path: attr('string'),
  attribute_description: attr('string'),
  value_type: attr('string'),
  value_format: attr('string'),
  value_options: attr('object'),
  value_options_url: attr('string'),
  value_is_multi_select: attr('boolean'),
  value_options_select_component_type: attr('string'),

  created_at: attr('utc'),
  updated_at: attr('utc'),
});
