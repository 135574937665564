import Transform from '@ember-data/serializer/transform';

/**
 * This transform is used to convert an array of strings into an array of objects
 * so we can pass the objects by reference in ember and bind or modify the string
 * value.
 *
 * Example:
 *
 * editable_perks: [
 *   "1 point for every $1 spent",
 *   "Free gift"
 * ]
 *
 * Transforms to:
 *
 * editable_perks: [
 *   {
 *     value: "1 poinst for every $1 spent"
 *   },
 *   {
 *     value: "Free gift"
 *   }
 * ]
 *
 * To see an example in use look at customer tier perks
 */
export default Transform.extend({
  deserialize(serialized) {
    return serialized.map(function (item) {
      return { value: item };
    });
  },

  serialize(deserialized) {
    return deserialized.map(function (item) {
      return item.value;
    });
  },
});
