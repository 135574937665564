import { camelize } from '@ember/string';
import { validateFormat } from 'ember-changeset-validations/validators';
import { pixelsToNumber } from 'smile-admin/helpers/smile-ui/pixels-to-number';
import { hexColorMatcher } from 'smile-admin/utils/regex';

let validateSpacing = function validateSpacing() {
  return (key, newValue, _oldValue, _changes, content) => {
    let minimumValueKey = camelize(key.replace('customizable', '') + 'Min');
    let minimumValue = content.get(`content.${minimumValueKey}`);

    return (
      pixelsToNumber(newValue) >= pixelsToNumber(minimumValue) ||
      `Spacing must be ${minimumValue} or greater`
    );
  };
};

export default {
  customizablePrimaryColor: validateFormat({
    allowBlank: true,
    regex: hexColorMatcher,
    message: 'Must be a valid hex color',
  }),

  customizableSecondaryColor: validateFormat({
    allowBlank: true,
    regex: hexColorMatcher,
    message: 'Must be a valid hex color',
  }),

  customizableSmileUiDesktopSideMargin: validateSpacing(),

  customizableSmileUiDesktopBottomMargin: validateSpacing(),

  customizableSmileUiMobileSideMargin: validateSpacing(),

  customizableSmileUiMobileBottomMargin: validateSpacing(),
};
