import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

/**
 * Helper to allow opening Intercom as an action.
 */
export default class OpenHelpWidgetHelper extends Helper {
  @service
  intercom;

  compute([message]) {
    return this.createIntercomAction(message);
  }

  createIntercomAction(message = '') {
    return () => this.intercom.showNewMessage(message);
  }
}
