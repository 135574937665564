import OriginalIntercomService from 'ember-intercom-io/services/intercom';
import { timeout } from 'ember-concurrency';
import config from 'smile-admin/config/environment';
import { XhrInterceptor } from 'smile-admin/utils/xhr';

export default class IntercomService extends OriginalIntercomService {
  config = { ...config.intercom };

  _tourCompletionInterceptor = null;

  startTour() {
    this._initializeTourCompletionCapture();

    super.startTour(...arguments);
  }

  // TODO: open a PR against ember-intercom-io to add this directly to the addon
  // (or remove this if it gets added to the base class before then).
  startSurvey(surveyId) {
    return this.api('startSurvey', surveyId);
  }

  async _getIntercomFrame() {
    let intercomFrame = null;
    while (!intercomFrame) {
      intercomFrame = document.querySelector('iframe#intercom-frame');
      if (intercomFrame) {
        break;
      }

      await timeout(500);
    }

    return intercomFrame;
  }

  async _initializeTourCompletionCapture() {
    if (this._tourCompletionInterceptor) {
      return;
    }

    let intercomFrame = await this._getIntercomFrame();

    this._tourCompletionInterceptor = new XhrInterceptor({
      Xhr: intercomFrame.contentWindow.XMLHttpRequest,
      shouldIntercept: (method, url) => {
        if (method !== 'POST') {
          return false;
        }

        let tourCompletionUrlMatch = url.match(
          /.+intercom\.io\/messenger\/web\/tours\/([\d]+)\/complete/
        );

        if (tourCompletionUrlMatch) {
          this._tourId = tourCompletionUrlMatch[1];
        }

        return !!tourCompletionUrlMatch;
      },
      onSend: (origSendInvocation) => origSendInvocation(),
    });
  }
}
