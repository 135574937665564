import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  attrs: {
    appListing: { serialize: false, deserialize: 'records' },
    activityDefinitions: { serialize: false, deserialize: 'records' },
    rewardDefinitions: { serialize: false, deserialize: 'records' },
    oauth2Application: { serialize: false, deserialize: 'records' },
    newIntegrations: { serialize: false, deserialize: 'records' },
  },
});
