import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthRoute extends Route {
  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      this.send('resetAuthData');
    }
  }

  @action
  persistAuthData(data = {}) {
    this.controllerFor('auth').setProperties(data);
  }

  @action
  resetAuthData() {
    this.controllerFor('auth').setProperties({
      email: null,
      hasRecoveredPassword: false,
      hasResetPassword: false,
      isResetPasswordTokenExpired: false,
    });
  }
}
