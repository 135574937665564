import roundToClosest from 'smile-admin/utils/round-to-closest';

/**
 * Rounding rules for onboarding quiz
 * See https://docs.google.com/spreadsheets/d/1ZaXcsCIO0oVxr5XJ5z5nq83MGOLKzHO_JSCuI3RCOgY/edit?ts=5bd89dd1#gid=0
 */
export default function convertedCurrencyRounding(value) {
  if (1000000000 < value) {
    // Bigger than 1B -> closest 1B
    return roundToClosest(value, 1000000000);
  } else if (500000000 < value && value <= 1000000000) {
    // Between 500M - 1B -> closest 100M
    return roundToClosest(value, 100000000);
  } else if (100000000 < value && value <= 500000000) {
    // Between 100M - 500M -> closest 50M
    return roundToClosest(value, 50000000);
  } else if (10000000 < value && value <= 100000000) {
    // Between 10M - 100M -> closest 10M
    return roundToClosest(value, 10000000);
  } else if (1000000 < value && value <= 10000000) {
    // Between 1M - 10M -> closest 1M
    return roundToClosest(value, 1000000);
  } else if (100000 < value && value <= 1000000) {
    // Between 100K - 1M -> closest 100K
    return roundToClosest(value, 100000);
  } else if (10000 < value && value <= 100000) {
    // Between 10K - 100K -> closest 5K
    return roundToClosest(value, 5000);
  } else if (1000 < value && value <= 10000) {
    // Between 1K - 10K -> closest 500
    return roundToClosest(value, 500);
  } else if (500 < value && value <= 1000) {
    // Between 500 - 1K -> closest 100
    return roundToClosest(value, 100);
  } else if (250 < value && value <= 500) {
    // Between 250 - 500 -> closest 50
    return roundToClosest(value, 50);
  } else if (100 < value && value <= 250) {
    // Between 100 - 250 -> closest 25
    return roundToClosest(value, 25);
  } else if (25 < value && value <= 100) {
    // Between 25 - 100 -> closest 10
    return roundToClosest(value, 10);
  } else if (5 < value && value <= 25) {
    return roundToClosest(value, 5);
  } else if (1 < value && value <= 5) {
    return 1;
  } else {
    return 0;
  }
}
