/* import __COLOCATED_TEMPLATE__ from './stripe-checkout-provider.hbs'; */
import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/**
 * Handles activating a subscription with Stripe Checkout.
 * NOTE: Only one of these components should exist in the entire app
 * at the same time.
 */
export default Component.extend({
  billing: service(),
  sesh: service(),

  /**
   * Used to manually trigger showing the Stripe Checkout modal
   *
   * @type {Boolean}
   * @private
   */
  showStripeCheckout: reads('billing.showStripeCheckout'),

  /**
   * Flag set by the billing service to indicate that the merchant is in the process
   * of updating their Stripe credit card payment.
   *
   * @type {Boolean}
   * @private
   */
  isUpdatingStripePayment: reads('billing.isUpdatingStripePayment'),

  /**
   * Billing subscription that backs the Stripe Checkout modal, when the merchant doesn't
   * have billing info and wants to upgrade/accept an offer with Stripe provider.
   *
   * @type {DS.Model}
   * @private
   */
  activateableStripeSubscription: reads(
    'billing.activateableStripeSubscription'
  ),

  actions: {
    processStripeToken(token) {
      this.billing.processStripeToken(token);
    },
  },
});
