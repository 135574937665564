import Base from 'ember-simple-auth/authenticators/base';
import { inject as service } from '@ember/service';
import { timeout, waitForProperty, restartableTask } from 'ember-concurrency';

// From testing, it seems a new Shopify session token is available ~8s before the official expiry of
// the active JWT.
const JWT_REFRESH_LEEWAY_IN_S = 5 * 1000;

export default class ShopifySessionTokenAuthenticator extends Base {
  @service session;
  @service shopify;

  get _sessionToken() {
    return this.session.data.authenticated.sessionToken;
  }

  async authenticate() {
    const sessionToken = await this.shopify.fetchSessionToken();
    this._ensureFreshSessionToken.perform();

    return this._makeAuthData(sessionToken);
  }

  async invalidate() {
    await this._ensureFreshSessionToken.cancelAll();
  }

  async restore(data) {
    this._ensureFreshSessionToken.perform();
    return data;
  }

  @restartableTask
  *_ensureFreshSessionToken() {
    // Wait until the session becomes authenticated, otherwise there's nothing to refresh
    yield waitForProperty(this.session, 'isAuthenticated');

    while (true) {
      yield timeout(JWT_REFRESH_LEEWAY_IN_S);

      const sessionToken = yield this.shopify.fetchSessionToken();
      if (sessionToken !== this._sessionToken) {
        this.trigger('sessionDataUpdated', this._makeAuthData(sessionToken));
      }
    }
  }

  _makeAuthData(sessionToken) {
    return {
      sessionToken,
      authentication_token: sessionToken,
    };
  }
}
