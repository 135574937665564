import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import { customerUpdatedTopic } from 'smile-admin/models/webhook-event-definition';

export const topicOptions = [
  {
    label: 'Customer updated',
    value: customerUpdatedTopic,
  },
];

export default Model.extend({
  type: attr('string'),
  isEnabled: attr('boolean'),
  urlTemplate: attr('string'),
  topics: attr('array'),

  // Mailchimp Specific Fields
  mailchimpListId: attr('string'),
  mailchimpListName: attr('string'),

  // Dotmailer integration specific fields
  dotmailerAddressBookId: attr('string'),
  dotmailerAddressBookName: attr('string'),

  account: belongsTo('account', { async: false, inverse: null }),
  app: belongsTo('app', { async: false, inverse: null }),
  newIntegration: belongsTo('new-integration', {
    async: false,
    inverse: 'webhooks',
  }),
  lastSyncTask: belongsTo('trackable-task', { async: false, inverse: null }),

  isFinishedSyncing: reads('lastSyncTask.isFinished'),

  isSyncing: computed('lastSyncTask.finishedStatus', function () {
    let task = this.get('lastSyncTask');
    return task && !task.get('finishedStatus');
  }).readOnly(),

  isSynced: computed('lastSyncTask.finishedStatus', function () {
    return this.get('lastSyncTask.finishedStatus') === 'succeeded';
  }),

  showSyncProgress: computed(function () {
    if (this.get('lastSyncTask.finishedStatus') === 'succeeded') {
      return false;
    }

    return true;
  }),

  topic: computed({
    get() {
      return this.get('topics.0');
    },

    set(key, value) {
      this.set('topics', [value]);
      return value;
    },
  }),

  enable: memberAction({
    path: 'enable',
    after: serializeAndPush,
  }),
  disable: memberAction({
    path: 'disable',
    after: serializeAndPush,
  }),
  sendTest: memberAction({
    path: 'send_test',
    type: 'post',
  }),
});
