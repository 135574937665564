import EmberObject, { computed } from '@ember/object';
import moment from 'moment';

export default EmberObject.extend({
  // API query param
  period: 'day',

  // Chart time unit used on the xAxis
  unit: 'day',

  keys: null,

  startAt: computed('duration', {
    get() {
      const endAt = this.get('endAt').clone();
      const duration = this.get('duration');

      return endAt.subtract(duration);
    },
  }).readOnly(),

  // currently, defaulted to current date, since we allow only things like last 30 days, 3 months, etc
  endAt: computed({
    get() {
      return moment();
    },
  }).readOnly(),

  duration: computed({
    set(key, value) {
      if (!moment.isDuration(value)) {
        value = moment.duration(value, 'days');
      }

      return value;
    },
  }),

  apiParams: computed('startAt', 'endAt', function () {
    return {
      start_at: this.get('startAt').toDate(),
      end_at: this.get('endAt').toDate(),
      period: this.get('period'),
      keys: this.get('keys'),
    };
  }),
});
