import Model, { belongsTo, attr } from '@ember-data/model';

export const states = {
  pending: 'pending',
  active: 'active',
};

export default Model.extend({
  state: attr('string'),
  startsAt: attr('utc'),
  endsAt: attr('utc'),

  billingCoupon: belongsTo('billing-coupon', { async: false, inverse: null }),
  billingSubscription: belongsTo('billing-subscription', {
    async: false,
    inverse: null,
  }),
});
