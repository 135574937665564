import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    /**
     * Embedded customer objects
     */
    source: { serialize: false, deserialize: 'records' },
    target: { serialize: false, deserialize: 'records' },
  },
});
