import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { assert } from '@ember/debug';
import { memberAction, collectionAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import { customizableProperty } from 'smile-admin/utils/customizable-property';

export const programTypes = {
  points: 'points',
  referrals: 'referrals',
  milestoneVip: 'milestone_vip',
};

export const pointsExpiryOptions = [
  {
    description: '6 months',
    intervalCount: 180,
  },
  {
    description: '1 year',
    intervalCount: 365,
  },
  {
    description: '2 years',
    intervalCount: 720,
  },
];

export const milestonePeriodType = {
  sinceDate: 'since_date',
  withinInterval: 'within_interval',
  withinAnniversaryInterval: 'within_anniversary_interval',
};

export const milestoneTypes = {
  amountSpent: 'amount_spent',
  pointsEarned: 'points_earned',
};

export const milestoneTypeOptions = [
  { label: 'Points earned', value: 'points_earned' },
  { label: 'Amount spent', value: 'amount_spent' },
];

export const milestonePeriodOptions = [
  { label: 'Since program start date', value: milestonePeriodType.sinceDate },
  { label: 'Within calendar year', value: milestonePeriodType.withinInterval },
  /**
   * For initial MilestoneVip release, we're hiding the "within anniversary year"
   * option, although it may be un-hidden in future. As such, all of the supporting
   * code still exists, but will remain unused for the time being.
   *
   * {
   *  label: `Within customer's anniversary year`,
   *  value: milestonePeriodType.withinAnniversaryInterval,
   * },
   */
];

export default Model.extend({
  type: attr('string'),
  name: attr('string'),
  is_enabled: attr('boolean'),
  requires_upgrade_to_enable: attr('boolean'),
  lastEnabledAt: attr('utc'),
  reward_program_definition_id: attr('number'),

  // Points reward program specific properties
  points_label_singular: attr('string'),
  customized_points_label_singular: attr('string'),
  default_points_label_singular: attr('string'),
  points_label_plural: attr('string'),
  customized_points_label_plural: attr('string'),
  default_points_label_plural: attr('string'),

  points_expiry_is_available: attr('boolean'),
  points_expiry_is_installed: attr('boolean'),
  points_expiry_is_enabled: attr('boolean'),
  points_expiry_interval: attr('string'),
  points_expiry_interval_count: attr('number'),
  points_expiry_inactive_floor_date: attr('utc'),
  requires_upgrade_to_enable_points_expiry: attr('boolean'),

  // Referrals program specific properties
  facebook_share_message_template: attr('string'),
  customized_facebook_share_message_template: attr('string'),

  twitter_share_message_template: attr('string'),
  customized_twitter_share_message_template: attr('string'),

  email_share_title_template: attr('string'),
  customized_email_share_title_template: attr('string'),

  email_share_body_template: attr('string'),
  customized_email_share_body_template: attr('string'),

  customized_landing_page_url: attr('string'),

  customized_referral_domain: attr('string'),
  customized_referral_domain_is_enabled: attr('boolean'),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  // Milestone vip program specific properties
  milestoneType: attr('string'),
  milestoneInterval: attr('string'),
  milestoneIntervalCount: attr('number'),
  milestonePeriod: attr('string'),
  milestonePeriodStartDate: attr('utc'),
  launchedAt: attr('utc'),

  milestoneDescriptionHighestTemplate: attr('string'),
  milestoneDescriptionToMaintainTierTemplate: attr('string'),
  milestoneDescriptionToNextTierTemplate: attr('string'),
  milestoneDescriptionToNextTierWithinIntervalTemplate: attr('string'),
  milestoneVerb: attr('string'),

  account: belongsTo('account', { async: false, inverse: 'reward_programs' }),
  reward_program_definition: belongsTo('reward-program-definition', {
    async: false,
    inverse: null,
  }),
  // Points program
  points_expiry_reminders: hasMany('points-expiry-reminder', {
    async: false,
    inverse: 'reward_program',
  }),
  // Vip program
  initialRefreshTiersTask: belongsTo('trackable-task', {
    async: false,
    inverse: null,
  }),
  shopifyVipMetafieldSync: belongsTo('shopify-vip-metafield-sync', {
    async: false,
    inverse: null,
  }),

  customizablePointsLabelPlural: customizableProperty('points_label_plural'),

  customizablePointsLabelSingular: customizableProperty(
    'points_label_singular'
  ),
  customizableFacebookShareMessageTemplate: customizableProperty(
    'facebook_share_message_template'
  ),
  customizableTwitterShareMessageTemplate: customizableProperty(
    'twitter_share_message_template'
  ),
  customizableEmailShareTitleTemplate: customizableProperty(
    'email_share_title_template'
  ),
  customizableEmailShareBodyTemplate: customizableProperty(
    'email_share_body_template'
  ),

  milestonePeriodSuffixText: computed(
    'milestonePeriod',
    'launchedAt',
    'milestoneType',
    function () {
      let { milestonePeriod: period, launchedAt, milestoneType } = this;

      assert(
        `Milestone type ${milestoneType} not supported!`,
        Object.values(milestoneTypes).indexOf(milestoneType) !== -1
      );

      let text =
        milestoneType === milestoneTypes.pointsEarned ? 'earned ' : 'spent';

      if (period === milestonePeriodType.sinceDate && launchedAt) {
        text = `${text} since ${launchedAt.format('MMMM DD, YYYY')}`;
      } else if (period === milestonePeriodType.withinInterval) {
        text = `${text} in calendar year`;
      } else if (period === milestonePeriodType.withinAnniversaryInterval) {
        text = `${text} in customer's anniversary year`;
      }
      return text;
    }
  ),

  enable: memberAction({
    path: 'enable',
    after: serializeAndPush,
  }),
  disable: memberAction({
    path: 'disable',
    after: serializeAndPush,
  }),
  // Points program
  installPointsExpiry: memberAction({
    path: 'install_points_expiry',
    after: serializeAndPush,
  }),
  enablePointsExpiry: memberAction({
    path: 'enable_points_expiry',
    after: serializeAndPush,
  }),
  disablePointsExpiry: memberAction({
    path: 'disable_points_expiry',
    after: serializeAndPush,
  }),
  // Vip program
  setVipRangeAttribute: memberAction({
    path: 'set_vip_range_attribute',
    after: serializeAndPush,
  }),
  // Referral program
  refreshCustomizedReferralDomainStatus: memberAction({
    path: 'refresh_customized_referral_domain_status',
    after: serializeAndPush,
  }),
  sendReferralSharingLinks: collectionAction({
    path: 'send_referral_sharing_links',
    type: 'post',
    after: serializeAndPush,
  }),
});
