import { isPresent } from '@ember/utils';

/**
 * hexToRgb - function for converting hex colors to rgb(a)
 *
 * @hex (String) - The hex value. Can be prefixed with "#" or not. Can be
 *   long format (6 chars) or short format (3 chars)
 * @opacity (number between 0 and 1 inclusive) - This is an optional float value that
 *   will be used for the opacity
 *
 * returns (Object) - an object with r, g, and b properties set as numbers
 *   along with a "css" property representing the css rule as a string
 *
 * SOURCE: https://gist.github.com/polotek/1584364
 */
export default function (hex, opacity) {
  hex = (hex + '').trim();

  var rgb = {},
    match = hex.match(/^#?(([0-9a-zA-Z]{3}){1,3})$/);

  if (!match) {
    return null;
  }

  hex = match[1];
  // check if 6 letters are provided
  if (hex.length === 6) {
    rgb.r = parseInt(hex.substring(0, 2), 16);
    rgb.g = parseInt(hex.substring(2, 4), 16);
    rgb.b = parseInt(hex.substring(4, 6), 16);
  } else if (hex.length === 3) {
    rgb.r = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16);
    rgb.g = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
    rgb.b = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
  }

  if (opacity === undefined) {
    opacity = 1.0;
  }

  rgb.css = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  return rgb;
}

/**
 * Mutes color adjusted for its background color
 *
 * @param  color           The color to mute
 * @param  backgroundColor The background color the muted color will live on
 * @param  mutePercentage  How much to mute the color, between 0 and 100
 * @return                 The muted color
 */
export const muteColor = function (color, backgroundColor, mutePercentage) {
  var isBackgroundDark = normalizedLightness(backgroundColor) < 0.4;
  mutePercentage = isPresent(mutePercentage) ? mutePercentage : 55;

  if (isBackgroundDark) {
    return darken(color, mutePercentage);
  }
  return lighten(color, mutePercentage);
};

/**
 * Checks if the color is dark or not and inverts to either white or black based on that condition.
 * @param  {String} color HEX color representation
 * @return {String}       HEX color representation, white or black.
 */
export const invertColor = function (color) {
  let isColorDark = normalizedLightness(color) < 0.65;
  return isColorDark ? '#ffffff' : '#000000';
};

/**
 * Returns black or white color depending on the passed in color being light or dark.
 * If a ratio is passed, it will darken/lighten with the ratio percentage the white/black color.
 *
 * @param  {String} color         HEX color that is checked whether is dark or not.
 * @param  {Number} ratio         Optional. Ratio to use to lighten/darken the returned black/white color.
 *                                Value, between 0..100
 * @return {String}               Hexadecimal representation of the color.
 */
export const getWhiteOrBlackBasedOnColor = function (color, ratio) {
  let isColorDark = normalizedLightness(color) < 0.6;
  let newColor;
  if (isColorDark) {
    newColor = ratio ? darken('#ffffff', ratio) : '#ffffff';
  } else {
    newColor = ratio ? lighten('#000000', ratio) : '#000000';
  }

  return newColor;
};

/**
 * Returns the normalized lightness of a hexColor. A value between 0(black)..1(white).
 * Using Weighted Euclidean Distance in 3D RGB Space
 * Sources:   https://robots.thoughtbot.com/closer-look-color-lightness
 *            http://alienryderflex.com/hsp.html
 *
 * @param  {String} hexColor A HEX color
 * @return {Number}          A value between 0..1
 */
export const normalizedLightness = function (hexColor) {
  hexColor = hexColor.replace('#', '');
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);
  let lightness = Math.sqrt(
    0.299 * Math.pow(r, 2) + 0.587 * Math.pow(g, 2) + 0.114 * Math.pow(b, 2)
  );

  // Normalize the value
  return lightness / 255;
};

/**
 * Lightens a HEX color, similar to lighten() in SASS.
 * @param  {String} color     The color in HEX.
 * @param  {Number} percent   Percentage, 0..100
 * @return {String}           The resulting color in HEX.
 */
export const lighten = function (color, percent) {
  percent = percent / 100;
  return _shadeColor(color, percent);
};

/**
 * Darkens a HEX color, similar to darken() in SASS.
 * @param  {String} color     The color in HEX.
 * @param  {Number} percent   Percentage, 0..100
 * @return {String}           The resulting color in HEX.
 */
export const darken = function (color, percent) {
  percent = (-1 * percent) / 100;
  return _shadeColor(color, percent);
};

/**
 * Takes a string value and will return true if it's a valid hex
 * @param  {String} value     The color in HEX.
 * @return {Boolean}          If the value is valid (ex: '#fff' or '#ffffff')
 */
export const isValidColor = function (value) {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(value);
};

/**
 * Lightens or darkens a HEX color, similar to darken() and lighten() in SASS.
 * Source: http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 * @param  {String} color     The color in HEX.
 * @param  {Number} percent   Percentage, value between -1..1.
 * @return {String}           The resulting color in HEX.
 */
const _shadeColor = function (color, percent) {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

/**
 * Converts a color to HSLa
 *
 * NOTE: This is ported from smile-ui
 */
export const rgbToHsla = function (rgb) {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;
  const a = rgb[3] || 1;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h,
    s,
    l = (max + min) / 2;

  const diff = max - min;

  if (max === min) {
    h = s = 0;
  } else {
    s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
    switch (max) {
      case r:
        h = (g - b) / diff + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / diff + 2;
        break;
      case b:
        h = (r - g) / diff + 4;
        break;
    }

    h /= 6;
  }

  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100), a];
};

// This is ported from smile-ui
export const hexToRgb = function (hex) {
  let hexColor = hex.split('#').pop();
  let r, g, b, a;
  let rgb = [];

  switch (hexColor.length) {
    case 3:
      r = hexColor.substr(0, 1);
      g = hexColor.substr(1, 1);
      b = hexColor.substr(2, 1);
      rgb.push(parseInt(r + r, 16), parseInt(g + g, 16), parseInt(b + b, 16));
      return rgb;
    case 4:
      r = hexColor.substr(0, 1);
      g = hexColor.substr(1, 1);
      b = hexColor.substr(2, 1);
      a = hexColor.substr(3, 1);
      rgb.push(
        parseInt(r + r, 16),
        parseInt(g + g, 16),
        parseInt(b + b, 16),
        parseInt(a + a, 16)
      );
      return rgb;
    case 6:
      r = hexColor.substr(0, 2);
      g = hexColor.substr(2, 2);
      b = hexColor.substr(4, 2);
      rgb.push(parseInt(r, 16), parseInt(g, 16), parseInt(b, 16));
      return rgb;
    case 8:
      r = hexColor.substr(0, 2);
      g = hexColor.substr(2, 2);
      b = hexColor.substr(4, 2);
      a = hexColor.substr(6, 2);
      rgb.push(
        parseInt(r, 16),
        parseInt(g, 16),
        parseInt(b, 16),
        parseInt(a, 16)
      );
      return rgb;
    default:
      return rgb;
  }
};

// This is ported from smile-ui
export const convertColorToCss = function (colorType = 'hex', color) {
  if (colorType === 'hex') {
    return `#${color}`;
  } else if (colorType === 'hsla') {
    return `${colorType}(${color[0]},${color[1]}%, ${color[2]}%, ${color[3]})`;
  }

  return `${colorType}(${color.join(',')})`;
};

// This is ported from smile-ui
export const modifyHsl = function (baseHsl, h = 0, s = 0, l = 0, override) {
  let newHsl = baseHsl.slice();

  if (baseHsl[2] === 0 && override) {
    // Black
    newHsl[2] += l;
    // eslint-disable-next-line no-dupe-else-if
  } else if (baseHsl[2] === 0 && override) {
    // White
    newHsl[2] -= 5;
  } else if (baseHsl[0] === 0 && baseHsl[1] === 0 && override) {
    // Grey
    newHsl[2] += l;
  } else {
    (newHsl[0] += h), (newHsl[1] += s), (newHsl[2] += l);
  }

  if (override && newHsl[1] > 100) {
    if (Math.log2) {
      newHsl[2] -= Math.round(Math.log2(newHsl[1] - 100) * 2);
    } else {
      newHsl[2] -= 5; // Fall back for IE 11
    }
  }

  return newHsl.map((x, i) => {
    // Skip H and A values
    if (i === 0 || i === 3) {
      return x;
    } else if (x < 0) {
      return 0;
    } else if (x > 100) {
      return 100;
    }
    return x;
  });
};
