import ApplicationSerializer from 'smile-admin/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    newIntegration: { serialize: false, deserialize: 'records' },
    unhealthyIntegrations: { serialize: false, deserialize: 'records' },
    featureRelease: { serialize: false, deserialize: 'records' },
    nudge: { serialize: false, deserialize: 'records' },
    activityRule: { serialize: false, deserialize: 'records' },
  },
});
