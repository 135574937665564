import { modifier } from 'ember-modifier';

interface DidInsertModifierSignature<T extends HTMLElement = HTMLElement> {
  Element: T;
  Args: {
    Positional: [callback: (element: T) => void, cleanupCallback?: () => void];
  };
}

export const didInsert = modifier<DidInsertModifierSignature>(
  (element, [callback, cleanupCallback]) => {
    callback(element);

    if (cleanupCallback) {
      return () => {
        cleanupCallback();
      };
    }
  },
);

export default didInsert;
