export interface RowContextType {
  itemId?: string;
  selected?: boolean | 'indeterminate';
  disabled?: boolean;
  position?: number;
  onInteraction?: (event: MouseEvent | KeyboardEvent) => void;
}

export type RowHoveredContextType = boolean | undefined;

export interface ScrollContextType {
  scrollableContainer: HTMLDivElement | null;
  canScrollLeft: boolean;
  canScrollRight: boolean;
}

export const scrollDefaultContext = {
  scrollableContainer: null,
  canScrollLeft: false,
  canScrollRight: false,
};
