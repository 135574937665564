import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

export default function changesetForProperty(propertyName, validations) {
  return computed(propertyName, function () {
    let obj = this.get(propertyName);

    if (isNone(obj)) {
      return null;
    }

    let args = [obj];

    if (validations) {
      args.push(lookupValidator(validations), validations, {
        skipValidate: true,
      });
    }

    return new Changeset(...args);
  });
}
