import { A } from '@ember/array';
import ColorsForm from '../../components/smile-ui/forms/theme/colors';
import ShapesForm from '../../components/smile-ui/forms/theme/shapes';
import WallpaperForm from '../../components/smile-ui/forms/theme/wallpaper';
import PlacementForm from '../../components/smile-ui/forms/theme/placement';
import VisibilityForm from '../../components/smile-ui/forms/theme/visibility';
import BannerImagesForm from '../../components/smile-ui/forms/theme/banner-images';
import SmileBrandingForm from '../../components/smile-ui/forms/theme/smile-branding';

export const navigationConfig = A([
  {
    sections: [
      {
        text: 'Colors',
        value: 'colors',
        icon: 'smile/colors',
        classNames: 'branding-tour-colors-step',
        Component: ColorsForm,
      },
      {
        text: 'Shapes',
        value: 'shapes',
        icon: 'smile/shapes',
        Component: ShapesForm,
      },
      {
        text: 'Wallpaper',
        value: 'wallpaper',
        icon: 'smile/wallpaper',
        Component: WallpaperForm,
      },
      {
        text: 'Placement',
        value: 'placement',
        icon: 'smile/placement',
        Component: PlacementForm,
        tabs: [
          { text: 'Desktop', value: 'desktop' },
          { text: 'Mobile', value: 'mobile' },
        ],
      },
      {
        text: 'Visibility',
        value: 'visibility',
        icon: 'smile/visibility',
        Component: VisibilityForm,
      },
      {
        text: 'Banner images',
        value: 'banner-images',
        icon: 'smile/banner',
        classNames: 'branding-tour-banner-images-step',
        Component: BannerImagesForm,
      },
      {
        text: 'Smile branding',
        value: 'smile-branding',
        icon: 'smile/smile-branding',
        Component: SmileBrandingForm,
      },
    ],
  },
]);
