import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

export default class ResetPasswordController extends Controller {
  @service router;
  @service ajax;
  @service errorHandler;

  queryParams = [
    {
      resetPasswordToken: 'reset_password_token',
    },
  ];

  /**
   * The reset password token. Users will get an email when they try to recover
   * their password which will link here with this token.
   *
   * @type {String}
   */
  resetPasswordToken = null;

  @dropTask
  *resetPassword(changeset) {
    try {
      let {
        resetPasswordToken: reset_password_token,
        password,
        passwordConfirmation: password_confirmation,
      } = changeset.getProperties(
        'resetPasswordToken',
        'password',
        'passwordConfirmation'
      );

      yield this.ajax.patch('/v1/users/password', {
        data: {
          reset_password_token,
          password,
          password_confirmation,
        },
      });

      this.send('persistAuthData', { hasResetPassword: true });

      this.router.replaceWith('auth.login');
    } catch (err) {
      if (err.status === 401) {
        this.send('persistAuthData', { isResetPasswordTokenExpired: true });

        this.router.replaceWith('auth.login');

        return;
      }

      this.errorHandler.handle(err);
    }
  }
}
