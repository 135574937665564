import Model, { attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  description: attr('string'),
  image_url: attr('string'),
  imageSvg: attr('string'),
  reward_type: attr('string'),
  category: attr('string'),

  requiredFeatureFlag: attr('string'),
  supportsVariableValues: attr('boolean'),
  supports_consumable_codes_import: attr('boolean'),
  consumable_codes_import_mass_action_type: attr('string'),
  supportsExpiry: attr('boolean'),
  supportsDiscountCombinations: attr('boolean'),

  template_variables: attr('object'),
  fulfill_template_variables: attr('object'),

  default_reward_name: attr('string'),
  default_reward_name_template: attr('string'),
  default_reward_image_url: attr('string'),
  default_reward_description_template: attr('string'),

  default_fulfillment_name_template: attr('string'),
  default_fulfillment_instructions_html_template: attr('string'),
  default_fulfillment_action_url_template: attr('string'),
  default_fulfillment_action_text_template: attr('string'),

  supports_coupon_code_tracking_on_orders: attr('string'),
  customized_default_reward_name_template: attr('string'),

  // From Data JSON
  reward_fulfillment_url_template: attr('string'),
  external_reward_type: attr('string'),
});
