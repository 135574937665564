/* import __COLOCATED_TEMPLATE__ from './video-player.hbs'; */
import BasePolarisModal from '@smile-io/ember-smile-core/components/polaris-modals/base';

export default class VideoPlayerModal extends BasePolarisModal {
  classNames = ['video-player-modal'];

  get title() {
    return this.modalParams.title;
  }

  get introText() {
    return this.modalParams.introText;
  }

  get videoUrl() {
    return this.modalParams.videoUrl;
  }

  get videoFile() {
    return this.modalParams.videoFile;
  }
}
