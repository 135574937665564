import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { programTypes as rewardProgramTypes } from 'smile-admin/models/reward-program';
import { types as rewardTypes } from 'smile-admin/models/reward';
import rewardValidations from 'smile-admin/validations/reward';
import { rewardForTypes } from 'smile-admin/models/referral-reward';

/**
 * Dummy default values engine as specd in the following link
 * https://docs.google.com/document/d/1afwd1HRgiWtbwu0tFMy2VkjSMwnto1c_03jMdf_nZ-w/edit#heading=h.oc94xjitavuo
 */
export default class RewardEngine {
  reward = null;

  amountDiscountRewardTypes = [
    rewardTypes.bigcommercePerTotalDiscountCoupon,
    rewardTypes.shopifyPriceRuleFixedAmountDiscount,
  ];

  constructor(reward) {
    this.reward = reward;
    this.createChangeset();
  }

  createChangeset() {
    if (!this.reward) {
      return null;
    }

    this.changeset = new Changeset(
      this.reward,
      lookupValidator(rewardValidations),
      rewardValidations,
      { skipValidate: true }
    );
  }

  getPerRewardProgramDefaults(reward, { rewardFor }) {
    let perRewardDefaults = {};
    let rewardBelongsToReferralsProgram =
      reward.rewardProgram?.type === rewardProgramTypes.referrals;
    let rewardIsAmountDiscount = this.amountDiscountRewardTypes.includes(
      reward.type
    );
    let rewardIsReferralAmountDiscount =
      rewardBelongsToReferralsProgram && rewardIsAmountDiscount;
    let isFriendReward = rewardFor === rewardForTypes.friend;

    if (
      reward.supportsMinimumOrderAmount &&
      rewardIsReferralAmountDiscount &&
      isFriendReward
    ) {
      perRewardDefaults = { ...perRewardDefaults, minimum_order_amount: 20 };
    }

    if (
      reward.supportsMinPurchase &&
      rewardIsReferralAmountDiscount &&
      isFriendReward
    ) {
      perRewardDefaults = { ...perRewardDefaults, min_purchase: 20 };
    }

    return perRewardDefaults;
  }

  getDefaults(options = {}) {
    let { only } = options;
    let { reward } = this;

    only = typeof only === 'string' ? [only] : only;

    let defaults = {
      value: 5,
    };

    if (reward.hasPercentageValue) {
      defaults = { ...defaults, value: 10 };
    } else if (reward.isFreeShippingType) {
      defaults = {
        ...defaults,
        maximum_shipping_amount: 25,
      };
    } else if (reward.isFreeProductType) {
      defaults = { ...defaults, value: 1 };
    }

    defaults = {
      ...defaults,
      ...this.getPerRewardProgramDefaults(reward, options),
    };

    if (only) {
      return only.reduce(
        (result, key) => ({ ...result, [key]: defaults[key] }),
        {}
      );
    }

    return defaults;
  }
}
