/* import __COLOCATED_TEMPLATE__ from './top-bar-upgrade-trigger.hbs'; */
import Component from '@ember/component';
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';

/**
 * @arg onClick {Function}
 */
@tagName('')
export default class PolarisAppTopBarUpgradeTrigger extends Component {
  onClick() {}

  @action
  handleMouseup(event) {
    event.preventDefault();
    event.currentTarget.blur();
  }

  @action
  handleClick(event) {
    event.preventDefault();

    this.onClick();
  }
}
