import { helper } from '@ember/component/helper';

const statusStateMap = {
  completed: 'success',
  pending: 'info',
  blocked: 'attention',
  cancelled: null,
};

export function badgeStatusForState(state) {
  return statusStateMap[state];
}

export default helper(([state]) => badgeStatusForState(state));
