/* import __COLOCATED_TEMPLATE__ from './wallpaper.hbs'; */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { schedule } from '@ember/runloop';
import { isBlank } from '@ember/utils';

export default Component.extend({
  tagName: '',

  /**
   * Action fired when a property is updated.
   *
   * @property {onChange}
   * @type {Function}
   * @default no-op
   * @public
   */
  onChange() {},

  /**
   * Collection of loaded `wallpaper-option` models
   *
   * @property {wallpaperOptions}
   * @type {Object[]}
   * @private
   */
  wallpaperOptions: readOnly('panel.wallpaperOptions'),

  /**
   * The selected wallpaper
   *
   * @property {chosenWallpaperOption}
   * @type {Object}
   * @private
   */
  chosenWallpaperOption: computed(
    'wallpaperOptions.[]',
    '_chosenWallpaperOption',
    {
      get() {
        if (this._chosenWallpaperOption) {
          return this._chosenWallpaperOption;
        }

        return this.wallpaperOptions.find(
          (option) => option.imageUrl === this.panel.wallpaperUrl
        );
      },

      set(key, value) {
        set(this, '_chosenWallpaperOption', value);
        return value;
      },
    }
  ),

  items: computed('wallpaperOptions.[]', function () {
    return this.wallpaperOptions.map((option) => {
      let item = {
        text: option.name,
        onAction: () => {
          this.set('chosenWallpaperOption', option);
        },
      };

      if (option.iconUrl) {
        item.image = option.iconUrl;
      } else {
        // Hax for the `None` wallpaper option
        // to render a blank icon so the item
        // still renders with a blank thumbnail
        // and aligns with the other items.
        item.icon = 'none';
      }

      return item;
    });
  }),

  // We're doing this since polaris action list item doesn't have an active prop
  addActiveClassToSelectedOption() {
    let chosenWallpaperOptionUrl = this.chosenWallpaperOption
      ? this.chosenWallpaperOption.iconUrl
      : '';

    document
      .querySelectorAll(
        '.smile-ui-theme-wallpaper-form .Polaris-ActionList__Image'
      )
      .forEach(function (elem) {
        if (
          (isBlank(elem.style.backgroundImage) &&
            isBlank(chosenWallpaperOptionUrl)) ||
          elem.style.backgroundImage.includes(chosenWallpaperOptionUrl)
        ) {
          elem.classList.add('active');
        } else {
          elem.classList.remove('active');
        }
      });
  },

  didUpdateAttrs() {
    this._super();
    schedule('afterRender', () => this.addActiveClassToSelectedOption());
  },

  actions: {
    updatePanelChangeset(panelChangeset) {
      this.onChange(
        'panel',
        panelChangeset,
        'customizableWallpaperOption',
        this.chosenWallpaperOption.name.toLowerCase()
      );
      this.onChange(
        'panel',
        panelChangeset,
        'wallpaperUrl',
        this.chosenWallpaperOption.imageUrl
      );
    },
  },
});
