import ApplicationAdapter from './application';

/**
 * Allows fetching an authenticated user by hitting a `/users/me` endpoint. Used on Shopify when the
 * app is embedded.
 *
 * This is following ember-simple-auth's guide
 * https://github.com/simplabs/ember-simple-auth/blob/4.2.2/guides/managing-current-user.md#using-a-dedicated-endpoint
 */
export default class UserAdapter extends ApplicationAdapter {
  urlForQueryRecord(query) {
    let originalUrl = super.urlForQueryRecord(...arguments);
    if (query.me) {
      delete query.me;
      return `${originalUrl}/me`;
    }

    return originalUrl;
  }
}
