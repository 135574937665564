/* import __COLOCATED_TEMPLATE__ from './breakdown.hbs'; */
import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { tagName } from '@ember-decorators/component';

@tagName('')
export default class BillingSubscriptionBreakdown extends Component {
  /**
   * The billing-subscription model
   *
   * @type {DS.Model}
   * @default null
   * @public
   */
  @reads('container.billingSubscription')
  billingSubscription = null;

  /**
   * An optional container object (billing-subscription/container or
   * billing-subscription-template/container) that provides all of
   * the data required by this component and in extreme cases, you can overwrite
   * specific properties by passing directly.
   * If not provided, it's expected that all the values are passed in.
   *
   * @type {Object}
   * @default null
   * @public
   */
  container = null;

  /**
   * Whether to show additional members rate in details section.
   *
   * @type {Boolean}
   * @default false
   * @public
   */
  showAdditionalMembersRate = false;

  @reads('container.basePlan')
  basePlan;

  @reads('container.basePlanName')
  basePlanName;

  @reads('container.isMembersInfinite')
  isMembersInfinite;

  @reads('container.accountMembersCount')
  accountMembersCount;

  @reads('container.basePlanMembersCount')
  basePlanMembersCount;

  @reads('container.membersPlanMembersCount')
  membersPlanMembersCount;

  @reads('container.membersPlanMembersCountTotal')
  membersPlanMembersCountTotal;

  @reads('container.creditsMembersCount')
  creditsMembersCount;

  @reads('container.membersCountTotal')
  membersCountTotal;

  @reads('container.isAppsInfinite')
  isAppsInfinite;

  @reads('container.isMonthlyOrdersInfinite')
  isMonthlyOrdersInfinite;

  @reads('container.supportsApps')
  supportsApps;

  @reads('container.accountAppsCount')
  accountAppsCount;

  @reads('container.basePlanAppsCount')
  basePlanAppsCount;

  @reads('container.appsPlanAppsCount')
  appsPlanAppsCount;

  @reads('container.appsPlanAppsCountTotal')
  appsPlanAppsCountTotal;

  @reads('container.creditsAppsCount')
  creditsAppsCount;

  @reads('container.appsCountTotal')
  appsCountTotal;

  @reads('container.hasZeroCost')
  hasZeroCost;

  @reads('container.basePlanPrice')
  basePlanPrice;

  @reads('container.basePlanMonthlyOrders')
  basePlanMonthlyOrders;

  @reads('container.membersPlanPrice')
  membersPlanPrice;

  @reads('container.membersPlanPriceTotal')
  membersPlanPriceTotal;

  @reads('container.appsPlanPrice')
  appsPlanPrice;

  @reads('container.appsPlanPriceTotal')
  appsPlanPriceTotal;

  @reads('container.priceTotalBeforeDiscount')
  priceTotalBeforeDiscount;

  @reads('container.priceTotal')
  priceTotal;

  @reads('container.billingDiscount')
  billingDiscount;

  @reads('container.discountAmountCents')
  discountAmountCents;

  @reads('container.friendlyDiscountDurationCount')
  friendlyDiscountDurationCount;

  @reads('container.discountDescription')
  discountDescription;

  @reads('container.appsSubscriptionItemQuantity')
  appsSubscriptionItemQuantity;

  @reads('container.membersSubscriptionItemQuantity')
  membersSubscriptionItemQuantity;

  @reads('container.monthlyOrdersCredits')
  monthlyOrdersCredits;

  @reads('container.monthlyOrdersTotal')
  monthlyOrdersTotal;

  @reads('container.ordersPlanPriceTotal')
  ordersPlanPriceTotal;

  @reads('container.ordersPlanPrice')
  ordersPlanPrice;

  @reads('container.ordersPlanMonthlyOrders')
  ordersPlanMonthlyOrders;

  @reads('container.requiredMonthlyOrdersSubscriptionItemQuantity')
  requiredMonthlyOrdersSubscriptionItemQuantity;
}
