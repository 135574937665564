/**
 * Launcher icon options.
 *
 * NOTE: This is mostly for onboarding when the account doesn't have yet a
 * `launcher` associated. Prefer using `launcher.iconOptions` otherwise.
 */
export const launcherIcons = [
  {
    value: 'bag',
    url: 'https://cdn.sweettooth.io/v1/images/launcher_icons/bag.svg',
  },
  {
    value: 'crown',
    url: 'https://cdn.sweettooth.io/v1/images/launcher_icons/crown.svg',
  },
  {
    value: 'present',
    url: 'https://cdn.sweettooth.io/v1/images/launcher_icons/present.svg',
  },
  {
    value: 'tag',
    url: 'https://cdn.sweettooth.io/v1/images/launcher_icons/tag.svg',
  },
  {
    value: 'star',
    url: 'https://cdn.sweettooth.io/v1/images/launcher_icons/star.svg',
  },
];
