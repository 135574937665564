import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import LoadingWindowMixin from 'smile-admin/mixins/routes/loading-window';

export default class UnauthenticatedRoute extends Route.extend(
  LoadingWindowMixin
) {
  @service session;

  beforeModel() {
    this.session.prohibitAuthentication('home');
    super.beforeModel(...arguments);
  }
}
