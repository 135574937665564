import Model, { belongsTo, attr } from '@ember-data/model';

export const rewardForTypes = {
  advocate: 'advocate',
  friend: 'friend',
};

export default Model.extend({
  account_id: attr('number'),
  advocateRewardId: attr('number'),
  friendTrackingRewardId: attr('number'),

  created_at: attr('utc'),
  updated_at: attr('utc'),

  reward_program: belongsTo('reward-program', { async: false, inverse: null }),
  advocateReward: belongsTo('reward', { async: false, inverse: null }),
  friendTrackingReward: belongsTo('reward', { async: false, inverse: null }),
});
