/* import __COLOCATED_TEMPLATE__ from './ember-chart.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

let Chart;
export default class EmberChart extends Component {
  async loadChartJs() {
    let { default: _Chart } = await import('chart.js');
    Chart = _Chart;
  }

  @action
  async drawChart(element) {
    await this.loadChartJs();

    let { data, type, options } = this.args;
    let chart = new Chart(element, {
      type,
      data,
      options,
    });

    this.chart = chart;
  }

  @action
  async updateChart() {
    let { chart, animate } = this.chart;
    let { data, options } = this.args;

    if (chart) {
      chart.data = data;
      chart.options = options;
      if (animate) {
        chart.update();
      } else {
        chart.update(0);
      }
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.chart?.destroy();
  }
}
