import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  sort_order: attr('number'),
  is_created: attr('boolean'),
  is_creatable: attr('boolean'),
  is_upgrade_required: attr('boolean'),

  reward_program_definition: belongsTo('reward-program-definition', {
    async: false,
    inverse: null,
  }),
});
