import Controller from '@ember/controller';
import $ from 'jquery';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Controller.extend({
  errorHandler: service(),
  sesh: service(),
  store: service(),

  /**
   * This is set by whoever calls the modal with the
   * current route context's customer
   */
  customer: null,

  points_amount: null,

  comment: '',

  pointsBalancePreview: computed(
    'customer.points_balance',
    'points_amount',
    function () {
      var pointsAdjustment = this.get('points_amount');

      if (!$.isNumeric(pointsAdjustment)) {
        pointsAdjustment = 0;
      }
      return (
        this.get('customer.points_balance') + parseInt(pointsAdjustment, 10)
      );
    }
  ),

  clear() {
    this.setProperties({
      customer: null,
      points_amount: null,
      comment: '',
    });
  },

  actions: {
    create() {
      let pointsTransactionProps = {
        customer_id: this.get('customer.id'),
        account_id: this.sesh.get('account.id'),
        points_change: this.get('points_amount'),
        comment: this.get('comment'),
      };

      var pointsTransaction = this.store.createRecord(
        'points-transaction',
        pointsTransactionProps
      );
      var promise = pointsTransaction.save();

      return promise
        .then(() => {
          this.send('closeModal');
          this.send('refreshCustomerRoute');
        })
        .catch((err) => {
          this.errorHandler.handle(err);
        });
    },
  },
});
