import Model, { attr } from '@ember-data/model';

export const customerUpdatedTopic = 'customer/updated';

export default Model.extend({
  topic: attr('string'),
  name: attr('string'),
  createdAt: attr('date'),
  updatedAt: attr('date'),
});
