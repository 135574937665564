/* import __COLOCATED_TEMPLATE__ from './customer-short-bio.hbs'; */
import Component from '@ember/component';
import { isPresent } from '@ember/utils';
import { assert } from '@ember/debug';

export default Component.extend({
  tagName: '',

  /**
   * @property customerName
   * @type {String}
   * @public
   * @required
   **/
  customerName: '',

  /**
   * @property subText
   * @type {String}
   * @public
   **/
  subText: '',

  init() {
    this._super(...arguments);
    assert(
      '[customer-short-bio] Customer name should be passed',
      isPresent(this.customerName)
    );
  },
});
