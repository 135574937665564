/* import __COLOCATED_TEMPLATE__ from './total.hbs'; */
import Component from '@ember/component';
import { classNames, className } from '@ember-decorators/component';
import { durations as couponDurations } from 'smile-admin/models/billing-coupon';

@classNames('breakdown-total', 'billing-subscription-breakdown-total')
export default class BreakdownTotal extends Component {
  /**
   * When true, adds a top border.
   *
   * @type {Boolean}
   * @default true
   * @public
   */
  @className('with-top-border')
  withTopBorder = true;

  couponDurations = couponDurations;
}
