export const smileDefaultChartOpts = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: { enabled: false },
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          unit: 'day',
        },
        scaleLabel: {
          display: false,
        },
        ticks: {
          display: false,
        },
        gridLines: {
          drawBorder: false,
          display: false,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: false,
        },
        ticks: {
          display: false,
        },
        gridLines: {
          drawBorder: false,
          display: false,
        },
      },
    ],
  },
};
