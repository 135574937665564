import { helper as buildHelper } from '@ember/component/helper';

const purple = 'rgba(156, 106, 222, 1)';
const transparent = 'rgba(0, 0, 0, 0)';
const teal = 'rgba(71, 193, 191, 1)';
const tealLighter = 'rgba(224, 245, 245, 1)';

/**
 * Helper that accepts a hash with `series` and `lineStyle`
 * attributes, and returns new series data with the proper style
 * attributes applied to each dataset in the series. Example:
 *
 *  {{ember-chart
 *    type='line'
 *    data=(chart-data
 *      series=referralRevenueCard.series
 *      chartStyle="singlePurpleLine"
 *    )
 *    options=chartOptions
 *  }}
 *
 * Where `series` is an array of Chart.js dataset objects with
 * each object containing a `data` array at minimum:
 * http://www.chartjs.org/docs/latest/
 *
 * Currently available `chartStyle` options:
 * "singlePurpleLine"
 * "stackedBarChart"
 */

export function chartData(params, { series = [], chartStyle }) {
  let datasets = [];
  let dataExists = series.length;

  if (!dataExists) {
    return { datasets };
  }

  if (chartStyle === 'singlePurpleLineOverview') {
    datasets = _singlePurpleLineOverview(series);
  } else if (chartStyle === 'singleTealLine') {
    datasets = _singleTealLine(series);
  }

  return { datasets };
}

/**
 * Accepts a set of series data and returns it with the purple
 * graph styling applied to the dataset(s), but with a thinner
 * line styling applied since they are smaller graphs
 *
 * @private
 */
function _singlePurpleLineOverview(series) {
  let styleOpts = {
    backgroundColor: purple,
    borderColor: purple,
    pointBorderWidth: 0,
    borderWidth: 1.5,
    borderCapStyle: 'round',
    borderJoinStyle: 'round',
    pointBackgroundColor: transparent,
    pointBorderColor: transparent,
    fill: false,
    pointRadius: 0,
    pointHitRadius: 10,
    lineTension: 0,
  };

  return series.map((set) => Object.assign({}, set, styleOpts));
}

function _singleTealLine(series) {
  let styleOpts = {
    backgroundColor: tealLighter,
    borderColor: teal,
    pointBackgroundColor: transparent,
    pointBorderColor: transparent,
    borderWidth: 1.5,
    borderCapStyle: 'round',
    borderJoinStyle: 'round',
    fill: 'zero',
    lineTension: 0,
  };

  return series.map((set) => Object.assign({}, set, styleOpts));
}

/**
 * Returns a dataset array with a single object containing
 * empty data for the range provided.
 *
 * ex:
 *
 * _singlePurpleLineEmpty('2018-01-20', '2018-01-22');
 *
 * returns:
 *
 * [
 *  { x: '2018-01-20', y: 0 },
 *  { x: '2018-01-21', y: 0 },
 *  { x: '2018-01-22', y: 0 }
 * ]
 *
 * which can then be styled and used to display a flat graph.
 *
 * @private
 */

export default buildHelper(chartData);
