import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import SweetToothControllerMixin from 'smile-admin/mixins/sweettooth-controller-mixin';

export default Controller.extend(SweetToothControllerMixin, {
  /**
   * These query parameters are added to our unsubscribe links, which hit this
   * page.
   *
   * Examples
   * user_id:             234
   * email:               test@nothing.com
   * unusubscribe_from:   account_milestones
   * token:               2cb1a277650a659f1b11e92a4a64275b128e037f2c3390e3c8fd2d8721dac9e2
   */
  queryParams: ['email', 'unsubscribe_from', 'token'],

  ajax: service(),

  /**
   * Send unsubscribe callback to sweettooth api.
   *
   * @param  string path The API path to send to
   */
  doRequest(path) {
    const url = `/v1${path}`;
    const data = this.getProperties('email', 'unsubscribe_from', 'token');

    return this.ajax.post(url, { data: data });
  },

  actions: {
    unsubscribe() {
      var self = this;

      self.set('working', true);

      this.doRequest('/unsubscribe').then(
        function () {
          self.set('unsubscribed', true);
          self.set('working', false);
        },
        function (error) {
          self.set('working', false);
          self.set('error', true);
          self.addError(error);
          throw error;
        }
      );
    },
  },
});
