import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  is_created: attr('boolean'),
  is_creatable: attr('boolean'),
  is_upgrade_required: attr('boolean'),
  is_upgrade_required_for_enhanced_option: attr('boolean'),
  sort_order: attr('number'),

  referral_sharing_option_definition: belongsTo(
    'referral-sharing-option-definition',
    {
      async: false,
      inverse: 'referral_sharing_option_definition_listing',
    }
  ),
});
