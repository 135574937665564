import { computed, get } from '@ember/object';
import { copy } from 'ember-copy';
import { typeOf, isBlank, isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { classify } from '@ember/string';

const defaultOptions = {
  allowBlank: false,
};

/**
 * Creates a computed property that plays nicely with our API convention of having a 'name' + 'customized_name'
 * property, that overwrites the former if present.
 * Initially returns the object's 'property' property value. If it is set to a new value, it will
 * set that value as the object's 'customizedProperty' attribute and return it.
 *
 * @param  {String} property              Required. The name of the property on which to fallback on.
 * @param  {String} customizedProperty    Optional. The name of the property to set when changing the value.
 *                                        If not passed, it will default to `customized_<property>`.
 * @param  {Object} opts                  Optional. An object with some options. Check `defaultOptions` above
 *                                        for supported ones.
 * @return {Ember.ComputedProperty}
 */
export function customizableProperty(property, customizedProperty, opts) {
  assert('Missing required customizable CP dependency param!', property);

  if (arguments.length === 2 && typeOf(customizedProperty) === 'object') {
    opts = customizedProperty;
    customizedProperty = null;
  }

  if (property.indexOf('.') !== -1) {
    let properties = property.split('.');
    let propertyName = properties.pop();
    customizedProperty =
      customizedProperty ||
      `${properties.join('.')}.customized_${propertyName}`;
  } else {
    customizedProperty = customizedProperty || `customized_${property}`;
  }

  let options = copy(defaultOptions);
  Object.assign(options, opts);

  return computed(property, customizedProperty, {
    get() {
      if (isBlank(this.get(customizedProperty)) && !options.allowBlank) {
        return this.get(property);
      }

      return this.get(customizedProperty);
    },
    set(_, value) {
      this.set(customizedProperty, value);
      // If the value is set to null, make sure to return the default
      if (isEmpty(value) && !options.allowBlank) {
        return this.get(property);
      }

      return value;
    },
  });
}

/**
 * Reads the customer-facing value for a customizableProperty from a changeset's
 * changes and underlying data, accounting for whether a customized value has
 * been set/updated, whether customizations should be applied etc.
 *
 * @param {Object} changedProperties  Hash of changeset's changes, usually found as changeset.get('change').
 * @param {Object} originalProperties Hash of changeset's original (unchanged) properties.
 * @param {String} key                Name of the property to retrieve the value for.
 */
export function getCustomizablePropertyValue(
  changedProperties,
  originalProperties,
  key
) {
  // Wallpaper URL can be null when the "none" option is chosen.
  const propertiesWithNullableValues = ['wallpaperUrl'];

  // Some parts of our code update `customized<Attribute>`
  // while others update `customizable<Attribute>`, so we
  // check if we have an updated value for either.
  let customizedKey = `customized${classify(key)}`;
  let customizableKey = `customizable${classify(key)}`;

  let changedValue = null;
  if (changedProperties.hasOwnProperty(customizedKey)) {
    changedValue = get(changedProperties, customizedKey);
  } else if (changedProperties.hasOwnProperty(customizableKey)) {
    changedValue = get(changedProperties, customizableKey);
  }

  // Use the updated changedValue for either the customized
  // or customizable properties if present.
  if (changedValue !== null) {
    return changedValue;
  }

  // Some properties (e.g. panel.wallpaperUrl) are not updated directly
  // by the merchant but can still be updated by our code - we need to
  // ensure those properties are represented correctly here.
  let hasChangedValue = changedProperties.hasOwnProperty(key);
  changedValue = hasChangedValue ? get(changedProperties, key) : null;
  let shouldUseChangedValue =
    changedValue !== null ||
    (hasChangedValue && propertiesWithNullableValues.indexOf(key) > -1);

  return shouldUseChangedValue ? changedValue : get(originalProperties, key);
}
