import Model, { belongsTo, attr } from '@ember-data/model';

export default class AccountDataSetModel extends Model {
  @attr('number') totalOrderValueWithSmileDiscountCode;
  @attr('number') totalOrdersWithSmileDiscountCode;
  @attr('number') totalCustomersWithPointsBalance;
  @attr('number') totalOrderValueWithReferredDiscountCode;
  @attr('number') totalOrdersWithReferredDiscountCode;
  @attr('number') totalReferralClicks;
  @attr('number') totalPointsOutstanding;
  @attr('number') totalPointsRedeemed;
  @attr('number') totalPointsEarned;
  @attr('number') totalMembers;
  @attr('number') totalEarners;
  @attr('number') totalRedeemers;
  @attr('number') totalOrders28d;
  @attr('number') totalEarners28d;
  @attr('number') totalRedeemers28d;

  @belongsTo('account', { async: false, inverse: 'dataSet' })
  account;
}
