/**
 * Hex colour checker.
 * Taken from https://gist.github.com/polotek/1584364 and modified slightly to:
 *  - only accept letters a-f (original accepted all letters),
 *  - require the pound sign at the start.
 */
export const hexColorMatcher = /^#(([0-9a-fA-F]{3}){1,3})$/;

/**
 * Loose matcher for the first instance of 'facebook.com/' in a URL.
 * This will account for an optional protocol and 'www' at the beginning.
 */
export const facebookUrlMatcher = /^.*?facebook\.com\//i;

/**
 * Tighter matcher for facebook pages to ensure that there's
 * something present after the 'facebook.com/'.
 */
export const facebookUrlWithPageMatcher = /^.*?facebook\.com\/.+/i;
