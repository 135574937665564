import { computed } from '@ember/object';

/**
 * Returns a CP that wraps a property which is an array of
 * values to make it usable with power-select components.
 *
 * valuesPath: path to the array of values (the "value array").
 * optionsPath: path to the array of power-select options.
 * optionValuePath: path to the value in each option that is used
 *                  to build up the values in the value array.
 *
 * Example: imagine this component:
 *
 * {
 *   fruitOptions: [{
 *     name: 'apple',
 *     id: 1,
 *   }, {
 *     name: 'orange',
 *     id: 2,
 *   }, {
 *     name: 'banana',
 *     id: 3,
 *   }],
 *
 *   selectedFruitIds: [ 1, 3 ],
 * }
 *
 * In the template we want a `power-select` component with `fruitOptions`
 * as the available options, and we want to use the control to display
 * and update the value of `selectedFruitIds`. We can add a CP to the component
 * like this:
 *
 *   `selectedFruits: powerSelectValues('selectedFruitIds', 'fruitOptions', 'id')`,
 *
 * then in the template we can simply use `selectedFruits` in the `power-select`:
 *
 * <PowerSelectMultiple
 *   @options={{this.fruitOptions}}
 *   @selected={{this.selectedFruits}}
 *   @onChange={{fn (mut this.selectedFruits)}}
 *   as |fruit|
 * >
 *   {{fruit.name}}
 * </PowerSelectMultiple>
 *
 */
export default function powerSelectValues(
  valuesPath,
  optionsPath,
  optionValuePath
) {
  return computed(
    `${valuesPath}.[]`,
    `${optionsPath}.@each.${optionValuePath}`,
    {
      get() {
        let values = this.get(valuesPath) || [];
        let options = this.get(optionsPath) || [];

        return values.map((value) =>
          options.find((option) => option[optionValuePath] === value)
        );
      },

      set(key, newValues) {
        newValues = newValues || [];
        this.set(
          valuesPath,
          newValues.map((value) => value[optionValuePath])
        );

        return newValues;
      },
    }
  );
}
