import Model, { belongsTo, attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';

export default Model.extend(
  addDefaultAndCustomizedFieldsToModel({
    buttonFontColor: attr('string'),
    buttonColorPreset: attr('string'),
  }),
  {
    logoUrl: attr('string'),
    logoTempS3Key: attr('string', { defaultValue: null }),

    displaySetting: belongsTo('display-setting', {
      async: false,
      inverse: 'customerNotificationTheme',
    }),
    customerNotification: belongsTo('customer-notification', {
      async: false,
      inverse: null,
    }),

    removeImage: memberAction({
      path: 'remove_image',
      after: serializeAndPush,
    }),
  }
);
