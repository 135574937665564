import Model, { attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import addDefaultAndCustomizedFieldsToModel from 'smile-admin/utils/add-default-and-customized-fields-to-model';

export default Model.extend(
  {
    bannerColor: attr('string'),
    bannerImageUrl: attr('string'),
    bannerImageTempS3Key: attr('string'),
    caption: attr('string'),
    iconUrl: attr('string'),
    iconTempS3Key: attr('string'),
    pointsTitle: attr('string'),
    referralsTitle: attr('string'),
    vipTitle: attr('string'),
    title: attr('string'),

    removeImage: memberAction({ path: 'remove_image', type: 'put' }),
  },
  addDefaultAndCustomizedFieldsToModel({
    bannerColorPreset: attr('string'),
    bannerFontColor: attr('string'),
    headerBarFontColor: attr('string'),
    rewardProgramForBanner: attr('string'),
    pointsCaption: attr('string'),
    referralsCaption: attr('string'),
    vipCaption: attr('string'),
    visitorCaption: attr('string'),
    visitorTitle: attr('string'),
    updatedAt: attr('date'),
  })
);
