/**
 * Utility to help rounding to a closest value.
 *
 * Examples:
 *  roundToClosest(98, 10) -> 100
 *  roundToClosest(51, 10) -> 50
 *  roundToClosest(78, 5) -> 80
 *
 * @param {Number} value The value to be rounded
 * @param {Number} closest The closest value to be rounded to
 */
export default function roundToClosest(value, closest) {
  return Math.round(value / closest) * closest;
}
