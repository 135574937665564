import Transform from '@ember-data/serializer/transform';
import moment from 'moment';

/**
 * Source: http://blog.skylight.io/bringing-sanity-to-javascript-utc-dates-with-moment-js-and-ember-data
 */
export default Transform.extend({
  serialize(deserialized) {
    return deserialized ? deserialized.toDate() : null;
  },

  deserialize(serialized) {
    return serialized ? moment.utc(serialized) : null;
  },
});
