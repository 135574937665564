import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

export default class RecoverPasswordController extends Controller {
  @service ajax;
  @service errorHandler;
  @service router;

  @dropTask
  *recoverPassword(changeset) {
    try {
      let email = changeset.get('email');

      yield this.ajax.post('/v1/users/password', { data: { email } });

      this.send('persistAuthData', {
        email,
        hasRecoveredPassword: true,
      });

      this.router.transitionTo('auth.login');
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }
}
