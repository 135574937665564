/* import __COLOCATED_TEMPLATE__ from './promo-content.hbs'; */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import { slugs } from 'smile-admin/models/billing-subscription-template';

/**
 * @arg billingPlan {DS.Model}
 * @arg smileAppsDescription {String|Component}
 * @arg nudgesDescription {String|Component}
 * @arg onVipInformationClicked {Function}
 */
@tagName('')
export default class NewBillingPlanPromoContent extends Component {
  @service media;

  @computed('billingPlan.billingProduct.slug')
  get planDetails() {
    switch (this.billingPlan.billingProduct.slug) {
      case slugs.starter:
        return {
          illustrationPath:
            '/assets/illustrations/billing-subscription-template/starter-desktop.png',
          illustrationSources: this.media.isMobile
            ? `/assets/illustrations/billing-subscription-template/starter-mobile.png,
              /assets/illustrations/billing-subscription-template/starter-mobile@2x.png 2x`
            : `/assets/illustrations/billing-subscription-template/starter-desktop.png,
              /assets/illustrations/billing-subscription-template/starter-desktop@2x.png 2x`,
          description: 'A fully branded rewards experience.',
          features: [
            this.smileAppsDescription,
            'Custom graphics',
            'Personalized messaging',
            'Remove Smile branding',
            'Light and dark theme',
            'Measure success with advanced analytics',
          ].filter(Boolean),
        };

      case slugs.growth:
        return {
          illustrationPath:
            '/assets/illustrations/billing-subscription-template/growth.svg',
          description: 'Take customer engagement to another level!',
          features: [
            this.smileAppsDescription,
            this.nudgesDescription,
            'Re-engage customers by notifying them before their points expire',
            'Better returns with product rewards',
          ].filter(Boolean),
        };

      case slugs.pro:
        return {
          illustrationPath: '/assets/illustrations/hero.svg',
          description: 'Drive high-value customer behavior!',
          features: [
            'Strengthen connections with your top customers',
            'Get customers excited to join your community',
            'Increase customer engagement by offering additional rewards and status',
            'Enhance your brand experience with exclusive rewards administered by your team',
          ],
        };

      case slugs.starterWithOrderLimit:
        return {
          illustrationPath:
            '/assets/illustrations/billing-subscription-template/starter.svg',
          description: 'A fully branded rewards experience.',
          features: [
            this.smileAppsDescription,
            this.nudgesDescription,
            'Custom graphics',
            'Personalized messaging',
            'Remove Smile branding',
            'Light and dark theme',
            'Measure success with advanced analytics',
          ].filter(Boolean),
        };

      case slugs.growthWithOrderLimit:
        return {
          illustrationPath: '/assets/illustrations/hero.svg',
          description: 'Take customer engagement to another level!',
          features: [
            this.smileAppsDescription,
            'Re-engage customers by notifying them before their points expire',
            'Better returns with product rewards',
          ].filter(function (e) {
            e !== undefined && e !== null;
          }),
        };

      case slugs.plus:
        return {
          illustrationPath:
            '/assets/illustrations/billing-subscription-template/plus.svg',
          description:
            'For large businesses ready to take loyalty into their own hands',
          features: [
            'Supercharge your program and rewards with the Smile API',
            'Get on-demand access to powerful data and reports',
            'Jump-the-line with priority support ',
            'Stay up to date with early access to new features',
            'Lock in preferred pricing for multi-store',
          ],
        };

      case slugs.wixPremium:
        return {
          illustrationPath:
            '/assets/illustrations/billing-subscription-template/wix-premium.svg',
          description: 'Reward your brand advocates and grow your community!',
          features: [
            'Measure success with advanced analytics',
            'Custom graphics',
            'Personalized messaging',
            'Light and dark theme',
          ],
        };

      case slugs.enterprise:
        return {
          illustrationPath:
            '/assets/illustrations/billing-subscription-template/enterprise.svg',
          description: 'A totally unique rewards experience.',
          features: [
            'Expert guidance from your dedicated success manager',
            'Use the Smile API to reward your customers for virtually any online interaction with your brand',
            'Custom HTML reward program emails',
            'Personalized analytics with custom reports',
          ],
        };

      default:
        return null;
    }
  }
}
