import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  isInstalled: attr('boolean'),
  defaultSortOrder: attr('number'),

  nudge: belongsTo('nudge', { async: false, inverse: null }),
  nudgeDefinition: belongsTo('nudge-definition', {
    async: false,
    inverse: null,
  }),
});
