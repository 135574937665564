export const rewardProgramSections = [
  {
    text: 'VIP',
    value: 'milestone_vip',
  },
  {
    text: 'Points',
    value: 'points',
  },
  {
    text: 'Referrals',
    value: 'referrals',
  },
];

export const navigationConfig = [
  {
    text: 'Home',
    value: 'home',
    tabs: [
      {
        text: 'Visitor',
        value: 'visitor',
        sections: [
          {
            text: 'Header',
            value: 'header',
          },
          {
            text: 'Account Creation',
            value: 'account-creation',
          },
          {
            text: 'Rewards sign in',
            value: 'rewards-sign-in',
          },
          ...rewardProgramSections,
        ],
      },
      {
        text: 'Member',
        value: 'member',
        sections: [
          {
            text: 'Header',
            value: 'header',
          },
          ...rewardProgramSections,
          {
            text: 'Your rewards',
            value: 'your-rewards',
          },
        ],
      },
    ],
  },
  {
    text: 'Birthday entry',
    value: 'birthday-entry',
    requiredRewardProgramType: 'points',
  },
  {
    text: 'Referral program details',
    value: 'referral-program-details',
    requiredRewardProgramType: 'referrals',
  },
  {
    text: 'Referral landing',
    value: 'referral-landing',
    requiredRewardProgramType: 'referrals',
  },
  {
    text: 'VIP program details',
    value: 'vip-program-details',
    requiredRewardProgramType: 'milestone_vip',
  },
];
