import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  external_id: attr('string'),
  external_created_at: attr('date'),
  external_updated_at: attr('date'),
  external_url: attr('string'),
  order_number: attr('string'),
  is_pos: attr('boolean'),
  status: attr('string'),
  grand_total: attr('number'),
  rewardable_total: attr('number'),

  customer: belongsTo('customer', { async: false, inverse: null }),
  activity: belongsTo('activity', { async: false, inverse: null }),
  points_transaction: belongsTo('points-transaction', {
    async: false,
    inverse: null,
  }),

  orderNumber: computed('order_number', 'external_id', function () {
    return `#${this.get('order_number') || this.get('external_id')}`;
  }).readOnly(),

  friendlyOrderNumber: computed('orderNumber', function () {
    return `Order ${this.get('orderNumber')}`;
  }).readOnly(),
});
