import Model, { belongsTo, attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';
import { memberAction } from 'ember-api-actions';
import { dasherize } from '@ember/string';
import { importSync } from '@embroider/macros';
import { ensureSafeComponent } from '@embroider/util';
import { serializeAndPush } from 'smile-admin/utils/models/serialize-and-push';

export const types = {
  metaTags: 'meta_tags',
  x: 'twitter',
  facebook: 'facebook',
  email: 'email',
};

export default Model.extend({
  type: attr('string'),
  is_enabled: attr('boolean'),
  sort_order: attr('number'),
  previewData: attr('ember-object', { camelCase: false }),

  // Email type specific attributes
  email_title_template: attr('string'),
  default_email_title_template: attr('string'),
  customized_email_title_template: attr('string'),
  email_body_template: attr('string'),
  default_email_body_template: attr('string'),
  customized_email_body_template: attr('string'),

  // Twitter type specific attributes
  twitter_share_message_template: attr('string'),
  default_twitter_share_message_template: attr('string'),
  customized_twitter_share_message_template: attr('string'),

  // Meta-tags type specific attributes
  meta_tag_title: attr('string'),
  meta_tag_description: attr('string'),
  meta_tag_image_url: attr('string'),
  meta_tag_image_temp_s3_key: attr('string'),

  // Mobile type specific attributes
  is_whatsapp_enabled: attr('boolean'),
  is_facebook_messenger_enabled: attr('boolean'),

  created_at: attr('date'),
  updated_at: attr('date'),

  reward_program: belongsTo('reward-program', { async: false, inverse: null }),
  referral_sharing_option_definition: belongsTo(
    'referral-sharing-option-definition',
    {
      async: false,
      inverse: null,
    }
  ),
  // Email type
  email_offer_customer_notification: belongsTo('customer-notification', {
    async: false,
    inverse: null,
  }),

  templateVariables: readOnly(
    'referral_sharing_option_definition.template_variables'
  ),

  get component() {
    if (!Object.values(types).includes(this.type)) {
      return null;
    }

    let componentModule = importSync(
      `../components/referral-sharing-option/${dasherize(this.type)}`
    );
    return ensureSafeComponent(componentModule.default, this);
  },

  removeImage: memberAction({
    path: 'remove_image',
    after: serializeAndPush,
  }),
});
