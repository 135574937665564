/* import __COLOCATED_TEMPLATE__ from './new-feature-release.hbs'; */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  tagName: '',

  store: service(),

  shouldRender: true,

  featureRelease: readOnly('homeCard.featureRelease'),

  dismissFeatureRelease: task(function* (featureRelease) {
    this.set('shouldRender', false);

    yield featureRelease.dismiss();
  }).drop(),
});
