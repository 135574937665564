/* import __COLOCATED_TEMPLATE__ from './ways-to-earn.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class WaysToEarnStartGuideStep extends Component {
  @service router;

  @tracked showVideoGuide = false;

  @action
  transitionToPointsRewards() {
    this.router.transitionTo('reward-programs.points.actions.index', {
      queryParams: { select_activity_rule: true },
    });
  }
}
