/**
 * Utils to work with Ember Data stuff
 * Mostly inspired from `ember-api-actions` build-url util
 */
import { getOwner } from '@ember/application';

/**
 * Given a record, obtain the ember-data model class
 * @param record
 */
export function getModelClass(record) {
  return record.constructor;
}

/**
 * Given an ember-data model class, obtain its name
 * @param clazz
 */
export function getModelName(clazz) {
  return (
    // modern use || legacy fallback
    clazz.modelName || clazz.typeKey
  );
}

/**
 * Given an ember-data-record, obtain the related Store
 * @param record
 */
export function getStoreFromRecord(record) {
  const owner = getOwner(record);
  return owner.lookup('service:store');
}
