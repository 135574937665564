import Model, { attr } from '@ember-data/model';

export const types = {
  simple: 'simple',
};

export default Model.extend({
  type: attr('string'),
  name: attr('string'),
  iconUrl: attr('string'),
});
