/* import __COLOCATED_TEMPLATE__ from './milestones.hbs'; */
import Component from '@glimmer/component';
import { pluralize } from 'ember-inflector';

export default class Milestones extends Component {
  get merchantMilestoneSteps() {
    // Default values that may be overwritten
    let stepCompleted = 0;
    let firstHeadline = 'IN A FEW DAYS';
    let firstHeading = 'First customer earns';
    let firstSubheading =
      'Customers that earn points are 1.5x more likely to make a repeat purchase!';

    let secondHeadline = 'WITHIN 90 DAYS';
    let secondHeading = 'First customer redeems';
    let secondSubheading =
      'Customers that redeem points spend 3x more on average than other customers!';

    let thirdHeadline = 'AFTER CUSTOMER REDEEMS';
    let thirdHeading = 'Repeat order placed';
    let thirdSubheading =
      'Customers are more likely to place this order because of their points discount.';

    // Determine if the first step is completed
    if (this.args.totalEarners > 0) {
      stepCompleted = 1;

      if (this.args.totalEarners < 10) {
        // First step is partially completed
        firstHeading = 'Your first customer earned!';
        firstSubheading =
          'This customer is 1.5x more likely to make a repeat purchase!';
      } else {
        // First step is completed
        firstHeading = `${this.args.totalEarners} customers have earned`;
        firstSubheading =
          'These customers are 1.5x more likely to make a repeat purchase!';

        thirdHeadline = 'SHORTLY AFTER REDEMPTION';
      }
    }

    // Checks if the second step is completed
    if (this.args.totalRedeemers > 0) {
      stepCompleted = 2;

      const customerWord = pluralize(this.args.totalRedeemers, 'customer');
      const verb = this.args.totalRedeemers === 1 ? 'has' : 'have';
      secondHeading = `${customerWord} ${verb} redeemed`;
      secondSubheading =
        'These customers will spend 3x more on average than other customers!';

      thirdHeadline = 'SHORTLY AFTER REDEMPTION';
    }

    // Checks if the third step is completed
    if (this.args.totalOrdersWithSmileDiscountCode > 0) {
      stepCompleted = 3;
      thirdHeading = 'Repeat order placed!';
    }

    return {
      stepCompleted: stepCompleted,
      firstStep: {
        completed: stepCompleted > 0,
        headline: firstHeadline,
        heading: firstHeading,
        subheading: firstSubheading,
      },
      secondStep: {
        completed: stepCompleted > 1,
        headline: secondHeadline,
        heading: secondHeading,
        subheading: secondSubheading,
      },
      thirdStep: {
        completed: stepCompleted > 2,
        headline: thirdHeadline,
        heading: thirdHeading,
        subheading: thirdSubheading,
      },
    };
  }
}
