/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  afterModel() {
    this._super(...arguments);

    /**
     * Closes the app loading screen
     * http://pathgather.github.io/please-wait/
     *
     * Initializing code for this is in index.html
     */
    if (window.loadingScreen && !window.loadingScreen.finishing) {
      window.loadingScreen.finish();
    }
  },
});
