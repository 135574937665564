/* import __COLOCATED_TEMPLATE__ from './popover-actions.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PopoverActionsComponent extends Component {
  @tracked showPopover = false;

  /**
   * @type {Array}
   * @default null
   * @public
   */
  popoverActions = null;

  @action
  toggleShowPopover() {
    this.showPopover = !this.showPopover;
  }
}
