/* import __COLOCATED_TEMPLATE__ from './shapes.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SmileUiFormsThemeShapes extends Component {
  @service sesh;
  @service featureRollouts;

  get panelCardTitle() {
    if (this.sesh.usesPrompts) {
      return 'On-site messages';
    } else {
      return 'Panel';
    }
  }
}
