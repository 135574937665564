/* import __COLOCATED_TEMPLATE__ from './result-item.hbs'; */
import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { resultsItemTypes } from 'smile-admin/utils/global-search';

@tagName('')
export default class AppTopBarSearchResultsListResultItem extends Component {
  itemTypes = resultsItemTypes;

  @computed('item.model.{fullName,email}')
  get customerDetails() {
    let { fullName, email } = this.item.model;

    return [fullName, email].filter(Boolean);
  }
}
